import axios from 'axios';
import headers from './headers';
import baseUrl from './baseUrl';

import EnvironmentType from '../models/EnvironmentType.enum';

import store from '../store';
import { loginFail } from '../store/actions/loginAction';

import { LOGIN_ROUTE_PATH } from '../routes/routesPath';
import configs from '../configs/environments';

interface GenericApi {
  post: (path: string, data?: any) => Promise<any>;
  get: (path: string) => Promise<any>;
  put: (path: string, data?: any) => Promise<any>;
  delete: (path: string, data?: any) => Promise<any>;
}

function handleResponse(resp: any): any {
  if (resp.status === 200) {
    return resp.data;
  }
  throw new Error('No api response!');
}

function handleError(error: any) {
  if (!error.response) {
    throw error;
  }

  const errorCode: number = error.response.status;
  const errorMessage: string = error.response.data.message;

  if (errorCode === 403) {
    if (configs.envType !== EnvironmentType.PROD) {
      console.log('FORBIDDEN ERROR: ', errorMessage);
    }

    store.dispatch(<any>loginFail());
    window.history.pushState({ urlPath: LOGIN_ROUTE_PATH }, '', LOGIN_ROUTE_PATH);
  } else {
    if (configs.envType !== EnvironmentType.PROD) {
      console.log(`ERROR: CODE: ${errorCode}, message: ${error.message}.`);
    }

    throw error;
  }
}

const genericApi: GenericApi = {

  post: (path: string, data?) => axios({
    method: 'post',
    url: baseUrl + path,
    headers,
    withCredentials: true,
    data: JSON.stringify(data),
  })
    .then((resp) => handleResponse(resp))
    .catch((error) => handleError(error)),

  get: (path: string) => axios({
    method: 'get',
    url: baseUrl + path,
    headers,
    withCredentials: true,
  })
    .then((resp) => handleResponse(resp))
    .catch((error) => handleError(error)),

  put: (path: string, data?) => axios({
    method: 'put',
    url: baseUrl + path,
    headers,
    withCredentials: true,
    data: JSON.stringify(data),
  })
    .then((resp) => handleResponse(resp))
    .catch((error) => handleError(error)),

  delete: (path: string, data?) => axios({
    method: 'delete',
    url: baseUrl + path,
    headers,
    withCredentials: true,
    data: JSON.stringify(data),
  })
    .then((resp) => handleResponse(resp))
    .catch((error) => handleError(error)),
};

export default genericApi;
