import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    hotelLoadingCard: {
      width: '100%',
      marginBottom: 8,
      '& .MuiCardContent-root:last-child': {
        paddingBottom: 10,
      },
    },
    hotelLoadingCardContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      padding: '8px',
    },
    hotelLoadingCardContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginTop: 10,
      marginLeft: 10,
    },
  },
));
