import { STORE_AVAILABILITY_SEARCH_PARAMS } from './actionTypes';

import { AvailabilitySearchParams } from '../../models';

const storeAvailabilitySearchParams = (
  data: AvailabilitySearchParams,
): { type: string, payload: AvailabilitySearchParams } => (
  {
    type: STORE_AVAILABILITY_SEARCH_PARAMS,
    payload: data,
  }
);

export {
  storeAvailabilitySearchParams,
};
