import Location, { dummyLocation } from '../../models/Location';

export default function locationPersistor(): Location {
  // find better place
  sessionStorage.removeItem('pid');
  
  let location: Location = { ...dummyLocation };
  const sessionLocation: string | null = sessionStorage.getItem('location');

  if (sessionLocation) {
    location = JSON.parse(sessionLocation);
  }

  return location;
}
