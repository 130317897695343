import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import whiteOvalBig from '../../assets/white_oval.png';
import whiteOvalSmall from '../../assets/white_oval_2.png';

export default makeStyles((theme: Theme) => createStyles(
  {
    root: {
      flexGrow: 1,
    },
    body: {
      position: 'absolute',
      top: 0,
      left: 0,
      margin: '0px auto',
      width: '100%',
      minHeight: '100vh',
      background: 'white',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundColor: theme.palette.primary.main,
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        backgroundPosition: '-12px -0px',
        backgroundImage: `url(${whiteOvalSmall})`,
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        backgroundPosition: '-64px -0px',
        backgroundImage: `url(${whiteOvalSmall})`,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        backgroundPosition: '0px -0px',
        backgroundImage: `url(${whiteOvalSmall})`,
      },
      [theme.breakpoints.between('md', 'lg')]: {
        backgroundPosition: '-128px -24px',
        backgroundImage: `url(${whiteOvalBig})`,
      },
      [theme.breakpoints.up('lg')]: {
        backgroundImage: `url(${whiteOvalBig})`,
        backgroundPosition: '0px -24px',
      },
    },
    container: {
      position: 'relative',
      minHeight: 810,
      [theme.breakpoints.down('xs')]: {
        padding: '0 12px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        padding: '0 32px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        padding: '0 52px',
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        padding: '0 84px',
      },
    },
    signOutButton: {
      backgroundColor: '#cccd',
      fontWeight: 600,
      '&:hover': {
        backgroundColor: '#ccc',
      },
      '& .MuiButton-label': {
        padding: '0 35px',
      },
    },
    content: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        flexWrap: 'wrap',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: 32,
        flexWrap: 'wrap',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 32,
        flexWrap: 'wrap',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 64,
        flexWrap: 'nowrap',
      },
    },
    brandImage: {
      width: 118,
      height: 51,
    },
    welcomeMessageContainer: {
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        flexBasis: '100%',
        marginBottom: 24,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        flexBasis: '100%',
        marginBottom: 18,
      },
      [theme.breakpoints.up('md')]: {
        flexBasis: '50%',
        marginBottom: 36,
      },
      [theme.breakpoints.up('lg')]: {
        flexBasis: '50%',
        marginBottom: 0,
      },
    },
    searchWidgetContainer: {
      width: 500,
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 18,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: 500,
        marginBottom: 18,
      },
      [theme.breakpoints.up('md')]: {
        width: 500,
        marginBottom: 128,
      },
    },
  },
));
