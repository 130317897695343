import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange as DateRangeIcon } from '@material-ui/icons';
import { Box, InputAdornment, TextField } from '../../../common';

import useStyles from './checkInCheckOutInputStyles';

interface CheckInCheckOutInputProps {
  isOpen: boolean;
  endDate: string;
  hasError: boolean;
  hasValues: boolean;
  startDate: string;
  onClick: (event) => void;
}

const CheckInCheckOutInput: FC<CheckInCheckOutInputProps> = (props: CheckInCheckOutInputProps) => {
  const {
    isOpen,
    endDate,
    hasError,
    hasValues,
    startDate,
    onClick,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const setTextFieldClasses = (): string => {
    if (hasError) {
      return classes.textFieldWithError;
    }
    
    if (isOpen) {
      return classes.textFieldClicked;
    }
    
    if (!isOpen && hasValues) {
      return classes.textFieldWithValue;
    }

    return classes.textField;
  };

  return (
    <Box>
      <Box className={classes.container} onClick={onClick}>
        <Box className={classes.checkInContainer}>
          <TextField
            fullWidth
            variant="filled"
            label={t('dateRangeCheckInLabel')}
            margin="normal"
            value={startDate}
            classes={{
              root: setTextFieldClasses(),
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        </Box>
        <Box className={classes.checkOutContainer}>
          <TextField
            fullWidth
            variant="filled"
            label={t('dateRangeCheckOutLabel')}
            margin="normal"
            value={endDate}
            classes={{
              root: setTextFieldClasses(),
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        </Box>
      </Box>
      { hasError
      && (
        <Box className={classes.errorContainer}>
          {t('dateRangeNoInputError')}
        </Box>
      )}
    </Box>
  );
};

const MemoizedCheckInCheckOutInput = React.memo(CheckInCheckOutInput);
export default MemoizedCheckInCheckOutInput;
