import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  card: {
    width: '100%',
    minWidth: 450,
    margin: '8px 0',
  },
  cardContent: {
    paddingTop: 9,
  },
  galleryContainer: {
    height: 550,
    marginBottom: 25,
    '& .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right': {
      width: 'calc(100% - 255px)',
    },
    '& .image-gallery-slides': {
      height: 550,
    },
    '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
      height: 550,
      objectFit: 'fill',
    },
    '& .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right': {
      width: 245,
    },
    '& .image-gallery-thumbnail .image-gallery-thumbnail-image': {
      width: 237,
      height: 145,
    },
    '& .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus': {
      width: 245,
    },
  },
  descAndMapContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  nameAndDescContainer: {
    flex: 3,
    marginRight: 10,
  },
  mapAndAddressContainer: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  mapContainer: {
    height: 460,
    overflow: 'hidden',
    borderRadius: 8,
  },
  addressContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  mapButtonContainer: {
    '& Button': {
      textTransform: 'none',
    },
  },
  refineSearchBtn: {
    color: theme.palette.secondary.main,
    '& .MuiButton-label': {
      textTransform: 'none',
    },
  },
  productsTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  sortByContainer: {
    marginLeft: 'auto',
    width: '30%',
  },
}));
