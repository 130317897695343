import {
  REQUEST_HOTELS_AND_COORDINATES_START,
  REQUEST_HOTELS_COORDINATES_END,
  REQUEST_HOTELS_COORDINATES_START,
  REQUEST_HOTELS_END,
  REQUEST_HOTELS_START,
} from '../actions/actionTypes';

interface FetchingState {
  hotels: boolean;
  hotelsCoordinates: boolean;
}

const initialState: FetchingState = {
  hotels: false,
  hotelsCoordinates: false,
};

export default (
  state: FetchingState = initialState, action: { type: string },
): FetchingState => {
  switch (action.type) {
    case REQUEST_HOTELS_AND_COORDINATES_START:
      return { hotels: true, hotelsCoordinates: true };
    case REQUEST_HOTELS_COORDINATES_END:
      return { ...state, hotelsCoordinates: false };
    case REQUEST_HOTELS_COORDINATES_START:
      return { ...state, hotelsCoordinates: true };
    case REQUEST_HOTELS_END:
      return { ...state, hotels: false };
    case REQUEST_HOTELS_START:
      return { ...state, hotels: true };
    default:
      return state;
  }
};
