import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    content: {
      cursor: 'pointer',
      padding: '10px 20px',
    },
    locationsContent: {
      display: 'flex',
    },
    iconContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 5,
    },
    titleContainer: {
      flex: 1,
      flexDirection: 'column',
      marginLeft: 20,
      marginRight: 20,
    },
    destinationContainer: {
      fontWeight: 'bold',
    },
    typeContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      marginTop: 10,
    },
  },
));
