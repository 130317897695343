import {
  MutableRefObject,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { Coords, Size } from 'google-map-react';

import { AvailabilityLight, MapCoordinates, MapCoordinatesNESW } from '../../../../../models';

import findZoomAndCenter from '../../findZoomAndCenter';

import { receiveHotelsAndCoordinatesOnMapChange } from '../../../../../store/actions/hotelsAction';

import useWindowDimensions from '../../../../../hooks/useWindowDimensions';

import { GOOGLE_MAP_FULL_SCREEN_TOOLBAR_HEIGHT } from '../../../../../configs/environments';

const DEFAULT_ZOOM_LEVEL = 16;

enum ActionType {
  CHANGE_LOCATION_BUTTON_HIDE = 'CHANGE_LOCATION_BUTTON_HIDE',
  CHANGE_LOCATION_BUTTON_SHOW = 'CHANGE_LOCATION_BUTTON_SHOW',
  SET_CENTER_AND_ZOOM = 'SET_CENTER_AND_ZOOM',
}

interface State {
  centerState: Coords;
  heightState: number;
  showChangeLocationBtn: boolean;
  zoomState: number;
}

const initialState: State = {
  centerState: { lat: 0, lng: 0 },
  heightState: 0,
  showChangeLocationBtn: false,
  zoomState: DEFAULT_ZOOM_LEVEL,
};

type Action =
  | { type: ActionType.CHANGE_LOCATION_BUTTON_HIDE }
  | { type: ActionType.CHANGE_LOCATION_BUTTON_SHOW }
  | { type: ActionType.SET_CENTER_AND_ZOOM, centerState: Coords, zoomState: number };

const mapContainerReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.CHANGE_LOCATION_BUTTON_HIDE:
      if (state.showChangeLocationBtn) {
        return { ...state, showChangeLocationBtn: false };
      }
      return state;
    case ActionType.CHANGE_LOCATION_BUTTON_SHOW:
      if (!state.showChangeLocationBtn) {
        return { ...state, showChangeLocationBtn: true };
      }
      return state;
    case ActionType.SET_CENTER_AND_ZOOM: return {
      ...state,
      centerState: action.centerState,
      zoomState: action.zoomState,
    };
    default: return state;
  }
};

// eslint-disable-next-line max-len
export const useLogic = (defaultCoordinates: MapCoordinates, height?: number, listCoordinates?: Array<AvailabilityLight>, zoom?: number): {
  centerState: Coords,
  heightState: number,
  showChangeLocationBtn: boolean,
  zoomState: number,
  // eslint-disable-next-line
  onDragEnd: (map: any) => void,
  // eslint-disable-next-line
  onMapLoaded: (mapProps: any) => void,
  onLocationChangedClicked: () => void,
} => {
  const dispatch = useDispatch();
  const { innerHeight, innerWidth } = useWindowDimensions();

  const hydrateInitialState = (): State => ({
    centerState: defaultCoordinates,
    heightState: height || innerHeight - GOOGLE_MAP_FULL_SCREEN_TOOLBAR_HEIGHT,
    showChangeLocationBtn: false,
    zoomState: zoom || DEFAULT_ZOOM_LEVEL,
  });

  const [state, reducerDispatch] = useReducer<Reducer<State, Action>, State>(
    mapContainerReducer, initialState, hydrateInitialState,
  );
  const {
    centerState,
    heightState,
    showChangeLocationBtn,
    zoomState,
  } = state;

  const searchCoordinates:
  MutableRefObject<MapCoordinatesNESW | null> = useRef<MapCoordinatesNESW | null>(null);
  const forceHideChangeLocationButton:
  MutableRefObject<boolean> = useRef<boolean>(false);

  useEffect(() => {
    if (listCoordinates && listCoordinates.length > 0) {
      const size: Size = { width: innerWidth, height: innerHeight };

      const { coordinates } = listCoordinates[0];
      const { calculatedCenter, calculatedZoom } = findZoomAndCenter(
        {
          size,
          defaultCenter: { lat: coordinates.latitude, lng: coordinates.longitude },
        },
        listCoordinates,
      );

      reducerDispatch({
        type: ActionType.SET_CENTER_AND_ZOOM,
        centerState: calculatedCenter,
        zoomState: calculatedZoom,
      });
      reducerDispatch({ type: ActionType.CHANGE_LOCATION_BUTTON_HIDE });

      // if it's not an initial change of `listCoordinates`
      if (searchCoordinates.current !== null) {
        forceHideChangeLocationButton.current = true;
      }
    }
  // eslint-disable-next-line
  }, [listCoordinates]);

  // eslint-disable-next-line
  const getNESWCoordinates = (map: any): MapCoordinatesNESW | null => {
    let neSwCoordinates: MapCoordinatesNESW | null;

    try {
      const bounds = map.getBounds(); // no typescript support
      const ne = bounds.getNorthEast(); // LatLng of the north-east corner
      const sw = bounds.getSouthWest(); // LatLng of the south-west corder

      neSwCoordinates = {
        ne: { lat: ne.lat(), lng: ne.lng() },
        sw: { lat: sw.lat(), lng: sw.lng() },
      };
    } catch {
      neSwCoordinates = null;
    }

    return neSwCoordinates;
  };

  // eslint-disable-next-line
  const bindZoomChangeListener = (map: any, maps: any): void => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(map, 'zoom_changed', () => {
        searchCoordinates.current = getNESWCoordinates(map);

        reducerDispatch({ type: ActionType.CHANGE_LOCATION_BUTTON_SHOW });
        
        /**
         * This check and adding the `forceHideChangeLocationButton` useRef is needed,
         * because after `listCoordinates` are changed and map library re-calculates
         * the zoom, but this triggers `zoom_changed` and it's shows the button. But
         * we don't want to be shown after new coordinates are loaded.
         */
        if (forceHideChangeLocationButton.current) {
          reducerDispatch({ type: ActionType.CHANGE_LOCATION_BUTTON_HIDE });
        }

        forceHideChangeLocationButton.current = false;
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  // eslint-disable-next-line
  const onMapLoaded = ({ map, maps }: { map: any, maps: any, ref: Element}): void => {
    // Get bounds by our places
    const bounds = map.getBounds();
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the zoom listener
    bindZoomChangeListener(map, maps);
  };

  // eslint-disable-next-line
  const onDragEnd = (map: any): void => {
    reducerDispatch({ type: ActionType.CHANGE_LOCATION_BUTTON_SHOW });
    searchCoordinates.current = getNESWCoordinates(map);
  };

  const onLocationChangedClicked = (): void => {
    if (searchCoordinates.current) {
      dispatch(receiveHotelsAndCoordinatesOnMapChange(searchCoordinates.current));
      reducerDispatch({ type: ActionType.CHANGE_LOCATION_BUTTON_HIDE });
    }
  };

  return {
    centerState,
    heightState,
    showChangeLocationBtn,
    zoomState,
    onDragEnd,
    onMapLoaded,
    onLocationChangedClicked,
  };
};
