import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const modifiedFilledInputRoot = {
  backgroundColor: '#fff',
  borderRadius: 8,
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
};

const modifiedFilledInputPadding = {
  padding: '30px 13px 12px',
};

const modifiedFilledLabelFocused = {
  color: '#000',
};

export default makeStyles((theme: Theme) => createStyles(
  {
    container: {
      display: 'flex',
      flexDirection: 'row',
      zIndex: 1,
    },
    checkInContainer: {
      marginRight: '0.5%',
    },
    checkOutContainer: {
      marginLeft: '0.5%',
    },
    textFieldClicked: {
      '& .MuiFilledInput-root': modifiedFilledInputRoot,
      '& .MuiFilledInput-input': modifiedFilledInputPadding,
      '& .MuiInputLabel-filled.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    textField: {
      '& .MuiFilledInput-root': modifiedFilledInputRoot,
      '& .MuiFilledInput-input': modifiedFilledInputPadding,
      '& .MuiFilledInput-root .MuiInputAdornment-positionStart': {
        color: theme.palette.action.disabled,
      },
      '& .MuiInputLabel-filled.Mui-focused': modifiedFilledLabelFocused,
    },
    textFieldWithValue: {
      '& .MuiFilledInput-root': modifiedFilledInputRoot,
      '& .MuiFilledInput-input': modifiedFilledInputPadding,
      '& .MuiInputLabel-filled.Mui-focused': modifiedFilledLabelFocused,
    },
    textFieldWithError: {
      '& .MuiFilledInput-root': {
        backgroundColor: theme.palette.background.default,
        borderRadius: 8,
      },
      '& .MuiFilledInput-input': modifiedFilledInputPadding,
      '& .MuiInputLabel-filled': {
        color: theme.palette.error.main,
      },
      '& .MuiInputLabel-filled.Mui-focused': modifiedFilledLabelFocused,
    },
    errorContainer: {
      color: theme.palette.error.main,
      marginLeft: 14,
      fontSize: 12,
    },
  },
));
