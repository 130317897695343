export default function headerPersistor(): { title: string; } {
  let title: { title: string; } = { title: '' };
  const headerTitle: string | null = sessionStorage.getItem('header');

  if (headerTitle) {
    title = JSON.parse(headerTitle);
  }

  return title;
}
