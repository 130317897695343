import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CheckInCheckOut as CheckInCheckOutModel, Room as RoomModel } from '../../../../models';

import { RootState } from '../../../../store/reducers';
import { setCheckInCheckOutAction } from '../../../../store/actions/checkInCheckOutAction';

export const useCommonSearchLogic = (): {
  dateRanges: CheckInCheckOutModel,
  hasCheckInCheckOutError: boolean,
  rooms: Array<RoomModel>,
  onSetDateRanges: (ranges: CheckInCheckOutModel) => void,
  onSetRooms: (roomsSelected: Array<RoomModel>) => void,
  setCheckInCheckOutHasAnError: (hasError: boolean) => void,
} => {
  const dispatch = useDispatch();

  const dateRangesRoot: CheckInCheckOutModel = useSelector(
    (state: RootState) => state.checkInCheckOut,
  );
  const roomsRoot: Array<RoomModel> = useSelector((state: RootState) => state.rooms);

  const [dateRanges, setDateRanges] = useState<CheckInCheckOutModel>(dateRangesRoot);
  const [hasCheckInCheckOutError, setCheckInCheckOutError] = useState<boolean>(false);
  const [rooms, setRooms] = useState<Array<RoomModel>>(roomsRoot);

  useEffect(() => {
    setDateRanges({ checkIn: dateRangesRoot.checkIn, checkOut: dateRangesRoot.checkOut });
  }, [dateRangesRoot]);

  const setCheckInCheckOutHasAnError = (hasError: boolean): void => (
    setCheckInCheckOutError(hasError)
  );

  const onSetDateRanges = (ranges: CheckInCheckOutModel): void => {
    dispatch(setCheckInCheckOutAction({ checkIn: ranges.checkIn, checkOut: ranges.checkOut }));
  };

  const onSetRooms = (roomsSelected: Array<RoomModel>): void => {
    setRooms(roomsSelected);
  };

  return {
    dateRanges,
    hasCheckInCheckOutError,
    rooms,
    onSetDateRanges,
    onSetRooms,
    setCheckInCheckOutHasAnError,
  };
};
