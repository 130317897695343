import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
} from '..';

import noAvailabilityImage from '../../../assets/no_availability.png';

const useStyles = makeStyles(() => createStyles({
  noResultsCard: {
    width: '100%',
    marginBottom: 8,
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 10,
    },
  },
  noResultsCardContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 886,
    padding: 8,
  },
}));

const NoResults: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.noResultsCard}>
      <CardContent className={classes.noResultsCardContainer}>
        <Box mt="100px">
          <CardMedia
            component="img"
            alt={t('noResultsFoundText')}
            image={noAvailabilityImage}
          />
          <Box mt={2} fontSize="32px" fontWeight="bold">{t('noResultsFoundText')}</Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const MemoizedNoResults = React.memo(NoResults);
export default MemoizedNoResults;
