import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckBox as CheckIcon, Clear as CloseIcon, Error as ErrorIcon } from '@material-ui/icons';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '../../../common';

import { BookingCancellationResponse } from '../../../../models';

import { currencySign } from '../../../../utils/currencyMapper';
import { CURRENCY_HUNDRED_UNIT } from '../../../../configs/environments';
import useStyles from './cancellationResponseDialogStyles';

interface CancellationResponseDialogProps {
  bookingCancellation: BookingCancellationResponse;
  isOpen: boolean;
  onClose: () => void;
}

const CancellationResponseDialog: FC<CancellationResponseDialogProps> = (
  props: CancellationResponseDialogProps,
) => {
  const { bookingCancellation, isOpen, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      className={classes.dialog}
    >
      <Box display="flex" width="100%">
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>{t('bookingReservationCancellationStatusHeader')}</DialogTitle>
        <Box display="flex">
          <IconButton aria-label="Close" classes={{ root: classes.iconContainer }} onClick={onClose}>
            <Tooltip title={t('close')}>
              <CloseIcon />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
      <DialogContent className={classes.dialogContent}>
        <Box fontSize="15px">
          { bookingCancellation.response_type === 'Success' ? (
            <>
              <CheckIcon className={classes.checkboxIcon} />
              <Box color="#008000">{t('bookingReservationCancellationRespSuccessText')}</Box>
              <Box mt={2}>{t('bookingReservationCancellationConfirmTextAndId', { confirmId: bookingCancellation.cancellation_number })}</Box>
              { bookingCancellation.cancellation_fee.amount > 0 ? (
                <Box mt={2} color="primary.main">{`${t('bookingReservationCancellationCharges')}: ${currencySign(bookingCancellation.cancellation_fee.currency)}${bookingCancellation.cancellation_fee.amount / CURRENCY_HUNDRED_UNIT} (${bookingCancellation.cancellation_fee.currency})`}</Box>
              ) : (
                null
              )}
            </>
          ) : (
            <>
              <ErrorIcon className={classes.errorIcon} />
              <Box color="#B22222">{t('bookingReservationCancellationRespFailText1')}</Box>
              <Box color="#B22222">{t('bookingReservationCancellationRespFailText2')}</Box>
              <Box mt={2}>{t('bookingReservationCancellationRespFailReason', { reason: bookingCancellation.errors[0].text })}</Box>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CancellationResponseDialog;
