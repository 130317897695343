import React, { FC } from 'react';

import { Details as LocationIcon } from '@material-ui/icons';
import { Box } from '../../../common';

import { AvailabilityLight } from '../../../../models';

import useStyles from './locationMarkerStyles';

interface LocationMarkerProps {
  data?: AvailabilityLight;
}

const LocationMarker: FC<LocationMarkerProps> = (props: LocationMarkerProps) => {
  const { data } = props;
  const classes = useStyles();

  return (
    <>
      <LocationIcon stroke="white" strokeWidth={1} className={classes.marker} />
      { data && (
        <Box className={classes.markerTooltipContent}>
          <Box component="span" className={classes.markerTooltipText}>
            {data.property_info.name}
          </Box>
        </Box>
      ) }
    </>
  );
};

const MemoizedLocationMarker = React.memo(LocationMarker);
export default MemoizedLocationMarker;
