import React, { FC, useCallback } from 'react';

import { Box } from '../../common';

import { HOTEL_LIST_DESC_TRIM_LENGTH } from '../../../configs/environments';

const DEFAULT_TRIM_LENGTH = HOTEL_LIST_DESC_TRIM_LENGTH;
const PADDED_END_CHARS_LENGTH = 3;
const TRIM_CHAR = '.';

interface HotelProps {
  description: string;
  trimLength?: number;
}

const HotelDescription: FC<HotelProps> = (props: HotelProps) => {
  const { description, trimLength } = props;

  const trimmedDescription = useCallback((): string => {
    const { length } = description;
    const trim = trimLength || DEFAULT_TRIM_LENGTH;

    return length > trim
      ? description.substring(0, trim - PADDED_END_CHARS_LENGTH).padEnd(trim, TRIM_CHAR)
      : description;
  }, [description, trimLength]);

  return (
    <Box>
      <Box fontSize={10}>{trimmedDescription}</Box>
    </Box>
  );
};

export default HotelDescription;
