import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BookingsContainer from '../components/booking/BookingsContainer';
import HomeContainer from '../components/home/HomeContainer';
import HotelContainer from '../components/hotel/HotelContainer';
import LoginContainer from '../components/login/LoginContainer';
import PrivateRoute from './PrivateRoute';

import { BOOKING_RESERVATIONS, HOME_ROUTE_PATH, LOGIN_ROUTE_PATH } from './routesPath';

const Routes: React.FC = () => (
  <Switch>
    <Route path={LOGIN_ROUTE_PATH} component={LoginContainer} />
    <PrivateRoute exact path={HOME_ROUTE_PATH} component={HomeContainer} />
    <PrivateRoute path={BOOKING_RESERVATIONS} component={BookingsContainer} />
    <PrivateRoute component={HotelContainer} />
  </Switch>
);

export default Routes;
