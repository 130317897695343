import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AvailabilityLight } from '../../../../../../models';

import { selectedProperty } from '../../../../../../store/actions/propertyAction';

import { HOTEL_ROUTE_PATH } from '../../../../../../routes/routesPath';

export const useLogic = (data?: AvailabilityLight): { handleClick: () => void } => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = (): void => {
    if (data) {
      const { property_info } = data;

      dispatch(selectedProperty({ id: property_info.property_id, name: property_info.name }));
      history.push(HOTEL_ROUTE_PATH);
    }
  };

  return { handleClick };
};
