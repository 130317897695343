import React, { FC } from 'react';

import {
  Accessible,
  AcUnit,
  AirportShuttle,
  BusinessCenter,
  Language,
  LocalBar,
  LocalCafe,
  LocalParking,
  Pets,
  Pool,
  Restaurant,
  RoomService,
  Spa,
  Wifi,
} from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Box } from '..';

const amenityIcon: { [key: number]: React.ReactElement<SvgIconProps> } = {
  14: <AcUnit />, // amenityName: Air conditioning
  13: <LocalBar />, // amenityName: Bar/Pub
  12: <LocalCafe />, // amenityName: Breakfast Included
  11: <BusinessCenter />, // amenityName: Business Center
  10: <LocalParking />, // amenityName: Car Park
  9: <Accessible />, // amenityName: Disability-Friendly
  8: <AirportShuttle />, // amenityName: Hotel Shuttle
  7: <Wifi />, // amenityName: Internet access/WiFi
  6: <Language />, // amenityName: Multi-Lingual Staff
  5: <Pets />, // amenityName: Pet Friendly
  4: <Pool />, // amenityName: Pool ( Pool/Indoor Pool/Outdoor Pool)
  3: <Restaurant />, // amenityName: Restaurant/Café
  2: <RoomService />, // amenityName: Room Service
  1: <Spa />, // amenityName: Spa
};

interface AmenityIconProps {
  factId: number;
}

const AmenityIcon: FC<AmenityIconProps> = (props: AmenityIconProps) => {
  const { factId } = props;

  return (
    <Box minWidth={'25px'}>{ amenityIcon[factId] }</Box>
  );
};

export default AmenityIcon;
