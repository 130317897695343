import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  Tab,
  Tabs,
} from '../../common';

import BookingsLoading from '../bookingsLoading/BookingsLoading';
import BookingTabContent from '../bookingTabContent/BookingTabContent';
import Pagination from '../../common/pagination/Pagination';

import { useLogic } from './hooks/useLogic';

const useStyles = makeStyles(() => createStyles({
  paper: {
    borderRadius: 8,
  },
  tab: {
    zIndex: 1,
    '&.Mui-selected': {
      color: '#fff',
      fontWeight: 'bold',
    },
  },
}));

const Bookings: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    isFetching,
    page,
    reservations,
    tabIndex,
    handleTabChange,
    onPageChange,
  } = useLogic();

  return (
    <>
      <Box fontSize="h5.fontSize" p={'10px 0 5px 16px'}>{t('bookingReservationsManagementHeader')}</Box>
      <Paper className={classes.paper}>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          variant="fullWidth"
          TabIndicatorProps={{
            style: { height: 48, borderRadius: 8 },
          }}
          onChange={handleTabChange}
        >
          <Tab label={t('bookingsTabActiveReservationText')} classes={{ root: classes.tab }} />
          <Tab label={t('bookingsTabFailedCancellationsText')} classes={{ root: classes.tab }} />
          <Tab label={t('bookingsTabCancelledReservationsText')} classes={{ root: classes.tab }} />
        </Tabs>
      </Paper>
      { isFetching && <BookingsLoading /> }
      { !isFetching && (
        <BookingTabContent
          value={tabIndex}
          index={tabIndex}
          items={reservations}
        />
      ) }
      <Pagination
        forcePage={page}
        paginatedPage={reservations.number}
        totalElements={reservations.totalElements}
        totalPages={reservations.totalPages}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default Bookings;
