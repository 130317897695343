import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    textfield: {
      '& .MuiInputBase-inputMarginDense': {
        paddingTop: '18.5px',
        paddingBottom: '18.5px',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#000',
      },
    },
    cardNumberContent: {
      width: '90%',
      marginBottom: 20,
    },
    expiryDateContent: {
      marginBottom: 20,
    },
    paymentIconContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '80%',
      marginTop: 130,
    },
    paymentIcon: {
      width: 60,
      height: 40,
    },
  },
));
