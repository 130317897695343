import React, { FC } from 'react';

import LocationMarker from './locationMarker/LocationMarker';
import MapMarker from './mapMarker/MapMarker';

import { AvailabilityLight } from '../../../models';

interface MarkerProps {
  lat: number;
  lng: number;
  data?: AvailabilityLight;
}

const Marker: FC<MarkerProps> = (props: MarkerProps) => {
  // eslint-disable-next-line
  const { lat, lng, data } = props;

  return (
    <>
      { data?.fake
        ? (<LocationMarker data={data} />)
        : (<MapMarker data={data} />
        )}
    </>
  );
};

const MemoizedMarker = React.memo(Marker);
export default MemoizedMarker;
