import React, { FC } from 'react';

import { Box } from '../../common';

import AvailabilitySortBy from '../sortBy/AvailabilitySortBy';
import FilterContainer from '../filters/FilterContainer';
import HotelBreadcrumbContainer from '../hotelBreadcrumbs/HotelBreadcrumbContainer';
import HotelsList from '../hotelsList/Hotels';
import HotelsLoading from '../hotelsLoading/HotelsLoading';
import HotelsMapContainer from '../../map/hotelsMapContainer/HotelsMapContainer';
import HotelSearchBar from '../../search/hotelSearchBar/HotelSearchBar';
import NoResults from '../../common/noResults/NoResults';
import Pagination from '../../common/pagination/Pagination';

import { useLogic } from './hooks/useLogic';

import useStyles from './hotelsPageStyles';

const HotelsPage: FC<unknown> = () => {
  const classes = useStyles();
  const {
    breadcrumbs,
    isFetching,
    isFetchMade,
    hotels,
    onPageChange,
  } = useLogic();

  return (
    <Box>
      <Box className={classes.hotelSearchBarContainer}>
        <HotelSearchBar />
      </Box>
      <Box className={classes.layoutContainer}>
        <Box className={classes.mapAndFilterContainer}>
          <HotelsMapContainer />
          <FilterContainer />
        </Box>
        <Box className={classes.hotelsContainer}>
          <HotelBreadcrumbContainer breadcrumbsItems={breadcrumbs} />
          <Box className={classes.sortByContainer}>
            <AvailabilitySortBy listPage />
          </Box>
          { isFetching && <HotelsLoading /> }
          { !isFetching && hotels.content.length > 0 && (
            <>
              <HotelsList hotels={hotels.content} />
              <Pagination
                forcePage={hotels.number}
                paginatedPage={hotels.number}
                totalElements={hotels.totalElements}
                totalPages={hotels.totalPages}
                onPageChange={onPageChange}
              />
            </>
          ) }
          { !isFetching && isFetchMade.current && hotels.content.length === 0 && <NoResults /> }
        </Box>
      </Box>
    </Box>
  );
};

export default HotelsPage;
