import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Button } from '../../../common';

const useStyles = makeStyles((theme: Theme) => createStyles({
  filterButton: {
    padding: 0,
    textTransform: 'none',
    minWidth: 45,
  },
  applyButton: {
    '&:hover': {
      background: theme.palette.secondary.main,
      color: '#fff',
    },
  },
  resetButton: {
    '&:hover': {
      background: theme.palette.primary.main,
      color: '#fff',
    },
  },
}));

interface ClearFiltersProps {
  showApply?: boolean;
  onSelectionApply?: () => void;
  showClear?: boolean;
  onSelectionClear?: () => void;
}

const ClearFilters: FC<ClearFiltersProps> = (props: ClearFiltersProps) => {
  const {
    showApply,
    showClear,
    onSelectionApply,
    onSelectionClear,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex">
      { showApply && (
        <Button
          className={[classes.filterButton, classes.applyButton].join(' ')}
          onClick={onSelectionApply}
        >
          {t('applyButtonTitle')}
        </Button>
      ) }
      { showClear && (
        <Button
          className={[classes.filterButton, classes.resetButton].join(' ')}
          onClick={onSelectionClear}
        >
          {t('clearButtonTitle')}
        </Button>
      ) }
    </Box>
  );
};

const MemoizedClearFilters = React.memo(ClearFilters);
export default MemoizedClearFilters;
