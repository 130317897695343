import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    dialog: {
      '& .MuiDialogContent-root': {
        padding: 24,
      },
      '& .MuiDialog-paper': {
        minWidth: 600,
        alignItems: 'center',
      },
    },
    dialogTitle: {
      width: '100%',
      textAlign: 'center',
      marginRight: -50,
    },
    dialogContent: {
      textAlign: 'center',
    },
    iconContainer: {
      color: '#000',
      '&:hover': {
        background: 'none',
      },
    },
    checkboxIcon: {
      color: '#008000',
      width: 30,
      height: 30,
    },
    errorIcon: {
      color: '#B22222',
      width: 35,
      height: 35,
    },
  },
));
