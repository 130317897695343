import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    hotelsContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    hotelContainer: {
      marginBottom: 8,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
));
