import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const textFontSize = 16;

export default makeStyles((theme: Theme) => createStyles(
  {
    card: {
      width: '100%',
      margin: '8px 0',
      '& .MuiCardContent-root': {
        padding: '0 16px',
      },
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 50,
    },
    paper: {
      borderRadius: 8,
    },
    tab: {
      zIndex: 1,
      '&.Mui-selected': {
        color: '#fff',
        fontWeight: 'bold',
      },
    },
    itemText: {
      flex: 0.13,
      textAlign: 'start',
      fontSize: textFontSize,
    },
    legendText: {
      flex: 0.25,
      textAlign: 'end',
      fontSize: textFontSize,
    },
    paginationContent: {
      flex: 1,
      '& .pagination': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .pagination > li': {
        listStyle: 'none',
        margin: '0 3px',
      },
      '& .pagination > li > a, .pagination > li > span': {
        fontSize: textFontSize,
        padding: '0 10px',
        margin: '0, 2px',
      },
      '& .pagination > li.active > a': {
        padding: '3px 9px',
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        fontWeight: 'bold',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
      },
      '& .pagination > li.active > a:hover': {
        cursor: 'default',
      },
      '& .pagination > li > a:hover': {
        cursor: 'pointer',
        padding: '3px 9px',
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        fontWeight: 'bold',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
      },
      '& .pagination > li.previous > a:hover, .pagination > li.next > a:hover': {
        color: '#fff',
        padding: '4px 10px',
        backgroundColor: theme.palette.primary.main,
        border: 'none',
      },
      '& .pagination > li.previous.disable > a, .pagination > li.next.disable > a': {
        cursor: 'default',
        color: 'gainsboro',
        background: 'none',
      },
      '& .pagination > li.previous.disable > a:hover, .pagination > li.next.disable > a:hover': {
        cursor: 'default',
        color: 'gainsboro',
      },
    },
  },
));
