import { SET_HOTELS_LIST_PAGE } from './actionTypes';

const hotelsCurrentPage = (page: number): { type: string, payload: number } => {
  sessionStorage.setItem('hotelsPage', JSON.stringify(page));

  return {
    type: SET_HOTELS_LIST_PAGE,
    payload: page,
  };
};

export {
  hotelsCurrentPage,
};
