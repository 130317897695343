import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '& .MuiFilledInput-root, .MuiSelect-select:focus': {
      backgroundColor: theme.palette.background.default,
      borderRadius: 8,
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    '& .MuiSelect-root.Mui-error': {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.error.main}`,
      borderRadius: 8,
      boxShadow: 'none',
    },
    '& .MuiInputLabel-filled.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  checkOutContainer: {},
  icon: {
    color: '#000',
  },
  iconDisabled: {
    color: theme.palette.action.disabled,
  },
}));
