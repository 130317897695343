import React, { FC } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';

import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '../../common';

import MapContainer from '../mapContainer/MapContainer';

import { AvailabilityLight, MapCoordinates } from '../../../models';

import { GOOGLE_MAP_FULL_SCREEN_TOOLBAR_HEIGHT } from '../../../configs/environments';

const useStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    minHeight: GOOGLE_MAP_FULL_SCREEN_TOOLBAR_HEIGHT,
  },
  progressDialog: {
    '& .MuiDialog-paperFullScreen': {
      backgroundColor: 'transparent',
    },
  },
}));

const CircularProgressContainer: FC<unknown> = () => (
  <Box position="absolute" top="50%" left="50%">
    <CircularProgress size={100} thickness={5} />
  </Box>
);

interface FullScreenMapDialogProps {
  defaultCoordinates: MapCoordinates;
  isFetchingListCoordinates?: boolean;
  listCoordinates?: Array<AvailabilityLight>;
  open: boolean;
  title?: string;
  zoom?: number;
  handleClose: () => void;
}

const FullScreenMapDialog: FC<FullScreenMapDialogProps> = (props: FullScreenMapDialogProps) => {
  const {
    defaultCoordinates,
    isFetchingListCoordinates,
    listCoordinates,
    open,
    title,
    zoom,
    handleClose,
  } = props;
  const classes = useStyles();

  const hasCoordinates: boolean = defaultCoordinates.lat !== 0 && defaultCoordinates.lng !== 0;

  return (
    <Box>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            { title && (
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            ) }
          </Toolbar>
        </AppBar>
        { hasCoordinates ? (
          <>
            <MapContainer
              defaultCoordinates={defaultCoordinates}
              listCoordinates={listCoordinates}
              zoom={zoom}
            />
            { isFetchingListCoordinates && (
              <Dialog
                fullScreen
                open={isFetchingListCoordinates}
                className={classes.progressDialog}
              >
                <CircularProgressContainer />
              </Dialog>
            ) }
          </>
        ) : (
          <CircularProgressContainer />
        )}
      </Dialog>
    </Box>
  );
};

export default FullScreenMapDialog;
