import Moment from 'moment';
import {
  AvailabilitySearchCriteriaIdsParams,
  AvailabilitySearchCriteriaLocationParams,
  AvailabilitySearchParams,
  CheckInCheckOut as CheckInCheckOutModel,
  FiltersStoreData,
  Location,
  RadiusSelection,
  Room as RoomModel,
  RoomCandidateSearchParams,
  RoomGuestsSearchParams,
} from '../models';
import AgeQualifyingCode from '../models/AgeQualifyingCode.enum';
import { AvailabilitySearchCriteriaRadiusParams, AvailabilitySearchPropertyFilter } from '../models/AvailabilitySearchParams';

const dateFormatString = 'YYYY-MM-DD';
function isPropertyIdsType(arg: Array<number>): arg is Array<number> {
  return Array.isArray(arg);
}

// eslint-disable-next-line max-len
const getCircleAreaParams = (radiusSelected: RadiusSelection, location: Location): AvailabilitySearchCriteriaRadiusParams => {
  const valueInKM: number | string = radiusSelected.selected.unit === 'km'
    ? radiusSelected.selected.value
    : radiusSelected.opposite.value;
  const valueInMeters: number = 1000 * (valueInKM as number);
  return {
    radius: valueInMeters,
    coordinates: {
      latitude: location.latitude,
      longitude: location.longitude,
    },
  };
};

export const constructAvailabilityRequestParams = (
  currency: string,
  dateRangesArg: CheckInCheckOutModel,
  roomsArg: Array<RoomModel>,
  propertyOrSuggestionArg: Array<number> | Location,
  hotelSortBy: Array<string>,
  hotelFilters?: FiltersStoreData,
  radiusSelected?: RadiusSelection,
): AvailabilitySearchParams => {
  const roomCandidates: Array<RoomCandidateSearchParams> = [];
  const roomsCount: number = roomsArg.length;
  
  // iterate over the rooms
  for (let i = 0; i < roomsCount; i += 1) {
    const roomGuests: Array<RoomGuestsSearchParams> = [];
    const currentRoom: RoomModel = roomsArg[i];

    const roomAdults: RoomGuestsSearchParams = {
      age_qualifying_code: AgeQualifyingCode.ADULT,
      count: currentRoom.adults,
    };

    roomGuests.push(roomAdults);

    const uniqueChildren: { [key: number]: number; } = {};
    const childCount: number = currentRoom.agesOfChild.length;

    // iterate over the children in the room to extract these with the same ages
    for (let j = 0; j < childCount; j += 1) {
      const currentAge: number = currentRoom.agesOfChild[j];

      if (Object.prototype.hasOwnProperty.call(uniqueChildren, currentAge)) {
        uniqueChildren[currentAge] += 1;
      } else {
        uniqueChildren[currentAge] = 1;
      }
    }

    const uniqueChildrenKeys: Array<string> = Object.keys(uniqueChildren);
    
    // iterate over the children with same ages to group the common ages
    for (let k = 0; k < uniqueChildrenKeys.length; k += 1) {
      const childrenAge: number = +uniqueChildrenKeys[k];

      const roomChildren: RoomGuestsSearchParams = {
        age: childrenAge,
        age_qualifying_code: AgeQualifyingCode.CHILD,
        count: +uniqueChildren[childrenAge],
      };

      roomGuests.push(roomChildren);
    }

    roomCandidates.push({ guests: roomGuests });
  }

  let searchCriteria:
  AvailabilitySearchCriteriaIdsParams | AvailabilitySearchCriteriaLocationParams;

  // if-else, assume there are two types at the time
  if (isPropertyIdsType(propertyOrSuggestionArg as Array<number>)) {
    // enter when search by propertyId
    searchCriteria = {
      hotel_ids: propertyOrSuggestionArg as Array<number>,
      property_filter: { sort_criteria: hotelSortBy } as AvailabilitySearchPropertyFilter,
    };
  } else {
    // enter when search by location
    const suggestionArg: Location = propertyOrSuggestionArg as Location;
    let property_filter: AvailabilitySearchPropertyFilter | undefined;

    if (hotelFilters) {
      property_filter = {
        amenity_ids: hotelFilters.amenity,
        chain_ids: hotelFilters.chain,
        neighborhood_ids: hotelFilters.neighborhood,
        poi_id: hotelFilters.location,
        ratings: hotelFilters.rating,
        sort_criteria: hotelSortBy,
      };
    }

    let circleArea: AvailabilitySearchCriteriaRadiusParams | undefined;

    if (radiusSelected.selected.value !== 'None') {
      circleArea = getCircleAreaParams(radiusSelected, suggestionArg);
    }

    searchCriteria = {
      circle_area: circleArea,
      hotel_location_ref: {
        destination_type: suggestionArg.destination_type,
        location_id: suggestionArg.id,
      },
      property_filter,
    };
  }

  return {
    currency,
    date_range: {
      check_in: Moment(dateRangesArg.checkIn).format(dateFormatString),
      check_out: Moment(dateRangesArg.checkOut).format(dateFormatString),
    },
    room_candidates: roomCandidates,
    search_criteria: searchCriteria,
  };
};
