import { STORE_HOTELS } from '../actions/actionTypes';

import { Availability } from '../../models';
import ApiList, { getEmptyApiList } from '../../models/ApiList';

interface HotelsReducer {
  items: ApiList<Availability>;
}

const initialState: HotelsReducer = {
  items: getEmptyApiList(),
};

export default (
  state: HotelsReducer = initialState,
  action: { type: string; payload: HotelsReducer },
): HotelsReducer => {
  if (action.type === STORE_HOTELS) {
    return {
      ...state,
      items: action.payload.items,
    };
  }

  return state;
};
