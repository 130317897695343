import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.background.default,
      borderRadius: 0,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      boxShadow: '2px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    '& .MuiFilledInput-root.Mui-error': {
      backgroundColor: theme.palette.background.default,
      borderRadius: 0,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      borderRightColor: theme.palette.background.default,
    },
    '& .MuiFilledInput-inputAdornedStart': {
      paddingLeft: '10px !important',
      paddingRight: '10px !important',
    },
    '& .MuiFilledInput-input': {
      padding: '30px 0 12px',
    },
    '& .MuiInputLabel-filled.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  icon: {
    color: '#000',
  },
  iconDisabled: {
    color: theme.palette.action.disabled,
  },
}));
