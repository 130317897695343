export const CLEAR_ALL_SELECTED = 'CLEAR_ALL_SELECTED';
export const CLEAR_ALL_SELECTED_HOTEL_AMENITIES = 'CLEAR_ALL_SELECTED_HOTEL_AMENITIES';
export const CLEAR_ALL_SELECTED_HOTEL_CHAINS = 'CLEAR_ALL_SELECTED_HOTEL_CHAINS';
export const CLEAR_ALL_SELECTED_HOTEL_NEIGHBORHOODS = 'CLEAR_ALL_SELECTED_HOTEL_NEIGHBORHOODS';
export const CLEAR_ALL_SELECTED_HOTEL_RATINGS = 'CLEAR_ALL_SELECTED_HOTEL_RATINGS';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const REQUEST_HOTELS_AND_COORDINATES_START = 'REQUEST_HOTELS_AND_COORDINATES_START';
export const REQUEST_HOTELS_COORDINATES_END = 'REQUEST_HOTELS_COORDINATES_END';
export const REQUEST_HOTELS_COORDINATES_START = 'REQUEST_HOTELS_COORDINATES_START';
export const REQUEST_HOTELS_END = 'REQUEST_HOTELS_END';
export const REQUEST_HOTELS_START = 'REQUEST_HOTELS_START';

export const SEARCH_BUTTON_CLICKED = 'SEARCH_BUTTON_CLICKED';
export const SEARCH_BUTTON_NOT_CLICKED = 'SEARCH_BUTTON_NOT_CLICKED';

export const SET_HOTELS_HEADER_SEARCHED_TITLE = 'SET_HOTELS_HEADER_SEARCHED_TITLE';

export const SET_HOTELS_LIST_PAGE = 'SET_HOTELS_LIST_PAGE';

export const SET_SELECTED_CHECK_IN_CHECK_OUT = 'SET_SELECTED_CHECK_IN_CHECK_OUT';

export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';

export const SET_SELECTED_RADIUS = 'SET_SELECTED_RADIUS';

export const SET_SELECTED_ROOMS = 'SET_SELECTED_ROOMS';

export const SET_SORT_BY_DETAILS_PAGE = 'SET_SORT_BY_DETAILS_PAGE';
export const SET_SORT_BY_LIST_PAGE = 'SET_SORT_BY_LIST_PAGE';

export const STORE_AVAILABILITY_SEARCH_PARAMS = 'STORE_AVAILABILITY_SEARCH_PARAMS';

export const SET_HOTEL_NEIGHBORHOOD = 'SET_HOTEL_NEIGHBORHOOD';
export const SET_POPULAR_LOCATIONS = 'SET_POPULAR_LOCATIONS';
export const STORE_HOTEL_AMENITY = 'STORE_HOTEL_AMENITY';
export const STORE_HOTEL_CHAIN = 'STORE_HOTEL_CHAIN';
export const STORE_HOTEL_NEIGHBORHOOD = 'STORE_HOTEL_NEIGHBORHOOD';
export const STORE_HOTEL_RATING = 'STORE_HOTEL_RATING';

export const STORE_AVAILABILITY_NEIGHBORHOOD = 'STORE_AVAILABILITY_NEIGHBORHOOD';

export const REMOVE_HOTEL_AMENITY = 'REMOVE_HOTEL_AMENITY';
export const REMOVE_HOTEL_CHAIN = 'REMOVE_HOTEL_CHAIN';
export const REMOVE_HOTEL_NEIGHBORHOOD = 'REMOVE_HOTEL_NEIGHBORHOOD';
export const REMOVE_HOTEL_RATING = 'REMOVE_HOTEL_RATING';
export const REMOVE_POPULAR_LOCATION = 'REMOVE_POPULAR_LOCATION';

export const STORE_HOTELS = 'STORE_HOTELS';
export const STORE_HOTELS_COORDINATES = 'STORE_HOTELS_COORDINATES';
export const STORE_POPULAR_LOCATION = 'STORE_POPULAR_LOCATION';
export const STORE_PROPERTY_DATA = 'STORE_PROPERTY_DATA';

export const STORE_SELECTED_PRODUCT = 'STORE_SELECTED_PRODUCT';
