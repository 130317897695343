import { i18n } from 'i18next';
import { getI18n } from 'react-i18next';
import { SET_HOTELS_HEADER_SEARCHED_TITLE } from './actionTypes';
import { RootState } from '../reducers';

const setHotelsHeaderTitleAction = (title: string): { type: string, payload: string } => {
  sessionStorage.setItem('header', JSON.stringify({ title }));

  return {
    type: SET_HOTELS_HEADER_SEARCHED_TITLE,
    payload: title,
  };
};

// eslint-disable-next-line
const setHotelsHeaderAction = () => {
  // eslint-disable-next-line
  return (dispatch: any, getState: () => RootState) => {
    const state: RootState = getState();
    let title = '';

    if (state.location.id !== 'null') {
      const i18next: i18n = getI18n();
      const locationName: string = state.location.location_name;
      title = i18next.t('headerHotelsList', { locationName });
    }

    dispatch(setHotelsHeaderTitleAction(title));
  };
};

// eslint-disable-next-line
const setHotelDetailsHeaderAction = (hotelName: string) => {
  // eslint-disable-next-line
  return (dispatch: any, getState: () => RootState) => {
    const i18next: i18n = getI18n();
    const locationName: string = getState().location.location_name;
    const titleI18n = i18next.t('headerHotelDetails', { hotelName, locationName });

    dispatch(setHotelsHeaderTitleAction(titleI18n));
  };
};

// eslint-disable-next-line
const setBookingConfirmationHederAction = (hotelName: string, locationName: string) => {
  // eslint-disable-next-line
  return (dispatch: any) => {
    const i18next: i18n = getI18n();
    let titleI18n = i18next.t('headerHotelDetails', { hotelName, locationName });

    if (!hotelName && !locationName) {
      titleI18n = '';
    }

    dispatch(setHotelsHeaderTitleAction(titleI18n));
  };
};

export {
  setBookingConfirmationHederAction,
  setHotelDetailsHeaderAction,
  setHotelsHeaderAction,
};
