import React, { FC } from 'react';

import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import { Box } from '../../../common';

import DefaultNoImage from '../../../common/defaultNoImage/DefaultNoImage';

import 'react-image-gallery/styles/css/image-gallery.css';
import './react-image-gallery.css';

interface PropertyDetailsProps {
  images: Array<ReactImageGalleryItem> | null;
}

const PropertyDetailsGallery: FC<PropertyDetailsProps> = (props: PropertyDetailsProps) => {
  const { images } = props;

  return (
    <>
      { images && images.length > 0 ? (
        <ImageGallery
          items={images}
          showBullets={false}
          showFullscreenButton={false}
          showNav={true}
          showPlayButton={false}
          thumbnailPosition={'right'}
        />
      ) : (
        <Box height={'550px'}>
          <DefaultNoImage bigImg height={'550px'} />
        </Box>
      )}
    </>
  );
};

const MemoizedPropertyDetailsGallery = React.memo(PropertyDetailsGallery);
export default MemoizedPropertyDetailsGallery;
