import React, { FC, ReactNode, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '../../common';

import { selectedProperty } from '../../../store/actions/propertyAction';

import DefaultNoImage from '../../common/defaultNoImage/DefaultNoImage';
import HotelDescription from '../hotelDescription/HotelDescription';
import HotelLoadingCard from '../hotelsLoading/hotelLoadingCard/HotelLoadingCard';
import HotelRating from '../hotelRating/HotelRating';

import { Availability } from '../../../models';

import { HOTEL_ROUTE_PATH } from '../../../routes/routesPath';
import { currencySign } from '../../../utils/currencyMapper';
import { CURRENCY_HUNDRED_UNIT } from '../../../configs/environments';

import useStyles from './hotelListUnitStyles';

interface HotelProps {
  hotel: Availability;
}

const Hotel: FC<HotelProps> = (props: HotelProps) => {
  const { hotel } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const onHotelClicked = useCallback(() => {
    const propertyId: number = hotel.property_info.property_id;
    const propertyName: string = hotel.property_info.name;

    dispatch(selectedProperty({ id: propertyId, name: propertyName }));

    history.push(HOTEL_ROUTE_PATH);
  }, [history, hotel, dispatch]);

  const mainImage = (): ReactNode => {
    const mainImg = hotel.property_info?.primary_image?.url;

    try {
      if (!mainImg) {
        throw new Error();
      }

      return (
        <CardMedia
          component="img"
          alt={hotel.property_info.primary_image.title}
          image={mainImg}
          className={classes.cardMedia}
        />
      );
    } catch {
      return <DefaultNoImage />;
    }
  };

  try {
    return (
      <Card className={classes.hotelCard}>
        <CardContent className={classes.hotelCardContainer}>
          <Box className={classes.imageContainer} onClick={onHotelClicked}>
            { mainImage() }
          </Box>
          <Box className={classes.content}>
            <Box className={classes.nameAndDescriptionContainer}>
              <Typography
                variant="h3"
                variantMapping={{ h3: 'h3' }}
                className={classes.nameContainer}
                onClick={onHotelClicked}
              >
                {hotel.property_info.name}
              </Typography>
              { hotel.property_info.rating && <HotelRating rating={hotel.property_info.rating} /> }
              <Box mt="10px">
                <HotelDescription description={hotel.property_info.description || ''} />
              </Box>
            </Box>
            <Box className={classes.pricesContainer}>
              <Box className={classes.priceContainer}>
                {hotel.best_price && <Box>{t('hotelListPriceFrom', { price: `${currencySign(hotel.best_price.currency)}${hotel.best_price.amount / CURRENCY_HUNDRED_UNIT}` })}</Box>}
              </Box>
              <Box className={classes.buttonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onHotelClicked}
                >
                  {t('hotelListShowPricesButton')}
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  } catch {
    return <HotelLoadingCard />;
  }
};

export default Hotel;
