import { SET_HOTEL_NEIGHBORHOOD } from '../actions/actionTypes';

import { Neighborhood } from '../../models';

const initialState: Array<Neighborhood> = [];

// eslint-disable-next-line max-len
export default (state: Array<Neighborhood> = initialState, action: { type: string; payload: Array<Neighborhood> }): Array<Neighborhood> => {
  if (action.type === SET_HOTEL_NEIGHBORHOOD) {
    return action.payload;
  }

  return state;
};
