import { SET_SELECTED_ROOMS } from '../actions/actionTypes';

import { Room } from '../../models';

import configs from '../../configs/environments';

const roomDefault: Room = {
  agesOfChild: [],
  adults: configs.room.adults.default,
  children: configs.room.children.default,
};

const initialState: Array<Room> = [roomDefault];

export default (
  state: Array<Room> = initialState, action: { type: string; payload: Array<Room> },
): Array<Room> => {
  if (action.type === SET_SELECTED_ROOMS) {
    return action.payload;
  }

  return state;
};
