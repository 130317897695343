import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AvailabilityProduct } from '../../../../../../models';

import { BOOKING_ROUTE_PATH, HOTEL_ROUTE_PATH } from '../../../../../../routes/routesPath';

import { selectedProduct } from '../../../../../../store/actions/bookingAction';

export const useLogic = (
  product: AvailabilityProduct,
  propertyId: number,
// eslint-disable-next-line
): { expanded: boolean; onBookButtonClicked: () => void; onExpandCollapse: () => void; } => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState<boolean>(false);

  const onBookButtonClicked = (): void => {
    dispatch(selectedProduct(product));
    history.push(`${HOTEL_ROUTE_PATH}/${propertyId}${BOOKING_ROUTE_PATH}`);
  };

  const onExpandCollapse = (): void => (
    setExpanded((expandedPrev) => !expandedPrev)
  );

  return {
    expanded,
    onBookButtonClicked,
    onExpandCollapse,
  };
};
