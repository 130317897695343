import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import nextId from 'react-id-generator';

import { Star } from '@material-ui/icons';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
} from '../../../common';

import ClearFilters from '../clearFilters/ClearFilters';

import { useLogic } from './hooks/useLogic';

import useStyles from './ratingFilterStyles';

const RatingFilter: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    ratings,
    ratingsSelect,
    onRatingsSelected,
    onRatingsSelectionClear,
  } = useLogic();

  return (
    <>
      <Box className={classes.ratingTitleContent}>
        <Box display="flex">
          <Box fontWeight="bold" lineHeight={1.8}>{t('starRatingTitle')}</Box>
        </Box>
        <ClearFilters
          showClear
          onSelectionClear={onRatingsSelectionClear}
        />
      </Box>
      <FormControl className={classes.form}>
        { ratings.map((rating: number, index: number) => (
          <FormControlLabel
            key={rating}
            value={rating}
            control={(
              <Checkbox
                inputProps={{
                  'data-rating-index': `${rating}-${index}`,
                // eslint-disable-next-line
                } as any}
                color="default"
                classes={{ root: classes.checkbox }}
                onChange={onRatingsSelected}
                checked={ratingsSelect[index]}
              />
            )}
            // eslint-disable-next-line
            label={(
              rating > 0
                ? (Array.from({ length: rating }).map(() => <Star key={nextId('strate-')} fontSize="small" className={ratingsSelect[index] ? classes.startChecked : classes.startUnchecked} />))
                : (<Box fontSize="14px">{t('starRatingUnrated')}</Box>)
            )}
            className={classes.formControlLabel}
          />
        ))}
      </FormControl>
    </>
  );
};

export default RatingFilter;
