import { Reducer, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import { FocusedInputShape } from 'react-dates';

import { CheckInCheckOut as CheckInCheckOutModel } from '../../../../../models';

import { RootState } from '../../../../../store/reducers';

import configs from '../../../../../configs/environments';

const FOCUS_START_DATE = 'startDate';

enum ActionType {
  SET_END_RANGE = 'SET_END_RANGE',
  SET_INPUT_FOCUS = 'SET_INPUT_FOCUS',
  SET_MODAL_CLOSE_AND_FOCUS = 'SET_MODAL_CLOSE_AND_FOCUS',
  SET_MODAL_OPEN = 'SET_MODAL_OPEN',
  SET_RANGES = 'SET_RANGES',
}

interface State {
  isOpen: boolean;
  startDateState: Moment.Moment | null;
  endDateState: Moment.Moment | null;
  focusedInput: FocusedInputShape;
}

const initialState: State = {
  isOpen: false,
  startDateState: null,
  endDateState: null,
  focusedInput: FOCUS_START_DATE,
};

type Action =
  | { type: ActionType.SET_END_RANGE, endDateState: Moment.Moment | null }
  | { type: ActionType.SET_INPUT_FOCUS, focusedInput: FocusedInputShape }
  | { type: ActionType.SET_MODAL_CLOSE_AND_FOCUS, focusedInput: FocusedInputShape }
  | { type: ActionType.SET_MODAL_OPEN }
  // eslint-disable-next-line
  | { type: ActionType.SET_RANGES, startDateState: Moment.Moment | null, endDateState: Moment.Moment | null };

const checkInCheckOutReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_END_RANGE: return {
      ...state,
      endDateState: action.endDateState,
    };
    case ActionType.SET_INPUT_FOCUS: return {
      ...state,
      focusedInput: action.focusedInput,
    };
    case ActionType.SET_MODAL_CLOSE_AND_FOCUS: return {
      ...state,
      isOpen: false,
      focusedInput: action.focusedInput,
    };
    case ActionType.SET_MODAL_OPEN: return {
      ...state,
      isOpen: true,
    };
    case ActionType.SET_RANGES: return {
      ...state,
      startDateState: action.startDateState,
      endDateState: action.endDateState,
    };
    default: return state;
  }
};

interface Ranges {
  startDate: Moment.Moment | null;
  endDate: Moment.Moment | null;
}

// eslint-disable-next-line
export const useLogic = (setCheckInCheckError: (hasError: boolean) => void, setDateRanges: (ranges: CheckInCheckOutModel) => void): {
  endDateState: Moment.Moment | null,
  focusedInput: FocusedInputShape,
  isOpen: boolean,
  startDateState: Moment.Moment | null,
  blockPastMonthsNavigation: () => Moment.Moment | null,
  isDayBlocked: (day: Moment.Moment) => boolean,
  // eslint-disable-next-line
  onClick: (event: any) => void,
  onClose: () => void,
  onDatesChange: (ranges: Ranges) => void,
  onFocusChange: (inputOnFocus: FocusedInputShape) => void,
  onResetButtonClicked: () => void,
  setVisibleMonth: () => Moment.Moment,
} => {
  const dateRangesRoot: CheckInCheckOutModel = useSelector(
    (store: RootState) => store.checkInCheckOut,
  );

  const hydrateInitialState = (): State => ({
    isOpen: false,
    startDateState: dateRangesRoot.checkIn,
    endDateState: dateRangesRoot.checkOut,
    focusedInput: FOCUS_START_DATE,
  });

  // eslint-disable-next-line
  const [state, dispatch] = useReducer<Reducer<State, Action>, State>(checkInCheckOutReducer, initialState, hydrateInitialState);
  const {
    isOpen,
    startDateState,
    endDateState,
    focusedInput,
  } = state;
  let timer: number;

  useEffect(() => {
    dispatch({
      type: ActionType.SET_RANGES,
      startDateState: dateRangesRoot.checkIn,
      endDateState: dateRangesRoot.checkOut,
    });
  }, [dateRangesRoot]);
  
  // eslint-disable-next-line
  useEffect(() => {
    return () => window.clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  const onDatesChange = (ranges: Ranges): void => {
    const { startDate, endDate } = ranges;

    if (startDate) {
      dispatch({
        type: ActionType.SET_RANGES,
        startDateState: startDate,
        endDateState: null,
      });
    }
    if (endDate) {
      dispatch({ type: ActionType.SET_END_RANGE, endDateState: endDate });

      timer = window.setTimeout(() => {
        dispatch({ type: ActionType.SET_MODAL_CLOSE_AND_FOCUS, focusedInput: FOCUS_START_DATE });
      }, configs.calendarCloseTimeMs);
    }

    setDateRanges({ checkIn: startDate, checkOut: endDate });
  };

  // eslint-disable-next-line
  const onClick = (event: any): void => {
    if (event) {
      dispatch({ type: ActionType.SET_MODAL_OPEN });
      setCheckInCheckError(false);
    }
  };

  const onClose = (): void => {
    if (isOpen) {
      dispatch({
        type: ActionType.SET_MODAL_CLOSE_AND_FOCUS,
        focusedInput: !startDateState ? FOCUS_START_DATE : 'endDate',
      });
    }
  };

  const onResetButtonClicked = (): void => {
    setDateRanges({ checkIn: null, checkOut: null });
    dispatch({ type: ActionType.SET_INPUT_FOCUS, focusedInput: FOCUS_START_DATE });
  };

  const today: Moment.Moment = Moment();
  const dayBeforeToday: Moment.Moment = Moment().subtract(1, 'days');

  const onFocusChange = (inputOnFocus: FocusedInputShape): void => (
    dispatch({
      type: ActionType.SET_INPUT_FOCUS,
      focusedInput: !inputOnFocus ? focusedInput : inputOnFocus,
    })
  );

  const isDayBlocked = (day: Moment.Moment): boolean => (
    day.isBefore(dayBeforeToday)
  );

  const blockPastMonthsNavigation = (): Moment.Moment | null => {
    if (configs.calendarBlockPastMonthsNavigation) {
      return today.subtract(0, 'month');
    }

    return null;
  };

  const setVisibleMonth = (): Moment.Moment => {
    if (!startDateState?.isValid()) return today;

    if (startDateState !== null && endDateState !== null) {
      return startDateState;
    }

    if (startDateState !== null) {
      return startDateState;
    }

    if (endDateState !== null) {
      return endDateState;
    }

    return today;
  };

  return {
    endDateState,
    focusedInput,
    isOpen,
    startDateState,
    blockPastMonthsNavigation,
    isDayBlocked,
    onClick,
    onClose,
    onDatesChange,
    onFocusChange,
    onResetButtonClicked,
    setVisibleMonth,
  };
};
