import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { TFunction } from 'react-i18next';

export const useLogic = (
  // eslint-disable-next-line
  autosuggestProps: any,
  classes: ClassNameMap,
  hasSuggestions: boolean,
  locationOnFocus: boolean,
  translation: TFunction,
  // eslint-disable-next-line
): { hasAnError: () => boolean; renderErrorText: () => string; locationIconColor: () => string; } => {
  const hasAnError = (): boolean => {
    if (
      (autosuggestProps.value && !locationOnFocus && !hasSuggestions)
       || (!autosuggestProps.value && !locationOnFocus)
    ) {
      return true;
    }
    return false;
  };

  const renderErrorText = (): string => {
    if (autosuggestProps.value && !locationOnFocus && !hasSuggestions) {
      return translation('locationNotRecognizedError');
    }
    if (!autosuggestProps.value && !locationOnFocus) {
      return translation('locationNoInputError');
    }
    return '';
  };

  const locationIconColor = (): string => {
    if ((!autosuggestProps.value && locationOnFocus) || !hasSuggestions) {
      return classes.iconDisabled;
    }
    if (!autosuggestProps.value && !locationOnFocus) {
      return classes.icon;
    }
    return '';
  };

  return {
    hasAnError,
    renderErrorText,
    locationIconColor,
  };
};
