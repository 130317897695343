import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    loginContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      height: '100vh',
    },
    form: {
      maxWidth: 500,
      backgroundColor: theme.palette.background.default,
      padding: 30,
      margin: '0 20px',
      borderRadius: 8,
    },
    textField: {
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#000',
      },
    },
    textFieldTerms: {
      '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.MuiInputBase-inputMultiline': {
        color: '#000',
        fontSize: 14,
      },
    },
    helperText: {
      marginTop: 0,
    },
    submitBtnContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
      '& Button': {
        fontWeight: 'bold',
      },
    },
  },
));
