import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import nextId from 'react-id-generator';

import { setCheckInCheckOutAction } from '../../../../store/actions/checkInCheckOutAction';
import { setSuggestedLocationAction } from '../../../../store/actions/locationAction';
import { setRoomsAction } from '../../../../store/actions/roomsAction';

import {
  CheckInCheckOut as CheckInCheckOutModel, Location, Room as RoomModel,
} from '../../../../models';

import { RootState } from '../../../../store/reducers';

import { hasValidLocation, hasInvalidCheckInCheckOut, hasValidRoomSelection } from '../../../../utils/hotelSearchValidations';
import { HOTELS_ROUTE_PATH } from '../../../../routes/routesPath';

import { useCommonSearchLogic } from '../useCommonSearchLogic';

export const useCommonLogic = (): {
  hasCheckInCheckOutError: boolean,
  locationOnFocus: boolean,
  rooms: Array<RoomModel>,
  suggestion: Location,
  onSetDateRanges: (ranges: CheckInCheckOutModel) => void,
  onSetLocation: (value: Location) => void,
  onSetRooms: (roomsSelected: Array<RoomModel>) => void,
  onSearchButtonClicked: () => void,
  setCheckInCheckOutHasAnError: (hasError: boolean) => void,
  setOnFocusLocation: () => void,
} => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    dateRanges, hasCheckInCheckOutError, rooms,
    onSetDateRanges, onSetRooms, setCheckInCheckOutHasAnError,
  } = useCommonSearchLogic();

  const locationRoot: Location = useSelector((state: RootState) => state.location);

  const [locationOnFocus, setLocationOnFocus] = useState<boolean>(true);
  const [suggestion, setSuggestion] = useState<Location>(locationRoot);

  useEffect(() => {
    setSuggestion(locationRoot);
  }, [locationRoot]);

  const validate = (): boolean => {
    const isValidLocation = hasValidLocation(suggestion);

    const isInvalidCheckInCheckOut = hasInvalidCheckInCheckOut(dateRanges);
    setCheckInCheckOutHasAnError(isInvalidCheckInCheckOut);

    const isValidRoomsSelection = hasValidRoomSelection(rooms);

    return isValidLocation && !isInvalidCheckInCheckOut && isValidRoomsSelection;
  };

  const onSearchButtonClicked = (): void => {
    window.scroll(0, 0);
    setLocationOnFocus(false);

    const isValid = validate();

    if (isValid) {
      dispatch(setSuggestedLocationAction(suggestion));
      dispatch(setCheckInCheckOutAction(dateRanges));
      dispatch(setRoomsAction(rooms));
      // new search id to mark that new search is requested
      sessionStorage.setItem('cid', nextId());

      history.push(HOTELS_ROUTE_PATH);
    }
  };

  const onSetLocation = (value: Location): void => {
    setSuggestion(value);
  };

  const setOnFocusLocation = (): void => {
    setLocationOnFocus(true);
  };

  return {
    hasCheckInCheckOutError,
    locationOnFocus,
    rooms,
    suggestion,
    onSetDateRanges,
    onSetLocation,
    onSetRooms,
    onSearchButtonClicked,
    setCheckInCheckOutHasAnError,
    setOnFocusLocation,
  };
};
