import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import {
  Availability,
  AvailabilityLight,
  CheckInCheckOut,
  FiltersStoreData,
  Location,
  PropertyStoreData,
  RadiusSelection,
  Room,
  SortByStoreData,
  User,
} from '../models';
import ApiList, { getEmptyApiList } from '../models/ApiList';

import rootReducer from './reducers';
import {
  checkInCheckoutPersistor,
  filterPersistor,
  headerPersistor,
  locationPersistor,
  neighborhoodPersistor,
  propertyPersistor,
  radiusPersistor,
  roomsPersistor,
  sortByPersistor,
} from './persistors';

const initialState: {
  avNeighborhoods: Array<number>,
  checkInCheckOut: CheckInCheckOut,
  fetching: { hotels: boolean; hotelsCoordinates: boolean; },
  filters: FiltersStoreData,
  hotels: { items: ApiList<Availability> },
  hotelsCoordinates: { items: ApiList<AvailabilityLight> },
  hotelsHeader: { title: string; },
  hotelsPage: number,
  location: Location,
  login: User | null,
  property: PropertyStoreData,
  radius: RadiusSelection,
  rooms: Array<Room>,
  searchButton: { clicked: boolean },
  sortBy: SortByStoreData,
} = {
  avNeighborhoods: neighborhoodPersistor(),
  checkInCheckOut: checkInCheckoutPersistor(),
  fetching: { hotels: false, hotelsCoordinates: false },
  filters: filterPersistor(),
  hotels: { items: getEmptyApiList() },
  hotelsCoordinates: { items: getEmptyApiList() },
  hotelsHeader: headerPersistor(),
  hotelsPage: sessionStorage.getItem('hotelsPage') ? JSON.parse(sessionStorage.getItem('hotelsPage') || '0') : 0,
  location: locationPersistor(),
  login: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') || '{}') : null,
  property: propertyPersistor(),
  radius: radiusPersistor(),
  rooms: roomsPersistor(),
  searchButton: { clicked: false },
  sortBy: sortByPersistor(),
};

const enhancer = compose(
  applyMiddleware(thunk),
  // eslint-disable-next-line
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : compose
);

const store = createStore(rootReducer, initialState, enhancer);

export default store;
