import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    textfield: {
      '& .MuiInputBase-inputMarginDense': {
        paddingTop: '18.5px',
        paddingBottom: '18.5px',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#000',
      },
    },
    nameAddressContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    addressContent: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20,
    },
  },
));
