import { SET_SELECTED_LOCATION } from './actionTypes';

import { Location } from '../../models';

// eslint-disable-next-line max-len
const setSuggestedLocationAction = (suggestion: Location): { type: string, payload: Location } => {
  sessionStorage.setItem('location', JSON.stringify(suggestion));

  return {
    type: SET_SELECTED_LOCATION,
    payload: suggestion,
  };
};

export {
  setSuggestedLocationAction,
};
