import genericApi from '../genericApi';

import { ApiList, Country, Neighborhood } from '../../models';

import { PORTAL_BE_ROUTE_PREFIX } from '../../configs/environments';

const locationRemote = {

  getCountries: (page: number, size: number, sortBy: string): Promise<ApiList<Country>> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/locations/countries?page=${page}&size=${size}&sort=${sortBy}`),

  getNeighborhoodsById: (idsAsString: string): Promise<Array<Neighborhood>> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/locations/cities/neighborhoods/${idsAsString}`),
};

export default locationRemote;
