import React, { FC } from 'react';

import { Box, LinearProgress } from '../../../common';

import useStyles from './hotelLoadingContentStyles';

const HotelLoadingImage: FC<unknown> = () => {
  const classes = useStyles();

  const commonClasses = (): { root: string, colorPrimary: string, barColorPrimary: string } => (
    {
      root: classes.linearRoot,
      colorPrimary: classes.linearColorPrimary,
      barColorPrimary: classes.linearBarColorPrimary,
    }
  );

  return (
    <Box className={classes.loadingContent}>
      <Box className={classes.loadingHeadingText}>
        <LinearProgress classes={commonClasses()} />
      </Box>
      <Box className={classes.loadingDescription}>
        <Box className={classes.loadingDescriptionText1} mb={'10px'}>
          <LinearProgress classes={commonClasses()} />
        </Box>
        <Box className={classes.loadingDescriptionText2} mb={'10px'}>
          <LinearProgress classes={commonClasses()} />
        </Box>
        <Box className={classes.loadingDescriptionText3} mb={'10px'}>
          <LinearProgress classes={commonClasses()} />
        </Box>
        <Box className={classes.loadingDescriptionText4} mb={'10px'}>
          <LinearProgress classes={commonClasses()} />
        </Box>
      </Box>
    </Box>
  );
};

export default HotelLoadingImage;
