import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    cardLoading: {
      width: '100%',
      minWidth: 450,
      borderRadius: 8,
      margin: '8px 0',
    },
    cardLoadingContent: {
      paddingTop: 9,
    },
    imagesLoadingContainer: {
      height: 550,
      marginBottom: 25,
    },
    descAndMapLoadingContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    nameAndDescLoadingContainer: {
      flex: 3,
      marginRight: 10,
    },
    mapLoadingContainer: {
      flex: 2,
      width: 200,
      height: 450,
      marginLeft: 10,
      borderRadius: 8,
    },
  },
));
