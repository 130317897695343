import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    descriptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 15,
    },
    moreLessContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    moreLessButton: {
      textTransform: 'none',
      fontWeight: 'bold',
      '&:hover': {
        background: 'none',
        color: theme.palette.primary.main,
      },
    },
  },
));
