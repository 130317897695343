import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    card: {
      width: '100%',
      minWidth: 450,
      minHeight: 106,
      margin: '8px 0',
    },
    headerCard: {
      margin: '8px 0',
    },
    cardContent: {
      paddingTop: 9,
      paddingBottom: 9,
      '&:last-child': {
        paddingBottom: 9,
      },
    },
    cardPaymentContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 8,
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 40,
    },
  },
));
