import { SET_SELECTED_CHECK_IN_CHECK_OUT } from './actionTypes';

import { CheckInCheckOut } from '../../models';

const setCheckInCheckOutAction = (
  dateRanges: CheckInCheckOut,
): { type: string, payload: CheckInCheckOut } => {
  sessionStorage.setItem('dataRange', JSON.stringify(dateRanges));

  return {
    type: SET_SELECTED_CHECK_IN_CHECK_OUT,
    payload: dateRanges,
  };
};

export {
  setCheckInCheckOutAction,
};
