import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../../store/reducers';
import { setSelectedRadiusAction } from '../../../../../store/actions/radiusAction';

import { RadiusSelection } from '../../../../../models';

import { useMeasurementUnit } from '../../../../../hooks/useMeasurementUnit';

import { DEFAULT_FALLBACK_UNIT } from '../../../../../configs/environments';

const NONE_VALUE = 'None';
const RADIUS_VALUES_KM = [NONE_VALUE, 1, 5, 10, 15, 20];
const RADIUS_VALUES_MI = [NONE_VALUE, 1, 3, 6, 9, 12];

export const useLogic = (): {
  radiusValues: Array<number | string>;
  value: number | string;
  handleRadiusChange: (event) => void;
  renderValue: (radiusValue: number | string) => string;
} => {
  const dispatch = useDispatch();
  const {
    apiUnitNameRoot, measurementUnit, UNIT_KM, UNIT_MI,
  } = useMeasurementUnit();

  const radiusRoot: RadiusSelection = useSelector((state: RootState) => state.radius);

  const [radiusValues, setRadiusValues] = useState<Array<number | string>>(RADIUS_VALUES_MI);
  const [unitState] = useState<string>(measurementUnit);

  useEffect(() => {
    if (apiUnitNameRoot !== DEFAULT_FALLBACK_UNIT) {
      setRadiusValues(RADIUS_VALUES_KM);
    }
  // eslint-disable-next-line
  }, []);

  const handleRadiusChange = (event) => {
    const { value: selectedValue, index } = event.currentTarget.dataset;

    const radiusSelection: RadiusSelection = {
      selected: {
        value: selectedValue !== NONE_VALUE ? Number(selectedValue) : selectedValue,
        unit: unitState,
      },
      opposite: {
        value: unitState === UNIT_MI ? RADIUS_VALUES_KM[index] : RADIUS_VALUES_MI[index],
        unit: unitState === UNIT_MI ? UNIT_KM : UNIT_MI,
      },
    };
  
    dispatch(setSelectedRadiusAction(radiusSelection));
  };

  const renderValue = (radiusValue: number | string): string => {
    if (typeof radiusValue === 'string') return radiusValue;
    return `${radiusValue} ${unitState}`;
  };

  return {
    radiusValues,
    value: radiusRoot.selected.value,
    handleRadiusChange,
    renderValue,
  };
};
