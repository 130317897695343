import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
        flexDirection: 'row',
      },
    },
    radioGroup: {
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
        flexDirection: 'row',
      },
    },
    radio: {
      padding: '4px 4px 4px 9px',
    },
    formControlLabel: {
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '18%',
      },
    },
    moreLessButton: {
      textTransform: 'none',
      '&:hover': {
        background: 'none',
        color: theme.palette.primary.main,
      },
    },
    titleContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
  },
));
