import { SET_SELECTED_RADIUS } from './actionTypes';

import { RadiusSelection } from '../../models';

// eslint-disable-next-line max-len
const setSelectedRadiusAction = (selected: RadiusSelection): { type: string, payload: RadiusSelection } => {
  sessionStorage.setItem('radius', JSON.stringify(selected));

  return {
    type: SET_SELECTED_RADIUS,
    payload: selected,
  };
};

export {
  setSelectedRadiusAction,
};
