import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    card: {
      width: '100%',
      minWidth: 450,
      margin: '8px 0',
      '& .MuiCardContent-root:last-child': {
        paddingBottom: 9,
      },
    },
    cardContent: {
      paddingTop: 9,
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 10,
    },
  },
));
