import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    card: {
      width: '100%',
      minWidth: 450,
      margin: '8px 0',
    },
    cardContent: {
      paddingTop: 9,
    },
    paymentContent: {
      display: 'flex',
      flexDirection: 'row',
    },
    paymentCardItemContent: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    paymentCardLeftItem: {
      width: '27%',
      fontWeight: 'bold',
    },
    paymentCardRightItem: {
      width: '73%',
    },
    transactionContent: {
      display: 'flex',
      width: '48%',
      marginTop: 2,
    },
  },
));
