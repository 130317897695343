import { User } from '../../models';
import { LOGIN_SUCCESS, LOGIN_FAIL } from '../actions/actionTypes';

interface Action { type: string; payload: User; }

const initState: User | null = null;

export default (
  state: User | null = initState, action: Action,
): User | null => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...action.payload };
    case LOGIN_FAIL:
      return initState;
    default:
      return state;
  }
};
