import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '../../common';

import useStyles from './welcomeMessageStyles';

const WelcomeMessage: FunctionComponent<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container direction="column" className={classes.container}>
        <Grid container direction="column" className={classes.titleContainer}>
          <Grid item>
            <Typography component="div" variant="h1" className={classes.titleText}>
              {t('welcomeTitle1')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="div" variant="h1" className={classes.titleText}>
              {t('welcomeTitle2')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="div" variant="h1" className={classes.titleText}>
              {t('welcomeTitle3')}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h6">{t('welcomeSubtitle1')}</Typography>
        <Typography variant="h6">{t('welcomeSubtitle2')}</Typography>
      </Grid>
    </div>
  );
};

export default WelcomeMessage;
