import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    hotelsLoadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    hotelLoadingCardContainer: {
      marginBottom: 8,
    },
  },
));
