import React, { FC } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CardMedia } from '..';

import defaultNoImage from '../../../assets/RR_default_Image_v1.png';
import defaultNoImageBig from '../../../assets/RR_default_no_image_v2.png';

const useStyles = makeStyles(() => createStyles({
  cardMedia: {
    objectFit: 'fill',
  },
}));

interface DefaultNoImageProps {
  bigImg?: boolean;
  height?: string | number;
  width?: string | number;
}

const DefaultNoImage: FC<DefaultNoImageProps> = (props: DefaultNoImageProps) => {
  const { bigImg, height, width } = props;
  const classes = useStyles();

  return (
    <CardMedia
      width={width}
      height={height}
      className={classes.cardMedia}
      component="img"
      alt="Room Rocket"
      image={bigImg ? defaultNoImageBig : defaultNoImage}
    />
  );
};

export default DefaultNoImage;
