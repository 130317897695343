import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Person as PersonIcon, Group as GroupIcon } from '@material-ui/icons';
import { Box, InputAdornment, TextField } from '../../../common';

import useStyles from './roomsInputStyles';

interface RoomsInputInputProps {
  value: string;
  isOpen: boolean;
  hasChildren: boolean;
  onClick: (event) => void;
}

const RoomsInput: FC<RoomsInputInputProps> = (props: RoomsInputInputProps) => {
  const {
    value,
    isOpen,
    hasChildren,
    onClick,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const setTextFieldClasses = (): string => {
    if (isOpen) {
      return classes.textFieldClicked;
    }

    return classes.textFieldWithValue;
  };

  return (
    <Box>
      <Box className={classes.container} onClick={onClick}>
        <Box className={classes.roomsContainer}>
          <TextField
            fullWidth
            variant="filled"
            label={t('roomInputLabel')}
            margin="normal"
            value={value}
            classes={{
              root: setTextFieldClasses(),
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box className={classes.adornmentContainer}>
                    <PersonIcon />
                    { hasChildren && <GroupIcon fontSize="small" className={classes.groupIcon} /> }
                  </Box>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RoomsInput;
