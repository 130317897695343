import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SortByStoreData } from '../../../../../models';

import { setDetailsPageSortBy, setHotelsPageSortBy } from '../../../../../store/actions/sortByAction';
import { RootState } from '../../../../../store/reducers';

import { SORT_BY_PRICE_ASC, SORT_BY_PRICE_DESC } from '../../../../../configs/environments';

const sortOptions = [
  {
    value: SORT_BY_PRICE_ASC,
    label: 'availabilitySortByPriceAsc',
  },
  {
    value: SORT_BY_PRICE_DESC,
    label: 'availabilitySortByPriceDesc',
  },
];

export const useLogic = (detailPage?: boolean, listPage?: boolean): {
  selectedOption: string,
  sortOptions: any,
  onSortByChange: (event: any) => void;
} => {
  const dispatch = useDispatch();

  const sortByRoot: SortByStoreData = useSelector((store: RootState) => store.sortBy);

  const [
    selectedOption, setSelectedOption,
  ] = useState<string>(detailPage ? sortByRoot.details : sortByRoot.list);

  useEffect(() => {
    let select: string;
    if (listPage) {
      select = sortByRoot.list;
    } else {
      select = sortByRoot.details;
    }

    setSelectedOption(select);
  // eslint-disable-next-line
  }, [sortByRoot]);

  const onSortByChange = (event: any): void => {
    const sort: string = event.target.value || SORT_BY_PRICE_ASC;

    if (listPage) {
      dispatch(setHotelsPageSortBy(sort));
    } else {
      dispatch(setDetailsPageSortBy(sort));
    }
  };

  return {
    selectedOption,
    sortOptions,
    onSortByChange,
  };
};
