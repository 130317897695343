import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '../../common';

import RoomsInput from './roomsInput/RoomsInput';
import RoomsWidgetContent from './roomsWidgetContent/RoomsWidgetContent';

import { Room as RoomModel } from '../../../models';

interface RoomsWidgetProps {
  rooms: Array<RoomModel>;
  onSetRooms: (roomsSelected: Array<RoomModel>) => void;
}

const RoomsWidget: FC<RoomsWidgetProps> = (props: RoomsWidgetProps) => {
  const { rooms, onSetRooms } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [roomsState, setRooms] = useState<Array<RoomModel>>([]);
  const [roomsInputValue, setRoomsInputValue] = useState<string>('');

  const roomsInputText = useCallback((currentRooms: Array<RoomModel>): void => {
    const travelersCount: number = currentRooms
      .map((room: RoomModel) => room.adults + room.children)
      .reduce((sumOne, sumTwo) => sumOne + sumTwo, 0);

    const roomText = currentRooms.length === 1 ? t('roomInputTextRoom') : t('roomInputTextRooms');
    const travelerText = travelersCount === 1 ? t('roomInputTextTraveler') : t('roomInputTextTravelers');
    const inputText = `${currentRooms.length} ${roomText}, ${travelersCount} ${travelerText}`;

    setRoomsInputValue(inputText);
  }, [t]);

  useEffect(() => {
    setRooms(rooms);
  }, [rooms]);

  useEffect(() => {
    roomsInputText(roomsState);
  }, [roomsState, roomsInputText]);

  const onClick = (event): void => {
    if (event) {
      setIsOpen(true);
    }
  };

  const onClose = (): void => {
    setIsOpen(false);
    onSetRooms(roomsState);
  };

  const updateRooms = (roomsChanges: Array<RoomModel>): void => (
    setRooms(roomsChanges)
  );

  const hasChildren = (): boolean => (
    roomsState
      .map((room: RoomModel) => room.children)
      .reduce((childrenOne, childrenTwo) => childrenOne + childrenTwo, 0) > 0
  );

  return (
    <Box>
      <RoomsInput
        value={roomsInputValue}
        isOpen={isOpen}
        hasChildren={hasChildren()}
        onClick={onClick}
      />
      {isOpen ? (
        <RoomsWidgetContent
          rooms={roomsState}
          updateRooms={updateRooms}
          onSubmit={onClose}
        />
      ) : null}
    </Box>
  );
};

export default RoomsWidget;
