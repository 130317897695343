import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  card: {
    width: '100%',
    minWidth: 450,
    margin: '8px 0',
    background: theme.palette.background.default,
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 8,
    },
  },
  cardContent: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '8px 5px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      flexDirection: 'row',
      padding: '8px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      flexDirection: 'row',
      padding: '8px',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      flexDirection: 'row',
      padding: '8px',
    },
  },
  content: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'unset',
    },
  },
  nameAndPriceContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  roomNameContainer: {
    width: '87%',
  },
  priceContainer: {
    width: '12%',
    textAlign: 'center',
  },
  galleryContainer: {
    '& .image-gallery-slides': {
      borderRadius: 8,
      height: 168,
    },
    '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
      height: 168,
      width: 290,
    },
    '& .image-gallery-slide .image-gallery-image': {
      objectFit: 'fill',
    },
    '& .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg': {
      height: 60,
      width: 30,
    },
    '& .image-gallery-left-nav, .image-gallery-right-nav': {
      padding: '50px 0',
    },
  },
  amenitiesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '82%',
    marginLeft: 10,
    marginBottom: 10,
    [theme.breakpoints.only('md')]: {
      width: '80%',
    },
    [theme.breakpoints.only('lg')]: {
      width: '75%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
  },
  moreLessContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  moreLessButton: {
    fontWeight: 400,
    textTransform: 'none',
    padding: 0,
    '&:hover': {
      background: 'none',
      color: theme.palette.primary.main,
    },
  },
  nameContainer: {
    minHeight: 30,
    fontWeight: 'bold',
  },
  pricesContainer: {
    width: '18%',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '38%',
    },
    [theme.breakpoints.only('md')]: {
      width: '20%',
    },
    [theme.breakpoints.only('lg')]: {
      width: '18%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '20%',
    },
  },
  guestsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  sourceContainer: {
    textAlign: 'center',
    marginTop: 25,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 70,
    '& .MuiButton-label': {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  roomAmenitiesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  roomAmenityContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '31%',
    marginBottom: '15px',
  },
  roomAmenityTextContainer: {
    marginTop: -2,
    marginLeft: 5,
    display: 'flex',
    alignItems: 'center',
  },
  collapseCardContent: {
    padding: 0,
  },
}));
