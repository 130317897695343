interface Headers {
  [index: string]: string;
}

const headers: Headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export default headers;
