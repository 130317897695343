import { SET_SORT_BY_DETAILS_PAGE, SET_SORT_BY_LIST_PAGE } from './actionTypes';

const setHotelsPageSortBy = (data: string): { type: string, payload: string } => (
  {
    type: SET_SORT_BY_LIST_PAGE,
    payload: data,
  }
);

const setDetailsPageSortBy = (data: string): { type: string, payload: string } => (
  {
    type: SET_SORT_BY_DETAILS_PAGE,
    payload: data,
  }
);

export {
  setDetailsPageSortBy,
  setHotelsPageSortBy,
};
