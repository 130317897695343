import React, { FC } from 'react';

import { Box } from '../../common';

import MapContainer from '../mapContainer/MapContainer';

import { MapCoordinates } from '../../../models';

const PROPERTY_MAP_CONTAINER_HEIGHT = 460;

function createMapOptions() {
  return {
    draggable: false,
    clickableIcons: false,
    draggableCursor: 'auto',
    gestureHandling: 'none',
    zoomControl: false,
  };
}

interface PropertyMapContainerProps {
  defaultCoordinates: MapCoordinates;
}

const PropertyMapContainer: FC<PropertyMapContainerProps> = (props: PropertyMapContainerProps) => {
  const { defaultCoordinates } = props;

  return (
    <Box>
      <MapContainer
        defaultCoordinates={defaultCoordinates}
        height={PROPERTY_MAP_CONTAINER_HEIGHT}
        mapOptions={createMapOptions()}
      />
    </Box>
  );
};

const MemoizedPropertyMapContainer = React.memo(PropertyMapContainer);
export default MemoizedPropertyMapContainer;
