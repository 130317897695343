import { SEARCH_BUTTON_CLICKED, SEARCH_BUTTON_NOT_CLICKED } from '../actions/actionTypes';

const initialState: { clicked: boolean } = { clicked: false };

export default (state: { clicked: boolean; } =
initialState, action: { type: string; }): { clicked: boolean } => {
  switch (action.type) {
    case SEARCH_BUTTON_CLICKED:
      return {
        ...state,
        clicked: true,
      };
    case SEARCH_BUTTON_NOT_CLICKED:
      return {
        ...state,
        clicked: false,
      };
    default: return state;
  }
};
