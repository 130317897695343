import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  ApiList,
  AvailabilityLight,
  AvailabilitySearchCriteriaIdsParams,
  AvailabilitySearchParams,
  Location,
} from '../../../../../models';

import { receiveHotelsCoordinates } from '../../../../../store/actions/hotelsAction';
import { RootState } from '../../../../../store/reducers';

export const useLogic = (handleCloseMap: () => void): {
  locationRoot: Location,
  isFetching: boolean,
  hotels: Array<AvailabilityLight>,
} => {
  const dispatch = useDispatch();
  const isFetchingRoot: boolean = useSelector(
    (store: RootState) => store.fetching.hotelsCoordinates,
  );
  const hotelsCoordinatesRoot: ApiList<AvailabilityLight> = useSelector(
    (store: RootState) => store.hotelsCoordinates.items,
  );
  const hotelsPageRoot: number = useSelector((store: RootState) => store.hotelsPage);
  const locationRoot: Location = useSelector((store: RootState) => store.location);
  const searchParamsRoot: AvailabilitySearchParams = useSelector(
    (store: RootState) => store.availabilityParams,
  );

  useEffect(() => {
    if (!searchParamsRoot.date_range.check_in || !searchParamsRoot.date_range.check_out
      /**
       * In `hotelsAction` we set `coordinate_range` to null, to prevent fetch availability light in
       * hotelsMapContainer on searchParams changed. This is done because we need to not trigger
       * update here and don't pass `coordinate_range` as next search params in the Redux.
       */
      // eslint-disable-next-line max-len
      || (searchParamsRoot.search_criteria as AvailabilitySearchCriteriaIdsParams).property_filter?.coordinate_range === null) return;

    dispatch(receiveHotelsCoordinates(hotelsPageRoot, searchParamsRoot));
  }, [hotelsPageRoot, searchParamsRoot, dispatch]);

  useEffect(() => {
    const hasCoordinates: boolean = hotelsCoordinatesRoot.content.length > 0
    && hotelsCoordinatesRoot.content[0].coordinates?.latitude !== 0
    && hotelsCoordinatesRoot.content[0].coordinates?.longitude !== 0;

    if (!hasCoordinates) {
      handleCloseMap();
    }
    // eslint-disable-next-line
  }, [hotelsCoordinatesRoot]);

  return {
    locationRoot,
    isFetching: isFetchingRoot,
    hotels: hotelsCoordinatesRoot.content,
  };
};
