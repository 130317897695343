import genericApi from '../genericApi';

import {
  ApiList,
  BookingCancellationResponse,
  BookingReservation,
  BookingResponse,
  BookingRequestParams,
} from '../../models';
import ReservationApiStatus from '../../models/ReservationApiStatus.enum';

import { PAGINATION_PAGE_SIZE, PORTAL_BE_ROUTE_PREFIX } from '../../configs/environments';

const bookingRemote = {

  getReservation: (reservationId: number): Promise<ApiList<BookingReservation>> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/reservation/${reservationId}`),
  makeReservation: (bookingData: BookingRequestParams): Promise<BookingResponse> => genericApi.post(`${PORTAL_BE_ROUTE_PREFIX}/api/reservation`, bookingData),
  cancelReservation: (lot: number, unique_id: string): Promise<BookingCancellationResponse> => genericApi.post(`${PORTAL_BE_ROUTE_PREFIX}/api/cancel`, { lot, unique_id }),
  getReservationsByStatus: (
    page: number, status: ReservationApiStatus,
  ): Promise<ApiList<BookingReservation>> => genericApi.get(`user/reservation?status=${status}&size=${PAGINATION_PAGE_SIZE}&page=${page}`),
};

export default bookingRemote;
