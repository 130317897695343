import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  card: {
    width: 270,
    marginRight: 8,
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      width: '76%',
      height: '100%',
      minHeight: 193,
    },
  },
  cardContent: {
    paddingLeft: 10,
    paddingRight: 3,
  },
  filtersTitle: {
    width: '100%',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  buttonsContainer: {
    minHeight: 40,
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));
