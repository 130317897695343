import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Add as AddIcon } from '@material-ui/icons';

import {
  Box,
  Button,
  Divider,
  Tooltip,
} from '../../../common';

import RoomSection from '../roomSection/RoomSection';

import { Room as RoomModel } from '../../../../models';

import configs from '../../../../configs/environments';
import useStyles from './roomWidgetContentStyles';

interface RoomsWidgetContentProps {
  rooms: Array<RoomModel>;
  updateRooms: (rooms: Array<RoomModel>) => void;
  onSubmit: (roomsSelection: Array<RoomModel>) => void;
}

const ADULTS_DEFAULT = configs.room.adults.default;
const CHILDREN_DEFAULT = configs.room.children.default;
const ROOM_MAX_ALLOWED = configs.room.room.max;

const RoomsWidgetContent: FC<RoomsWidgetContentProps> = (props: RoomsWidgetContentProps) => {
  const { rooms, updateRooms, onSubmit } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleOnChange = (roomUpdate: RoomModel, roomIndex: number): void => {
    const updatedRooms: Array<RoomModel> = Object.assign(
      [], rooms, { [roomIndex]: roomUpdate },
    );

    updateRooms(updatedRooms);
  };

  const handleOnRemove = (index: number): void => {
    const updatedRooms: Array<RoomModel> = [...rooms];
    updatedRooms.splice(index, 1);

    updateRooms(updatedRooms);
  };

  const handleOnAdd = (): void => {
    const updatedRooms: Array<RoomModel> = [...rooms];
    const newRoom: RoomModel = {
      agesOfChild: [],
      adults: ADULTS_DEFAULT,
      children: CHILDREN_DEFAULT,
    };
    updatedRooms.push(newRoom);

    updateRooms(updatedRooms);
  };

  const submitRoomsSelection = (): void => (
    onSubmit(rooms)
  );

  return (
    <Box className={classes.content}>
      <Box className={classes.contentWrapper}>
        {
          rooms.map((room: RoomModel, index: number) => (
            // eslint-disable-next-line
            <Fragment key={index}>
              <RoomSection
                room={room}
                roomIndex={index + 1}
                onChange={(roomUpdate) => handleOnChange(roomUpdate, index)}
                onRemove={() => handleOnRemove(index)}
              />
              <Divider variant="middle" />
            </Fragment>
          ))
        }
        { rooms.length < ROOM_MAX_ALLOWED
          ? (
            <Box className={classes.addRoomContainer}>
              <Tooltip title={t('roomAddButtonTooltip')} aria-label="add">
                <Button aria-label="add" onClick={handleOnAdd} startIcon={<AddIcon />}>
                  {t('roomAddButton')}
                </Button>
              </Tooltip>
            </Box>
          )
          : null }
        <Box className={classes.doneRoomContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.doneButton}
            onClick={submitRoomsSelection}
          >
            {t('roomDoneButton')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RoomsWidgetContent;
