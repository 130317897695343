import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '../../../common';

import HeaderCommon from '../../../common/layout/header/HeaderCommon';

const Header: FC<unknown> = () => {
  const { t } = useTranslation();

  return (
    <HeaderCommon>
      <Box fontSize="h4.fontSize">{t('bookingsMyReservationHeaderText')}</Box>
    </HeaderCommon>
  );
};

const MemoizedHeader = React.memo(Header);
export default MemoizedHeader;
