export default function neighborhoodPersistor(): Array<number> {
  let neighborhoods: Array<number> = [];
  const sessionNeighborhoods: string | null = sessionStorage.getItem('availabilityNeighborhood');

  if (sessionNeighborhoods) {
    neighborhoods = JSON.parse(sessionNeighborhoods);
  }

  return neighborhoods;
}
