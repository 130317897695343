import React, { FC, ReactNode } from 'react';
import nextId from 'react-id-generator';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent } from '../../common';

import HotelLoadingContent from '../../hotel/hotelsLoading/hotelLoadingContent/HotelLoadingContent';

const useStyles = makeStyles(() => createStyles({
  cardLoading: {
    width: '100%',
    margin: '8px 0',
  },
  cardLoadingContent: {
    paddingTop: 8,
  },
  loadingContainer: {
    marginTop: 35,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const LOADING_ITEMS = 3;
const LOADING_CONTENT: Array<ReactNode> = Array.from({ length: LOADING_ITEMS }, () => (
  <Box key={nextId('blc-')} mb={1}><HotelLoadingContent /></Box>
));

const BookingsLoading: FC<unknown> = () => {
  const classes = useStyles();

  return (
    <Card className={classes.cardLoading}>
      <CardContent className={classes.cardLoadingContent}>
        <Box className={classes.loadingContainer}>
          { LOADING_CONTENT }
        </Box>
      </CardContent>
    </Card>
  );
};

const MemoizedBookingsLoading = React.memo(BookingsLoading);
export default MemoizedBookingsLoading;
