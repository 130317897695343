import sortBy from 'lodash/sortBy';
import {
  fitBounds,
  Coords,
  Size,
  NESWBounds,
} from 'google-map-react';

import { AvailabilityLight } from '../../../models';

/*
  This file content is extracted from the official library's repo
  and modified to fit RoomRocket's need.

  https://github.com/google-map-react/google-map-react/issues/3
*/

// Here world is a tube
const findZoomAndCenter = (
  {
    size,
    defaultCenter,
    zoom: defaultZoom = 10,
    zoomBounds = { min: 3, max: 17 },
  }: {
    size: Size,
    defaultCenter: Coords,
    zoom?: number,
    zoomBounds?: { min: number, max: number },
  },
  points: Array<AvailabilityLight>,
): { calculatedCenter: Coords, calculatedZoom: number } => {
  if (size === undefined) return { calculatedCenter: defaultCenter, calculatedZoom: defaultZoom };
  if (points.length === 0) return { calculatedCenter: defaultCenter, calculatedZoom: defaultZoom };

  // to not write additional logic for one point
  const eps = 0.0001;

  const pointsNorm: Array<Coords> = points
    // convert all that latitude longitude
    .map(({ coordinates }) => ({
      lat: coordinates.latitude,
      lng: coordinates.longitude,
    }))
    // add bounding box corners to points
    .reduce((r: Array<Coords>, pt: Coords) => {
      r.push(pt);

      return r;
    }, []);

  const { lat: latFirst, lng: lngFirst } = pointsNorm[0];

  const { nw, se } = pointsNorm.reduce(
    ({ nw: ptNW, se: ptSE }, { lat, lng }) => ({
      nw: {
        lat: Math.max(ptNW.lat, lat + eps),
        lng: Math.min(ptNW.lng, lng - eps),
      },
      se: {
        lat: Math.min(ptSE.lat, lat - eps),
        lng: Math.max(ptSE.lng, lng + eps),
      },
    }), {
      nw: { lat: latFirst, lng: lngFirst },
      se: { lat: latFirst, lng: lngFirst },
    },
  );

  // bounds on the tube can be not as simple {from, to}
  // ____***_____
  // but having zero meridean we could get bounds like
  // **_________*
  // The only idea I have is to use n^(log2(n) + c) algorithm to find such bounds

  // The idea is to find max distance between nearest pts (as I know similar algos always n*log(n))
  // so sorting is good here
  const pointsSorted: Array<Coords> = sortBy(pointsNorm, 'lng');

  let dist = 0;
  let leftLng = 0;

  for (let i = 0; i < pointsSorted.length - 1; i += 1) {
    const d = pointsSorted[i + 1].lng - pointsSorted[i].lng;
    if (d > dist) {
      leftLng = pointsSorted[i].lng;
      dist = d;
    }
  }

  const minInterval1 = se.lng - nw.lng;
  const minInterval2 = 360 - dist;
  
  // only to satisfy typescript and NESWBounds type
  const ne: Coords = { lat: 0, lng: 0 };
  const sw: Coords = { lat: 0, lng: 0 };

  // decide what kind of bounds we have
  // this ____***_____
  // or this **_________*
  const bounds: NESWBounds = minInterval1 < minInterval2
    ? {
      ne,
      sw,
      nw,
      se,
    }
    : {
      ne,
      sw,
      nw: {
        lat: nw.lat,
        lng: leftLng + dist,
      },
      se: {
        lat: se.lat,
        lng: leftLng + 360,
      },
    };

  let primitiveCenter: Coords = defaultCenter;

  if (bounds.se && bounds.nw) {
    primitiveCenter = {
      lng: (bounds.se.lng + bounds.nw.lng) / 2,
      lat: (bounds.se.lat + bounds.nw.lat) / 2,
    };
  }

  const { center: center0, zoom: zoom0 } = fitBounds(bounds, size);

  const center: Coords = zoom0 > zoomBounds.max
    ? primitiveCenter
    : {
      ...center0,
      lng: center0.lng % 360,
    };

  const zoom = Math.min(Math.max(zoom0, zoomBounds.min), zoomBounds.max);

  return {
    calculatedCenter: center,
    calculatedZoom: zoom,
  };
};

export default findZoomAndCenter;
