import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Card,
  CardContent,
} from '../../common';

import AmenityFilter from './amenitiesFilter/AmenityFilter';
import ChainFilter from './chains/ChainFilter';
import NeighborhoodFilter from './neighborhoodFilter/NeighborhoodFilter';
import PointsOfInterestFilter from './pointsOfInterestFilter/PointsOfInterestFilter';
import RatingFilter from './ratingFilter/RatingFilter';

import { clearAllSelected } from '../../../store/actions/filtersAction';
import { RootState } from '../../../store/reducers';

import { useCommonLogic } from '../../search/hooks/useCommonLogic';

import {
  SHOW_AMENITY_FILTER,
  SHOW_CHAIN_FILTER,
  SHOW_NEIGHBORHOOD_FILTER,
  SHOW_POI_FILTER,
  SHOW_RATING_FILTER,
} from '../../../configs/environments';

import useStyles from './filterContainerStyles';

const FilterContainer: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onSearchButtonClicked } = useCommonLogic();

  const isHotelFetchingRoot: boolean = useSelector((state: RootState) => state.fetching.hotels);

  const onClearButtonClicked = (): void => {
    dispatch(clearAllSelected());
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.filtersTitle}>{t('filtersSearchTitle')}</Box>
        { SHOW_AMENITY_FILTER && <AmenityFilter /> }
        { SHOW_POI_FILTER && <Box mt={3}><PointsOfInterestFilter /></Box> }
        { SHOW_RATING_FILTER && <Box mt={3}><RatingFilter /></Box> }
        { SHOW_CHAIN_FILTER && <Box mt={3}><ChainFilter /></Box> }
        { SHOW_NEIGHBORHOOD_FILTER && <Box mt={3}><NeighborhoodFilter /></Box>}
        <Box className={classes.buttonsContainer}>
          { !isHotelFetchingRoot && (
            <>
              <Button color="default" variant="outlined" onClick={onSearchButtonClicked}>
                {t('applyButtonTitle')}
              </Button>
              <Button color="primary" variant="outlined" onClick={onClearButtonClicked}>
                {t('clearButtonTitle')}
              </Button>
            </>
          ) }
        </Box>
      </CardContent>
    </Card>
  );
};

const MemoizedFilterContainer = React.memo(FilterContainer);
export default MemoizedFilterContainer;
