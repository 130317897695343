import RadiusSelection, { defaultRadiusSelected } from '../../models/RadiusSelection';

export default function radiusPersistor(): RadiusSelection {
  let radius: RadiusSelection = defaultRadiusSelected;
  const sessionRadius: string | null = sessionStorage.getItem('radius');

  if (sessionRadius) {
    radius = JSON.parse(sessionRadius);
  }

  return radius;
}
