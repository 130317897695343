import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    card: {
      width: '100%',
      minWidth: 450,
      height: 480,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'initial',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        minWidth: 'initial',
      },
      backgroundColor: '#cccd',
    },
    cardContent: {
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    form: {
      width: 405,
      marginTop: 34,
      position: 'absolute',
      [theme.breakpoints.down('xs')]: {
        width: '80%',
      },
      [theme.breakpoints.only('xs')]: {
        width: '64%',
      },
    },
    locationContainer: {
      width: '100%',
      position: 'absolute',
      [theme.breakpoints.down('xs')]: {
        width: '130%',
      },
    },
    inFormContainer: {
      position: 'relative',
      top: 100,
      [theme.breakpoints.down('xs')]: {
        width: '130%',
      },
    },
    suggestionContainer: {
      marginBottom: 15,
      display: 'flex',
    },
    inputContainer: {
      marginBottom: 15,
    },
    titleContainer: {
      width: '85%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    buttonContainer: {
      position: 'relative',
      top: 90,
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
      [theme.breakpoints.down('xs')]: {
        width: '130%',
      },
      '& Button': {
        padding: '12px 25px',
      },
    },
  },
));
