import genericApi from '../genericApi';

import Location from '../../models/Location';

const destinationRemote = {

  getDestination: (candidate: string): Promise<Array<Location>> => genericApi.post('api/destination/suggest', { candidate }),
};

export default destinationRemote;
