import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { clearAllSelectedHotelRatings, selectHotelRating, unSelectHotelRating } from '../../../../../../store/actions/filtersAction';
import { RootState } from '../../../../../../store/reducers';

import { MAX_STAR_RATING_VALUE } from '../../../../../../configs/environments';

// this will dynamically work based on max ratings value in the environment config
const initialRatingsValues: Array<number> = new Array(MAX_STAR_RATING_VALUE + 1)
  .fill(MAX_STAR_RATING_VALUE)
  .map((value: number, index: number) => {
    if (index < MAX_STAR_RATING_VALUE) {
      return value - index;
    }

    return 0;
  });
const initialRatingsSelection = (): Array<boolean> => [
  ...Array.from({ length: MAX_STAR_RATING_VALUE + 1 }, () => false),
];

const getRatingsSelection = (
  selectedRatingsRoot: Array<number>, ratings: Array<number>,
): Array<boolean> => {
  const ratingsInitial: Array<boolean> = initialRatingsSelection();
  const selectedIndexes: Array<number> = selectedRatingsRoot.map(
    (ratingRoot: number) => ratings.findIndex(
      (rating: number) => ratingRoot === rating,
    ),
  );

  if (selectedRatingsRoot.length === 0) {
    return ratingsInitial;
  }
  
  const { length } = selectedIndexes;

  for (let i = 0; i < length; i += 1) {
    ratingsInitial[selectedIndexes[i]] = true;
  }

  return ratingsInitial;
};

export const useLogic = (): {
  ratings: Array<number>,
  ratingsSelect: Array<boolean>,
  onRatingsSelected: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  onRatingsSelectionClear: () => void,
} => {
  const dispatch = useDispatch();

  const hotelRatingsFilterRoot: Array<number> = useSelector(
    (store: RootState) => store.filters.rating,
  );

  const [ratings] = useState<Array<number>>(initialRatingsValues);
  const [ratingsSelect, setRatingsSelect] = useState<Array<boolean>>(initialRatingsSelection());

  useEffect(() => {
    const ratingsChecked: Array<boolean> = getRatingsSelection(
      hotelRatingsFilterRoot, ratings,
    );

    setRatingsSelect(ratingsChecked);
  // eslint-disable-next-line
  }, [hotelRatingsFilterRoot]);

  const onRatingsSelected = useCallback((
    event: ChangeEvent<HTMLInputElement>, checked: boolean,
  ): void => {
    if (!event) return;

    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const fallbackIfNull = '0-0';
    const ratingData: Array<string> = (eventTarget.getAttribute('data-rating-index') || fallbackIfNull).split('-');
    const ratingSelection = Number(ratingData[0]);
    const ratingIndex = Number(ratingData[1]);

    const ratingsSelectCopy = [...ratingsSelect];
    ratingsSelectCopy[ratingIndex] = checked;

    dispatch(checked ? selectHotelRating(ratingSelection) : unSelectHotelRating(ratingSelection));
    setRatingsSelect(ratingsSelectCopy);
  }, [ratingsSelect, dispatch]);

  const onRatingsSelectionClear = (): void => {
    const initialRatingsChecked: Array<boolean> = initialRatingsSelection();

    setRatingsSelect(initialRatingsChecked);
    dispatch(clearAllSelectedHotelRatings());
  };

  return {
    ratings,
    ratingsSelect,
    onRatingsSelected,
    onRatingsSelectionClear,
  };
};
