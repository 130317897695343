import Moment from 'moment';

import { CheckInCheckOut } from '../../models';

export default function checkInCheckoutPersistor(): CheckInCheckOut {
  const dateRanges: CheckInCheckOut = { checkIn: null, checkOut: null };
  const sessionDateRanges: string | null = sessionStorage.getItem('dataRange');

  if (sessionDateRanges) {
    const { checkIn, checkOut } = JSON.parse(sessionDateRanges);
    dateRanges.checkIn = Moment(checkIn);
    dateRanges.checkOut = Moment(checkOut);
  }

  return dateRanges;
}
