import React, { FC, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Box } from '../common';

import Bookings from './bookings/Bookings';
import Footer from '../hotel/layout/footer/Footer';
import Header from './layout/header/Header';

import { BOOKING_RESERVATIONS } from '../../routes/routesPath';

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    background: '#eceeee',
  },
  contentContainer: {
    [theme.breakpoints.between('sm', 'md')]: {
      marginLeft: '2%',
      marginRight: '2%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      marginLeft: '4%',
      marginRight: '4%',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      marginLeft: '6%',
      marginRight: '6%',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '15%',
      marginRight: '15%',
    },
  },
}));

const BookingsContainer: FC<unknown> = () => {
  const classes = useStyles();
  const [year] = useState<number>(new Date().getFullYear());

  return (
    <Box className={classes.content}>
      <Box className={classes.contentContainer}>
        <Header />
        <Switch>
          <Route path={BOOKING_RESERVATIONS} component={Bookings} />
        </Switch>
        <Footer year={year} />
      </Box>
    </Box>
  );
};

export default BookingsContainer;
