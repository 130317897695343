import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    guestsNumberContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    captionText: {
      color: 'gray',
    },
    numberContainer: {
      width: '30%',
    },
  },
));
