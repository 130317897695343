import { STORE_PROPERTY_DATA } from './actionTypes';

import { PropertyStoreData } from '../../models';

const selectedProperty = (
  data: PropertyStoreData,
): { type: string, payload: PropertyStoreData } => {
  sessionStorage.setItem('property', JSON.stringify(data));

  return {
    type: STORE_PROPERTY_DATA,
    payload: data,
  };
};

export {
  selectedProperty,
};
