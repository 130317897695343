import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { Box, IconButton, Tooltip } from '../../../common';

import ChildAgeSelection from '../childAgeSelection/ChildAgeSelection';
import GuestsSelection from '../guestsSelection/GuestsSelection';

import { Room as RoomModel } from '../../../../models';
import NumberSelectorType from '../../../../models/NumberSelectorType.enum';

import configs from '../../../../configs/environments';
import useStyles from './roomSectionStyles';

interface RoomSectionProps {
  room: RoomModel;
  roomIndex: number;
  onChange: (roomUpdate: RoomModel) => void;
  onRemove: () => void;
}

const ADULTS_MAX = configs.room.adults.max;
const ADULTS_MIN = configs.room.adults.min;
const CHILDREN_MAX = configs.room.children.max;
const CHILDREN_MIN = configs.room.children.min;
const ROOM_MIN_ALLOWED = configs.room.room.min;

const RoomSection: FC<RoomSectionProps> = (props: RoomSectionProps) => {
  const {
    room,
    roomIndex,
    onChange,
    onRemove,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleValueChange = (selectionType: string, valueChange: number): void => {
    const roomCopy: RoomModel = { ...room, agesOfChild: [...room.agesOfChild] };
    const oldValue = roomCopy[selectionType];
    roomCopy[selectionType] = oldValue + valueChange;

    if (selectionType === NumberSelectorType.CHILDREN && valueChange) {
      if (valueChange > 0) {
        roomCopy.agesOfChild.push(0);
      } else {
        roomCopy.agesOfChild.splice(valueChange, 1);
      }
    }

    onChange(roomCopy);
  };

  const handleAgeSelect = (selectedAge: number, ageIndex: number): void => {
    const roomCopy: RoomModel = { ...room };
    const childAgesCopy = [...room.agesOfChild];
    childAgesCopy[ageIndex] = selectedAge;

    roomCopy.agesOfChild = childAgesCopy;
    onChange(roomCopy);
  };

  return (
    <Box className={classes.roomSectionContainer}>
      <Box className={classes.roomTitleContainer}>
        <Box>
          <Box fontWeight="bold" pt={1}>{`${t('roomTitle')} ${roomIndex}`}</Box>
        </Box>
        <Box className={classes.removeRoomContainer}>
          {roomIndex > ROOM_MIN_ALLOWED && (
            <Tooltip title={t('roomRemoveButton')} aria-label="delete">
              <Box>
                <IconButton aria-label="delete" className={classes.deleteButton} onClick={onRemove}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box className={classes.numbersContainer}>
        <Box className={classes.guestsNumberContainer}>
          <GuestsSelection
            max={ADULTS_MAX}
            min={ADULTS_MIN}
            type={NumberSelectorType.ADULTS}
            value={room.adults}
            roomIndex={roomIndex}
            onChange={(value) => handleValueChange(NumberSelectorType.ADULTS, value)}
          />
        </Box>
        <Box className={classes.guestsNumberContainer}>
          <GuestsSelection
            max={CHILDREN_MAX}
            min={CHILDREN_MIN}
            type={NumberSelectorType.CHILDREN}
            value={room.children}
            roomIndex={roomIndex}
            onChange={(value) => handleValueChange(NumberSelectorType.CHILDREN, value)}
          />
        </Box>
      </Box>
      {room.agesOfChild.length > 0
        ? (
          <Box className={classes.childAgesContainer}>
            <ChildAgeSelection
              ages={room.agesOfChild}
              onSelect={
                (selectedAge: number, ageIndex: number) => handleAgeSelect(selectedAge, ageIndex)
              }
            />
          </Box>
        )
        : null }
    </Box>
  );
};

export default RoomSection;
