import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    content: {
      position: 'relative',
    },
    contentWrapper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.default,
      width: 405,
      maxHeight: 400,
      overflowY: 'auto',
      boxShadow: '0px 0px 12px #888888',
      borderRadius: 4,
      zIndex: 5,
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#000',
        borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.main,
      },
    },
    addRoomContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '20px 12px',
    },
    doneRoomContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginBottom: 10,
    },
    doneButton: {
      width: '95%',
    },
  },
));
