import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    card: {
      width: '100%',
      minWidth: 450,
      height: 106,
      backgroundColor: '#eceeee',
      boxShadow: 'none',
    },
    cardContent: {
      marginBottom: 8,
      padding: 0,
    },
    form: {
      position: 'absolute',
      width: '88%',
      [theme.breakpoints.between('md', 'lg')]: {
        width: '92%',
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        width: '88%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '70%',
      },
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    checkInCheckOutContainer: {
      width: '45%',
    },
    roomsContainer: {
      width: '40%',
    },
    buttonContainer: {
      marginTop: 17,
      '& Button': {
        paddingTop: 18,
        paddingBottom: 18,
        [theme.breakpoints.down('xs')]: {
          paddingTop: 3,
          paddingBottom: 3,
        },
      },
    },
  },
));
