import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { PhoneIphone as PhoneIcon, AlternateEmail as EmailIcon } from '@material-ui/icons';
import { Box, Card, CardContent } from '../../../common';

import { ProviderInfo } from '../../../../models';

const useStyles = makeStyles(() => createStyles({
  card: {
    width: '100%',
    minWidth: 450,
    margin: '8px 0',
  },
  cardContent: {
    paddingTop: 9,
  },
  contactContent: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: -5,
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
}));

export interface BookingVoucherConfirmationProps {
  providerInfo: ProviderInfo;
  reservationId: string;
}

const BookingVoucherConfirmation: FC<BookingVoucherConfirmationProps> = (
  props: BookingVoucherConfirmationProps,
) => {
  const { providerInfo, reservationId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  try {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box fontSize="h6.fontSize">{t('bookingConfirmationVoucherConfirmationTitle')}</Box>
          <Box mt={2}>
            <Box component="span" fontWeight="bold">{`${t('bookingConfirmationVoucherReferenceTitle')}: `}</Box>
            <Box component="span">{reservationId}</Box>
          </Box>
          <Box>
            <Box component="span" fontWeight="bold">{`${t('bookingConfirmationVoucherSourceChannel')}: `}</Box>
            <Box component="span">{providerInfo.providerName}</Box>
          </Box>
          <Box>
            <Box component="span" fontWeight="bold">{`${t('bookingConfirmationVoucherSourceChannelContacts')}: `}</Box>
            <Box className={classes.contactContent}>
              <Box className={classes.contactItem}>
                <PhoneIcon fontSize="small" />
                <Box component="span">{`${providerInfo.providerPhone}, `}</Box>
              </Box>
              <Box className={classes.contactItem}>
                <EmailIcon fontSize="small" />
                <Box component="span" pl="5px">{providerInfo.providerEmail}</Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  } catch {
    return <Box />;
  }
};

const MemoizedBookingVoucherConfirmation = React.memo(BookingVoucherConfirmation);
export default MemoizedBookingVoucherConfirmation;
