import React, { FC } from 'react';
import GoogleMap, { MapOptions } from 'google-map-react';
import { useTranslation } from 'react-i18next';
import nextId from 'react-id-generator';

import { Box, Button, Grow } from '../../common';

import Marker from '../marker/Marker';

import { AvailabilityLight, MapCoordinates } from '../../../models';

import { useLogic } from './hooks/useLogic';

import { GOOGLE_MAP_API_KEY } from '../../../configs/environments';

interface MapContainerProps {
  defaultCoordinates: MapCoordinates;
  height?: number;
  listCoordinates?: Array<AvailabilityLight>;
  mapOptions?: MapOptions;
  zoom?: number;
}

const MapContainer: FC<MapContainerProps> = (props: MapContainerProps) => {
  const {
    defaultCoordinates,
    height,
    listCoordinates,
    mapOptions,
    zoom,
  } = props;
  const { t } = useTranslation();
  const {
    centerState,
    heightState,
    showChangeLocationBtn,
    zoomState,
    onDragEnd,
    onMapLoaded,
    onLocationChangedClicked,
  } = useLogic(defaultCoordinates, height, listCoordinates, zoom);

  return (
    <Box width={'100%'} height={heightState}>
      <GoogleMap
        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
        center={centerState}
        zoom={zoomState}
        yesIWantToUseGoogleMapApiInternals
        options={(maps) => ({
          ...mapOptions,
          disableDoubleClickZoom: true,
          fullscreenControl: false,
          gestureHandling: 'greedy',
          zoomControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT,
          },
        })}
        onGoogleApiLoaded={onMapLoaded}
        onDragEnd={onDragEnd}
      >
        { !listCoordinates && <Marker lat={defaultCoordinates.lat} lng={defaultCoordinates.lng} /> }
        { listCoordinates?.map((data: AvailabilityLight) => (
          <Marker
            key={nextId('mluid-')}
            lat={data.coordinates.latitude}
            lng={data.coordinates.longitude}
            data={data}
          />
        )) }
      </GoogleMap>
      { listCoordinates && showChangeLocationBtn && (
        <Grow
          in={showChangeLocationBtn}
          style={{ transformOrigin: '0 0 0' }}
          // eslint-disable-next-line
          {...(showChangeLocationBtn ? { timeout: 1300 } : {})}
        >
          <Box position="absolute" top="95vh" left="45vw">
            <Button variant="contained" color="primary" onClick={onLocationChangedClicked}>{t('searchThisAreaBtnTitle')}</Button>
          </Box>
        </Grow>
      ) }
    </Box>
  );
};

export default MapContainer;
