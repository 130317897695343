import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useId } from 'react-id-generator';

import {
  Box, Button, Card, CardContent,
} from '../../common';

import CheckInCheckOut from '../checkInCheckOut/CheckInCheckOut';
import LocationAutoSuggest from '../locationAutoSuggest/LocationAutoSuggest';
import RadiusSearchInput from '../radiusSearchInput/RadiusSearchInput';
import RoomsWidget from '../roomsWidget/RoomsWidget';

import { useCommonLogic } from '../hooks/useCommonLogic';

import useStyles from './hotelSearchWidgetStyles';

const checkInCheckOutCorrectCSS = { left: -214 };

const HotelSearchWidget: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    hasCheckInCheckOutError, locationOnFocus, rooms, suggestion,
    onSetDateRanges,
    onSetLocation,
    onSetRooms,
    onSearchButtonClicked,
    setCheckInCheckOutHasAnError,
    setOnFocusLocation,
  } = useCommonLogic();

  const [newSearchId] = useId();

  useEffect(() => {
    const prevSearchId: string | null = sessionStorage.getItem('pid');
    
    if (prevSearchId) {
      sessionStorage.setItem('pid', prevSearchId);
      sessionStorage.setItem('cid', prevSearchId);
    } else {
      sessionStorage.setItem('pid', newSearchId);
      sessionStorage.setItem('cid', newSearchId);
    }
  // eslint-disable-next-line
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.titleContainer}>
          <Box fontWeight="fontWeightBold">
            {t('widgetLabel')}
          </Box>
          <Box fontWeight="fontWeightBold" fontSize="h5.fontSize">
            {t('widgetTitle')}
          </Box>
        </Box>
        <Box className={classes.titleContainer}>
          <Box className={classes.form}>

            <Box className={classes.locationContainer}>
              <Box className={classes.suggestionContainer}>
                <LocationAutoSuggest
                  locationOnFocus={locationOnFocus}
                  onChange={onSetLocation}
                  onFocus={setOnFocusLocation}
                  suggestion={suggestion}
                />
                <RadiusSearchInput />
              </Box>
            </Box>

            <Box className={classes.inFormContainer}>
              <Box className={classes.inputContainer}>
                <CheckInCheckOut
                  correctCss={checkInCheckOutCorrectCSS}
                  hasCheckInCheckOutError={hasCheckInCheckOutError}
                  setCheckInCheckError={setCheckInCheckOutHasAnError}
                  setDateRanges={onSetDateRanges}
                />
              </Box>

              <Box className={classes.inputContainer}>
                <RoomsWidget rooms={rooms} onSetRooms={onSetRooms} />
              </Box>
            </Box>

            <Box className={classes.buttonContainer}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={onSearchButtonClicked}
              >
                <Box fontWeight="fontWeightBold">
                  {t('widgetSearchButton')}
                </Box>
              </Button>
            </Box>

          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default HotelSearchWidget;
