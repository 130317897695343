import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import {
  FormControl,
  InputLabel,
  Select,
} from '../../../common';

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '45%',
  },
}));

const childAgesArray: Array<number> = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
];

interface ChildAgeProps {
  age: number;
  index: number;
  onSelect: (selectedAge: number) => void;
}

const ChildAge: FC<ChildAgeProps> = (props: ChildAgeProps) => {
  const { age, index, onSelect } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const selectLabel = (): string => (
    t('roomChildAgeLabelText', { index: index + 1 })
  );

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">{selectLabel()}</InputLabel>
      <Select
        native
        value={age}
        onChange={(event) => onSelect(+event.target.value)}
        label={selectLabel()}
        inputProps={{
          name: 'age',
          id: 'outlined-age-native-simple',
        }}
      >
        { childAgesArray.map((ageNumber: number, arrayIndex: number) => (
          (ageNumber === 0
            // eslint-disable-next-line
            ? <option key={arrayIndex} value={ageNumber}>Under 1</option>
            // eslint-disable-next-line
            : <option key={arrayIndex} value={ageNumber}>{ageNumber}</option>
          )
        ))}
      </Select>
    </FormControl>
  );
};

export default ChildAge;
