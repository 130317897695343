import {
  CheckInCheckOut as CheckInCheckOutModel,
  Location,
  Room as RoomModel,
} from '../models';

const hasValidLocation = (location: Location): boolean => (
  location.id !== 'null'
);

const hasInvalidCheckInCheckOut = (dateRanges: CheckInCheckOutModel): boolean => {
  const { checkIn, checkOut } = dateRanges;

  if (
    ((checkIn && !checkIn.isValid()) || (checkOut && !checkOut.isValid()))
    || (!checkIn && !checkOut)
    || (checkIn && !checkOut)) {
    return true;
  }

  return false;
};

const hasValidRoomSelection = (rooms: Array<RoomModel>): boolean => (
  rooms.length > 0 && rooms[0].adults > 0
);

export {
  hasValidLocation,
  hasInvalidCheckInCheckOut,
  hasValidRoomSelection,
};
