import React, { FC } from 'react';

import { Box } from '../../common';

import HotelLoadingCard from './hotelLoadingCard/HotelLoadingCard';

import { HOTEL_LOADING_CARDS } from '../../../configs/environments';
import useStyles from './hotelsLoadingStyles';

const HotelsLoading: FC<unknown> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.hotelsLoadingContainer}>
      { Array(HOTEL_LOADING_CARDS).fill(null)
        .map((value: null, index: number) => (
          // eslint-disable-next-line
          <Box key={index} className={classes.hotelLoadingCardContainer}>
            <HotelLoadingCard />
          </Box>
        ))}
    </Box>
  );
};

const MemoizedHotelsLoading = React.memo(HotelsLoading);
export default MemoizedHotelsLoading;
