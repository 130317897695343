import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    loadingImage: {
      borderRadius: 8,
      height: '100%',
      width: '100%',
      background: '#cccccc',
    },
    imageContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    imageIcon: {
      width: '50%',
      height: '50%',
      color: '#fff',
    },
  },
));
