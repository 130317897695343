import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Card, CardContent,
} from '../../../common';

import AvailabilitySortBy from '../../sortBy/AvailabilitySortBy';
import FullScreenMapDialog from '../../../map/fullScreenMapDialog/FullScreenMapDialog';
import HotelsLoading from '../../hotelsLoading/HotelsLoading';
import PropertyAddress from '../propertyAddress/PropertyAddress';
import PropertyAmenities from '../propertyAmenities/PropertyAmenities';
import PropertyDescription from '../propertyDescription/PropertyDescription';
import PropertyDetailsGallery from '../propertyDetailsGallery/PropertyDetailsGallery';
import PropertyMapContainer from '../../../map/propertyMapContainer/PropertyMapContainer';
import PropertyPointsOfInterest from '../propertyPoi/PropertyPointsOfInterest';
import PropertyPolicies from '../propertyPolicies/PropertyPolicies';
import PropertyRooms from '../propertyRooms/PropertyRooms';

import { PropertyDetails as PropertyDetailsModel } from '../../../../models';

import { useLogic } from './hooks/useLogic';
import { useOpenMap } from '../../../../hooks/useOpenMap';

import useStyles from './propertyDetailsStyles';

interface PropertyDetailsProps {
  propertyDetails: PropertyDetailsModel;
}

const PropertyDetails: FC<PropertyDetailsProps> = (props: PropertyDetailsProps) => {
  const { propertyDetails } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { openMap, handleOpenMap, handleCloseMap } = useOpenMap();
  const {
    fetchingAvailability,
    propertyAmenities,
    propertyAvailability,
    propertyImages,
    propertyPoi,
    propertyPolicies,
    roomAmenities,
    roomImages,
    memoizedMapCoordinates,
    memoizedGuests,
  } = useLogic(propertyDetails);

  const onScrollToTop = (): void => window.scrollTo(0, 0);

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box className={classes.galleryContainer}>
            <PropertyDetailsGallery images={propertyImages} />
          </Box>
          <Box className={classes.descAndMapContainer}>
            <Box className={classes.nameAndDescContainer}>
              <Box fontSize="h6.fontSize" fontWeight="bold" mb={2}>{t('propertyDetailsPropertyName', { name: propertyDetails.property_name })}</Box>
              <PropertyDescription descriptions={propertyDetails.propertyTexts} />
              <Box fontSize="h6.fontSize" fontWeight="bold" mb={2}>{t('propertyDetailsNearByPointTitle')}</Box>
              { propertyPoi.length > 0 && <PropertyPointsOfInterest poi={propertyPoi} /> }
            </Box>
            <Box className={classes.mapAndAddressContainer}>
              <Box className={classes.mapContainer} onClick={handleOpenMap}>
                {/* !openMap is needed to prevent MapContainer unnecessary render twice */}
                { propertyDetails.latitude && propertyDetails.longitude && !openMap
                  && (
                  <PropertyMapContainer defaultCoordinates={memoizedMapCoordinates} />
                  )}
              </Box>
              <Box className={classes.addressContainer}>
                <Box>
                  { propertyDetails?.propertyAddress?.[0] && (
                    <PropertyAddress address={propertyDetails.propertyAddress[0]} />
                  )}
                </Box>
                <Box className={classes.mapButtonContainer}>
                  <Button color="secondary" onClick={handleOpenMap}>{t('propertyDetailsViewOnMapButton')}</Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box>
        <Box fontSize="h6.fontSize" fontWeight="bold" p={'5px 0 5px 16px'}>{t('propertyRoomsAndRates')}</Box>
        { !fetchingAvailability && propertyAvailability?.length === 0 && (
          <Box className={classes.productsTitle}>
            <Box color="primary.main" fontWeight="bold" p={'5px 0 5px 16px'}>{t('propertyRoomsAndRatesNoAvailabilityText')}</Box>
            <Button className={classes.refineSearchBtn} onClick={onScrollToTop}>{t('propertyDetailsChangeSearchBtn')}</Button>
          </Box>
        )}
        <Box className={classes.sortByContainer}>
          <AvailabilitySortBy detailPage disabled={fetchingAvailability} />
        </Box>
        { !fetchingAvailability && propertyAvailability?.length > 0 && (
          <PropertyRooms
            amenities={roomAmenities}
            images={roomImages}
            guests={memoizedGuests}
            propertyAvailability={propertyAvailability}
            propertyId={propertyDetails.property_id}
          />
        )}
        { fetchingAvailability && <Box mt={1}><HotelsLoading /></Box> }
      </Box>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box>
            <Box fontSize="h6.fontSize" fontWeight="bold" mb={2}>{t('propertyDetailsAmenitiesTitle')}</Box>
            { propertyAmenities.length > 0
              ? (<PropertyAmenities amenities={propertyAmenities} />)
              : (<Box>{t('noInformationAvailable')}</Box>) }
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box>
            <Box fontSize="h6.fontSize" fontWeight="bold" mb={2}>{t('propertyDetailsPoliciesTitle')}</Box>
            { propertyPolicies.length > 0
              ? (<PropertyPolicies policies={propertyPolicies} />)
              : (<Box>{t('noInformationAvailable')}</Box>) }
          </Box>
        </CardContent>
      </Card>
      { openMap && propertyDetails.latitude && propertyDetails.longitude
        && (
          <FullScreenMapDialog
            defaultCoordinates={{
              lat: propertyDetails.latitude,
              lng: propertyDetails.longitude,
            }}
            open={openMap}
            title={propertyDetails.property_name}
            handleClose={handleCloseMap}
          />
        )}
    </>
  );
};

const MemoizedPropertyDetails = React.memo(PropertyDetails);
export default MemoizedPropertyDetails;
