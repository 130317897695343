import { SET_HOTEL_NEIGHBORHOOD } from './actionTypes';

import { Neighborhood } from '../../models';

// eslint-disable-next-line max-len
const sethHotelsNeighborhoods = (data: Array<Neighborhood>): { type: string, payload: Array<Neighborhood> } => (
  {
    type: SET_HOTEL_NEIGHBORHOOD,
    payload: data,
  }
);

export {
  sethHotelsNeighborhoods,
};
