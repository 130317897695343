import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    roomSectionContainer: {
      paddingTop: 1,
      paddingBottom: 14,
    },
    roomTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 10,
      marginTop: 8,
      marginBottom: 8,
    },
    numbersContainer: {
      paddingLeft: 32,
      paddingRight: 32,
      marginBottom: 8,
    },
    guestsNumberContainer: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    childAgesContainer: {
      width: 365,
      margin: '20px 21px 8px 21px',
    },
    removeRoomContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingLeft: 20,
      paddingRight: 20,
    },
    deleteButton: {
      color: '#000',
      marginTop: -6,
    },
  },
));
