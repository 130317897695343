import {
  ChangeEvent,
  Reducer,
  useEffect,
  useReducer,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Neighborhood } from '../../../../../../models';

import { clearAllSelectedHotelNeighborhoods, selectHotelNeighborhood, unSelectHotelNeighborhood } from '../../../../../../store/actions/filtersAction';
import { RootState } from '../../../../../../store/reducers';

import { INITIAL_FILTER_ITEMS } from '../../../../../../configs/environments';

const getFilterSelection = (
  selectedNeighborhoodsRoot: Array<number>, neighborhoods: Array<Neighborhood>,
): Array<boolean> => {
  const neighborhoodsInitial: Array<boolean> = Array.from(neighborhoods, () => false);

  const selectedIndexes: Array<number> = selectedNeighborhoodsRoot.map(
    (id: number) => neighborhoods.findIndex(
      (neighborhood: Neighborhood) => neighborhood.id === id,
    ),
  );

  if (selectedNeighborhoodsRoot.length === 0) {
    return neighborhoodsInitial;
  }
  
  const { length } = selectedIndexes;

  for (let i = 0; i < length; i += 1) {
    neighborhoodsInitial[selectedIndexes[i]] = true;
  }

  return neighborhoodsInitial;
};

enum ActionType {
  SET_NEIGHBORHOODS = 'SET_NEIGHBORHOODS',
  SET_NEIGHBORHOODS_SELECT = 'SET_NEIGHBORHOODS_SELECT',
  SET_EXPAND_WITH_NEIGHBORHOODS_MORE_LESS = 'SET_EXPAND_WITH_NEIGHBORHOODS_MORE_LESS',
}

interface State {
  expanded: boolean,
  neighborhoods: Array<Neighborhood>;
  neighborhoodsMoreLess: Array<Neighborhood>;
  neighborhoodsSelect: Array<boolean>,
}

const initialState: State = {
  expanded: false,
  neighborhoods: [{
    id: 1,
    city_id: 93,
    neighborhood: 'aaa',
  }],
  neighborhoodsMoreLess: [],
  neighborhoodsSelect: [],
};

type Action =
  // eslint-disable-next-line max-len
  | { type: ActionType.SET_NEIGHBORHOODS, neighborhoods: Array<Neighborhood>, neighborhoodsMoreLess: Array<Neighborhood>, neighborhoodsSelect: Array<boolean> }
  | { type: ActionType.SET_NEIGHBORHOODS_SELECT, neighborhoodsSelect: Array<boolean> }
  // eslint-disable-next-line max-len
  | { type: ActionType.SET_EXPAND_WITH_NEIGHBORHOODS_MORE_LESS, expanded: boolean, neighborhoodsMoreLess: Array<Neighborhood> };

const neighborhoodFilterReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_NEIGHBORHOODS: return {
      ...state,
      neighborhoods: [...action.neighborhoods],
      neighborhoodsMoreLess: [...action.neighborhoodsMoreLess],
      neighborhoodsSelect: [...action.neighborhoodsSelect],
    };
    case ActionType.SET_NEIGHBORHOODS_SELECT: return {
      ...state,
      neighborhoodsSelect: [...action.neighborhoodsSelect],
    };
    case ActionType.SET_EXPAND_WITH_NEIGHBORHOODS_MORE_LESS: return {
      ...state,
      expanded: action.expanded,
      neighborhoodsMoreLess: [...action.neighborhoodsMoreLess],
    };

    default: return state;
  }
};

export const useLogic = (): {
  hotelNeighborhoodFilterRoot: Array<number>,
  neighborhoods: Array<Neighborhood>,
  neighborhoodsMoreLess: Array<Neighborhood>,
  neighborhoodsSelect: Array<boolean>,
  expanded: boolean,
  onNeighborhoodSelected: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  onNeighborhoodSelectionClear: () => void,
  onExpandCollapse: () => void,
} => {
  const dispatch = useDispatch();

  const hotelNeighborhoodsRoot: Array<Neighborhood> = useSelector(
    (store: RootState) => store.hotelsNeighborhoods,
  );
  const hotelNeighborhoodFilterRoot: Array<number> = useSelector(
    (store: RootState) => store.filters.neighborhood,
  );

  const [state, reducerDispatch] = useReducer<Reducer<State, Action>>(
    neighborhoodFilterReducer, initialState,
  );
  const {
    expanded, neighborhoods, neighborhoodsMoreLess, neighborhoodsSelect,
  } = state;

  useEffect(() => {
    const neighborhoodsChecked: Array<boolean> = getFilterSelection(
      hotelNeighborhoodFilterRoot, hotelNeighborhoodsRoot,
    );

    reducerDispatch({
      type: ActionType.SET_NEIGHBORHOODS,
      neighborhoods: hotelNeighborhoodsRoot,
      neighborhoodsMoreLess: [...hotelNeighborhoodsRoot.slice(0, INITIAL_FILTER_ITEMS)],
      neighborhoodsSelect: neighborhoodsChecked,
    });
    // eslint-disable-next-line
  }, [hotelNeighborhoodsRoot]);

  useEffect(() => {
    const neighborhoodsChecked: Array<boolean> = getFilterSelection(
      hotelNeighborhoodFilterRoot, neighborhoods,
    );

    reducerDispatch({
      type: ActionType.SET_NEIGHBORHOODS_SELECT,
      neighborhoodsSelect: neighborhoodsChecked,
    });
    // eslint-disable-next-line
  }, [hotelNeighborhoodFilterRoot]);

  const onExpandCollapse = (): void => {
    const isExpanded = !expanded;
    let neighborhoodsCopy = [...neighborhoods];

    if (!isExpanded) {
      neighborhoodsCopy = [...neighborhoods.slice(0, INITIAL_FILTER_ITEMS)];
    }

    reducerDispatch({
      type: ActionType.SET_EXPAND_WITH_NEIGHBORHOODS_MORE_LESS,
      expanded: isExpanded,
      neighborhoodsMoreLess: neighborhoodsCopy,
    });
  };

  const onNeighborhoodSelected = (
    event: ChangeEvent<HTMLInputElement>, checked: boolean,
  ): void => {
    if (!event) return;

    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const fallbackIfNull = '0-0';
    const neighborhoodData: Array<string> = (eventTarget.getAttribute('data-neighborid-index') || fallbackIfNull).split('-');
    const neighborhoodId = Number(neighborhoodData[0]);
    const neighborhoodIndex = Number(neighborhoodData[1]);

    if (neighborhoodId === 0) return;

    const neighborhoodsSelectCopy = [...neighborhoodsSelect];
    neighborhoodsSelectCopy[neighborhoodIndex] = checked;
    // eslint-disable-next-line max-len
    dispatch(checked ? selectHotelNeighborhood(neighborhoodId) : unSelectHotelNeighborhood(neighborhoodId));
    reducerDispatch({
      type: ActionType.SET_NEIGHBORHOODS_SELECT,
      neighborhoodsSelect: neighborhoodsSelectCopy,
    });
  };

  const onNeighborhoodSelectionClear = (): void => {
    const initialNeighborhoodsChecked: Array<boolean> = Array.from(neighborhoods, () => false);

    reducerDispatch({
      type: ActionType.SET_NEIGHBORHOODS_SELECT,
      neighborhoodsSelect: initialNeighborhoodsChecked,
    });
    dispatch(clearAllSelectedHotelNeighborhoods());
  };

  return {
    hotelNeighborhoodFilterRoot,
    neighborhoods,
    neighborhoodsMoreLess,
    neighborhoodsSelect,
    expanded,
    onNeighborhoodSelected,
    onNeighborhoodSelectionClear,
    onExpandCollapse,
  };
};
