import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, CardContent } from '../../../common';

import BookingReservation from '../../../../models/BookingReservation';

import useStyles from './bookingConfirmationPaymentStyles';

const COMMON_BOX_MARGIN_TOP = 2;

export interface BookingConfirmationPaymentProps {
  reservation: BookingReservation;
}

const BookingConfirmationPayment: FC<BookingConfirmationPaymentProps> = (
  props: BookingConfirmationPaymentProps,
) => {
  const { reservation } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  try {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box fontSize="h6.fontSize">{t('bookingConfirmationPaymentTitle')}</Box>
          <Box className={classes.paymentContent}>
            <Box width="48%">
              <Box mt={COMMON_BOX_MARGIN_TOP} fontSize="h6.fontSize">{t('bookingReservationCardDetailsTitle')}</Box>
              <Box mt={COMMON_BOX_MARGIN_TOP}>
                <Box className={classes.paymentCardItemContent}>
                  <Box className={classes.paymentCardLeftItem}>{`${t('bookingReservationCardNumberText')}:`}</Box>
                  <Box className={classes.paymentCardRightItem}>
                    {reservation.guarantee.guarantee_accepted.payment_card.card_number}
                  </Box>
                </Box>
                <Box className={classes.paymentCardItemContent}>
                  <Box className={classes.paymentCardLeftItem}>{`${t('bookingReservationExpirationDateText')}:`}</Box>
                  <Box className={classes.paymentCardRightItem}>
                    {reservation.guarantee.guarantee_accepted.payment_card.expire_date}
                  </Box>
                </Box>
                <Box className={classes.paymentCardItemContent}>
                  <Box className={classes.paymentCardLeftItem}>{`${t('bookingConfirmationCardHolderTitle')}:`}</Box>
                  <Box className={classes.paymentCardRightItem}>
                    {reservation.guarantee.guarantee_accepted.payment_card.cardholder_name}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box width="48%">
              <Box mt={COMMON_BOX_MARGIN_TOP} fontSize="h6.fontSize">{t('bookingConfirmationBillingAddressTitle')}</Box>
              <Box mt={COMMON_BOX_MARGIN_TOP} display="flex" flexDirection="row">
                <Box marginRight="5px">
                  <Box component="span" fontWeight="bold">{`${t('address')}:`}</Box>
                </Box>
                <Box>
                  <Box>
                    <Box component="span">{`${reservation.guarantee.guarantee_accepted.payment_card.address.address_line[0]}, `}</Box>
                    <Box component="span">{reservation.guarantee.guarantee_accepted.payment_card.address.address_line[1]}</Box>
                  </Box>
                  <Box>
                    <Box component="span">{`${reservation.guarantee.guarantee_accepted.payment_card.address.city_name}, `}</Box>
                    <Box component="span">{reservation.guarantee.guarantee_accepted.payment_card.address.country_name.code}</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={COMMON_BOX_MARGIN_TOP} fontSize="h6.fontSize">{t('bookingConfirmationTransactionTitle')}</Box>
          <Box className={classes.transactionContent}>
            <Box className={classes.paymentCardLeftItem}>{`${t('bookingConfirmationTransactionStatusText')}:`}</Box>
            {/* `Paid` requested to be hardcoded */}
            <Box className={classes.paymentCardRightItem}>Paid</Box>
          </Box>
          <Box className={classes.transactionContent}>
            <Box className={classes.paymentCardLeftItem}>{`${t('bookingConfirmationTransactionMerchantText')}:`}</Box>
            <Box className={classes.paymentCardRightItem}>{`${t('footerCopyrightBrandText')} ${t('inc')}`}</Box>
          </Box>
        </CardContent>
      </Card>
    );
  } catch {
    return <Box />;
  }
};

const MemoizedBookingConfirmationPayment = React.memo(BookingConfirmationPayment);
export default MemoizedBookingConfirmationPayment;
