import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    marker: {
      width: 40,
      height: 40,
      color: theme.palette.primary.main,
      position: 'absolute',
      transform: 'translate(-50%, -100%)',
    },
    markerTooltipContent: {
      position: 'relative',
      top: -50,
      left: 15,
      padding: 5,
      borderRadius: 8,
      backgroundColor: '#0008',
      width: 150,
      zIndex: 1,
      textAlign: 'center',
    },
    markerTooltipText: {
      color: '#fff',
      fontSize: 12,
      fontWeight: 'bold',
    },
  },
));
