import genericApi from '../genericApi';

import { Amenity, ApiList } from '../../models';

import { PORTAL_BE_ROUTE_PREFIX } from '../../configs/environments';

const amenityRemote = {

  getAmenitiesFilters: (): Promise<ApiList<Amenity>> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/amenities`),

  getPropertyAmenities: (propertyId: number): Promise<Array<Amenity>> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/properties/${propertyId}/amenities`),
};

export default amenityRemote;
