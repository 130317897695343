import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '../../../common';

import HeaderCommon from '../../../common/layout/header/HeaderCommon';

import { RootState } from '../../../../store/reducers';

const Header: FC<unknown> = () => {
  const headerTitleRoot: string = useSelector(
    (state: RootState) => state.hotelsHeader.title,
  );

  return (
    <HeaderCommon>
      { headerTitleRoot ? (
        <Box fontSize="h4.fontSize">{headerTitleRoot}</Box>
      ) : null }
    </HeaderCommon>
  );
};

const MemoizedHeader = React.memo(Header);
export default MemoizedHeader;
