export default interface ApiList<T> {
  content: Array<T>;
  number: number;
  totalElements: number;
  totalPages: number;
}

export function getEmptyApiList<T>(): ApiList<T> {
  return {
    content: [],
    number: 0,
    totalElements: 0,
    totalPages: 0,
  };
}
