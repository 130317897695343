import { SET_SELECTED_ROOMS } from './actionTypes';

import { Room } from '../../models';

const setRoomsAction = (rooms: Array<Room>): { type: string, payload: Array<Room> } => {
  sessionStorage.setItem('rooms', JSON.stringify(rooms));

  return {
    type: SET_SELECTED_ROOMS,
    payload: rooms,
  };
};

export {
  setRoomsAction,
};
