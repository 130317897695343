import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    ratingTitleContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
        flexDirection: 'row',
      },
    },
    checkbox: {
      padding: '4px 4px 4px 9px',
    },
    formControlLabel: {
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '18%',
      },
    },
    startChecked: {
      marginTop: 5,
      color: '#000',
    },
    startUnchecked: {
      marginTop: 5,
      color: 'rgba(0, 0, 0, 0.2)',
    },
  },
));
