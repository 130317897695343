import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { hotelsRemote } from '../../../../../api/services';

import { PropertyDetails as PropertyDetailsModel, PropertyStoreData, Location } from '../../../../../models';

import { setHotelDetailsHeaderAction } from '../../../../../store/actions/hotelsHeaderAction';
import { RootState } from '../../../../../store/reducers';

import { HOME_ROUTE_PATH, HOTELS_ROUTE_PATH } from '../../../../../routes/routesPath';

import { CancelablePromise, makePromiseCancelable } from '../../../../../utils/makePromiseCancelable';

export const useLogic = (): {
  breadcrumbs: Array<string>,
  propertyDetails: PropertyDetailsModel | null,
  onBackButtonClicked: () => void;
} => {
  const dispatch = useDispatch();
  const history = useHistory();

  const locationRoot: Location = useSelector((state: RootState) => state.location);
  const propertyRoot: PropertyStoreData = useSelector((state: RootState) => state.property);

  const [breadcrumbs, setBreadcrumbs] = useState<Array<string>>([]);
  const [propertyDetails, setPropertyDetails] = useState<PropertyDetailsModel | null>(null);

  useEffect(() => {
    if (!propertyRoot.id) {
      history.push(HOME_ROUTE_PATH);
      return;
    }
    
    window.scrollTo(0, 0);
    let propertyName = propertyRoot.name;

    const cancelablePropertyById: CancelablePromise = makePromiseCancelable(
      hotelsRemote.getProperty(propertyRoot.id).then((resp: PropertyDetailsModel) => {
        if (resp && resp.property_id) {
          propertyName = resp.property_name;
          setPropertyDetails(resp);
        }
  
        dispatch(setHotelDetailsHeaderAction(propertyName));
      })
        .catch(() => dispatch(setHotelDetailsHeaderAction(propertyName))),
    );
    cancelablePropertyById.promise.then().catch((reason) => reason.isCanceled);

    if (locationRoot.id !== 'null') {
      setBreadcrumbs([
        locationRoot.country_name,
        locationRoot.destination_name,
        locationRoot.location_name,
        propertyRoot.name,
      ]);
    }

    // eslint-disable-next-line
    return () => {
      cancelablePropertyById.cancel();
    };
  // eslint-disable-next-line
  }, []);

  const onBackButtonClicked = (): void => (
    history.push(HOTELS_ROUTE_PATH)
  );

  return {
    breadcrumbs,
    propertyDetails,
    onBackButtonClicked,
  };
};
