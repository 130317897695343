import React, { FC } from 'react';

import CommonCheckboxFilter from '../commonCheckboxFilter/CommonCheckboxFilter';

import { useLogic } from './hooks/useLogic';

const AmenityFilter: FC<unknown> = () => {
  const {
    hotelAmenityFilterRoot,
    amenities,
    amenitiesMoreLess,
    amenitiesSelect,
    expanded,
    onAmenitySelected,
    onAmenitySelectionClear,
    onExpandCollapse,
  } = useLogic();

  return (
    <CommonCheckboxFilter
      expanded={expanded}
      items={amenities}
      itemsMoreLess={amenitiesMoreLess}
      itemsSelect={amenitiesSelect}
      itemsFilterRoot={hotelAmenityFilterRoot}
      title={'filterAmenitiesTitle'}
      onExpandCollapse={onExpandCollapse}
      onItemSelected={onAmenitySelected}
      onItemSelectionClear={onAmenitySelectionClear}
    />
  );
};

const MemoizedAmenityFilter = React.memo(AmenityFilter);
export default MemoizedAmenityFilter;
