import { Reducer, useEffect, useReducer } from 'react';

enum ActionType {
  SET_AMENITY_TYPE = 'SET_AMENITY_TYPE',
  SET_CHAIN_TYPE = 'SET_CHAIN_TYPE',
  SET_NEIGHBORHOOD_TYPE = 'SET_NEIGHBORHOOD_TYPE',
}

interface State {
  dataType: string;
  isTypeSafe: boolean;
  itemPropId: string;
  itemPropName: string;
}

const initialState: State = {
  dataType: '',
  isTypeSafe: false,
  itemPropId: '',
  itemPropName: '',
};

type Action =
  | { type: ActionType.SET_AMENITY_TYPE }
  | { type: ActionType.SET_CHAIN_TYPE }
  | { type: ActionType.SET_NEIGHBORHOOD_TYPE };

const guardFilterReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_AMENITY_TYPE: return {
      ...state,
      dataType: 'data-amenityid-index',
      isTypeSafe: true,
      itemPropId: 'amenityId',
      itemPropName: 'amenityName',
    };
    case ActionType.SET_CHAIN_TYPE: return {
      ...state,
      dataType: 'data-chainid-index',
      isTypeSafe: true,
      itemPropId: 'chainId',
      itemPropName: 'chainName',
    };
    case ActionType.SET_NEIGHBORHOOD_TYPE: return {
      ...state,
      dataType: 'data-neighborid-index',
      isTypeSafe: true,
      itemPropId: 'id',
      itemPropName: 'neighborhood',
    };
    default: return state;
  }
};

// eslint-disable-next-line
export const useRuntimeTypeGuards = <T extends object>(items: Array<T>): {
  dataType: string, isTypeSafe: boolean, itemPropId: string, itemPropName: string,
} => {
  const [
    state, reducerDispatch,
  ] = useReducer<Reducer<State, Action>>(guardFilterReducer, initialState);
  const {
    dataType, isTypeSafe, itemPropId, itemPropName,
  } = state;

  useEffect(() => {
    if (items.length > 0) {
      const item: T = items[0];

      if ('amenityId' in item) {
        reducerDispatch({ type: ActionType.SET_AMENITY_TYPE });
      }

      if ('chainId' in item) {
        reducerDispatch({ type: ActionType.SET_CHAIN_TYPE });
      }

      if ('neighborhood' in item) {
        reducerDispatch({ type: ActionType.SET_NEIGHBORHOOD_TYPE });
      }
    }
  }, [items]);

  return {
    dataType,
    isTypeSafe,
    itemPropId,
    itemPropName,
  };
};
