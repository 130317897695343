import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '../../../common';
import NumberSelector from '../../../common/numberSelector/NumberSelector';

import NumberSelectorType from '../../../../models/NumberSelectorType.enum';

import useStyles from './guestsSelectionStyles';

interface GuestsSelectionProps {
  max: number;
  min: number;
  type: string;
  value: number;
  roomIndex: number;
  onChange: (newValue: number) => void;
}

const GuestsSelection: FC<GuestsSelectionProps> = (props: GuestsSelectionProps) => {
  const {
    max,
    min,
    type,
    value,
    roomIndex,
    onChange,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const label = (): string => (
    type === NumberSelectorType.ADULTS ? t('roomAdultsText') : t('roomChildrenText')
  );

  return (
    <Box className={classes.guestsNumberContainer}>
      <Box>
        <Box fontSize="body1.fontSize">{label()}</Box>
        { type === NumberSelectorType.CHILDREN && (
          <Box fontSize="caption.fontSize">
            {t('roomChildrenHelperText')}
          </Box>
        )}
      </Box>
      <Box className={classes.numberContainer}>
        <NumberSelector
          max={max}
          min={min}
          type={type}
          value={value}
          roomIndex={roomIndex}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};

export default GuestsSelection;
