import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
} from '../../../common';

import ClearFilters from '../clearFilters/ClearFilters';

import { useRuntimeTypeGuards } from './hooks/useRuntimeTypeGuards';

import { INITIAL_FILTER_ITEMS } from '../../../../configs/environments';

import useStyles from './commonCheckboxFilterStyles';

interface CommonCheckboxFilterProp<T> {
  expanded: boolean;
  items: Array<T>;
  itemsMoreLess: Array<T>;
  itemsSelect: Array<boolean>;
  itemsFilterRoot: Array<number>;
  title: string;
  onExpandCollapse: () => void,
  onItemSelected: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  onItemSelectionClear: () => void,
}

// eslint-disable-next-line
const CommonCheckboxFilter = <T extends object>(props: CommonCheckboxFilterProp<T>) => {
  const {
    expanded,
    items,
    itemsMoreLess,
    itemsSelect,
    itemsFilterRoot,
    title,
    onExpandCollapse,
    onItemSelected,
    onItemSelectionClear,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    isTypeSafe, dataType, itemPropId, itemPropName,
  } = useRuntimeTypeGuards(items);

  return (
    <>
      { isTypeSafe && items.length > 0 && itemsSelect.length > 0 && (
        <>
          <Box className={classes.itemTitleContent}>
            <Box display="flex">
              <Box fontWeight="bold" lineHeight={1.8}>{t(title)}</Box>
              { itemsFilterRoot.length > 0 && (
                <IconButton className={classes.moreLessButton}>
                  <Badge
                    badgeContent={itemsFilterRoot.length}
                    color="primary"
                    className={classes.badgeLabel}
                  />
                </IconButton>
              ) }
            </Box>
            <ClearFilters showClear onSelectionClear={onItemSelectionClear} />
          </Box>
          <FormControl className={classes.form}>
            { itemsMoreLess.map((item: T, index: number) => (
              <FormControlLabel
                key={item[itemPropId]}
                value="sealed"
                control={(
                  <Checkbox
                    inputProps={{
                      [dataType]: `${item[itemPropId]}-${index}`,
                    // eslint-disable-next-line
                    } as any}
                    color="default"
                    classes={{ root: classes.checkbox }}
                    onChange={onItemSelected}
                    checked={itemsSelect[index]}
                  />
                )}
                label={<Box fontSize="14px">{item[itemPropName]}</Box>}
                className={classes.formControlLabel}
              />
            ))}
            <Box marginLeft="auto">
              { items.length > INITIAL_FILTER_ITEMS && (
                <Button className={classes.moreLessButton} onClick={onExpandCollapse}>
                  {expanded ? t('propertyDetailsShowLess') : t('propertyDetailsShowMore')}
                </Button>
              ) }
            </Box>
          </FormControl>
        </>
      )}
    </>
  );
};

export default CommonCheckboxFilter;
