import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
} from '../../common';

import { AvailabilityProductWithId, CheckInCheckOut, Room } from '../../../models';

import { RootState } from '../../../store/reducers';

import { currencySign } from '../../../utils/currencyMapper';
import { HOTEL_ROUTE_PATH } from '../../../routes/routesPath';
import { CURRENCY_HUNDRED_UNIT } from '../../../configs/environments';

const useStyles = makeStyles(() => createStyles({
  card: {
    width: '100%',
    minWidth: 450,
    minHeight: 106,
    margin: '8px 0',
  },
}));

interface RoomSummary {
  adults: number;
  children: number;
  rooms: number;
}

const BookingReservationDetails: FC<unknown> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const dateRangesRoot: CheckInCheckOut = useSelector(
    (state: RootState) => state.checkInCheckOut,
  );
  const productRoot: AvailabilityProductWithId | null = useSelector(
    (state: RootState) => state.booking,
  );
  const roomsRoot: Array<Room> = useSelector((state: RootState) => state.rooms);

  const [roomSummary, setRoomSummary] = useState<RoomSummary>({ adults: 0, children: 0, rooms: 0 });

  useEffect(() => {
    const rSummary: RoomSummary = {
      adults: 0,
      children: 0,
      rooms: roomsRoot.length,
    };

    for (let i = 0; i < rSummary.rooms; i += 1) {
      rSummary.adults += roomsRoot[i].adults;
      rSummary.children += roomsRoot[i].children;
    }

    setRoomSummary(rSummary);
  // eslint-disable-next-line
  }, []);

  const onEditSearchClicked = (): void => {
    history.push(HOTEL_ROUTE_PATH);
  };

  const roomText = roomsRoot.length === 1 ? t('bookingReservationRoomText') : t('bookingReservationRoomsText');
  const arrivalDateStr = dateRangesRoot.checkIn ? Moment(dateRangesRoot.checkIn).format('MMM DD') : '';
  const departureDateStr = dateRangesRoot.checkOut ? Moment(dateRangesRoot.checkOut).format('MMM DD') : '';

  try {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box fontSize="h6.fontSize">{t('bookingReservationDetailsTitle')}</Box>
            <Button
              color="primary"
              variant="contained"
              onClick={onEditSearchClicked}
            >
              {t('editSearchButtonTitle')}
            </Button>
          </Box>
          <Box mt={0.8} fontWeight="bold">
            <Box component="span">{`${roomText}: `}</Box>
            <Box component="span">{`${roomsRoot.length}, `}</Box>
            <Box component="span">{`${t('bookingReservationAdultsText')}: `}</Box>
            <Box component="span">{`${roomSummary.adults}, `}</Box>
            <Box component="span">{`${t('bookingReservationChildrenText')}: `}</Box>
            <Box component="span">{roomSummary.children}</Box>
          </Box>
          <Box mb={2} fontWeight="bold">
            <Box component="span">{`${t('bookingReservationArrivalText')}: `}</Box>
            <Box component="span">{`${arrivalDateStr}, `}</Box>
            <Box component="span">{`${t('bookingReservationDepartureText')}: `}</Box>
            <Box component="span">{departureDateStr}</Box>
          </Box>
          <Box>
            <Box component="span" fontWeight="bold">{`${t('bookingReservationRoomRateText')}: `}</Box>
            <Box component="span">{`${productRoot.product.room_type.description}, `}</Box>
            <Box component="span" fontWeight="bold">{`${t('bookingReservationInclusionsText')}: `}</Box>
            <Box component="span">{productRoot.product.room_stays[0].meal_plan}</Box>
          </Box>
          <Box>
            <Box component="span" fontWeight="bold">{`${t('bookingReservationCancellationText')}: `}</Box>
            <Box component="span">{productRoot.product.room_stays[0].cancellation_string}</Box>
          </Box>
          <Box>
            <Box component="span" fontWeight="bold">{`${t('bookingReservationTotalToPayText')}: `}</Box>
            <Box component="span">{`${currencySign(productRoot.product.rate.currency)}${productRoot.product.rate.amount / CURRENCY_HUNDRED_UNIT} `}</Box>
            <Box component="span">{`(${productRoot.product.rate.currency.toUpperCase()})`}</Box>
          </Box>
        </CardContent>
      </Card>
    );
  } catch {
    history.push(HOTEL_ROUTE_PATH);
    return null;
  }
};

const MemoizedBookingReservationDetails = React.memo(BookingReservationDetails);
export default MemoizedBookingReservationDetails;
