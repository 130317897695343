import React, { FC } from 'react';
import nextId from 'react-id-generator';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from '../../../common';

import { AvailabilityError, BookingWarning } from '../../../../models';

const useStyles = makeStyles((theme: Theme) => createStyles({
  errorContent: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderLeft: '4px solid #000',
    borderRadius: 8,
    width: '100%',
    height: 40,
    marginBottom: 4,
  },
  error: {
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
  },
}));

export interface BookingConfirmationErrorProps {
  errors?: Array<AvailabilityError>;
  /**
   * Due the inconsistency on the BE, return a different format
   * it is needed to implement additional logic to handle it
   */
  warning?: BookingWarning;
}

const BookingConfirmationError: FC<BookingConfirmationErrorProps> = (
  props: BookingConfirmationErrorProps,
) => {
  const { errors, warning } = props;
  const classes = useStyles();

  return (
    <Box mt="8px">
      { errors ? (
        errors.map((error: AvailabilityError) => (
          <Box key={nextId('bckerr-')} className={classes.errorContent}>
            <Box className={classes.error}>{ error.errorMessage }</Box>
          </Box>
        ))
      ) : (
        <>
          { warning && (
            <Box className={classes.errorContent}>
              <Box className={classes.error}>{ warning.warningMessage }</Box>
            </Box>
          ) }
        </>
      ) }
    </Box>
  );
};

const MemoizedBookingConfirmationError = React.memo(BookingConfirmationError);
export default MemoizedBookingConfirmationError;
