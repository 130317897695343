import { Reducer, useEffect, useReducer } from 'react';

enum ActionType {
  SET_POPULAR_LOCATION_TYPE = 'SET_POPULAR_LOCATION_TYPE',
}

interface State {
  isTypeSafe: boolean;
  itemPropId: string;
  itemPropName: string;
}

const initialState: State = {
  isTypeSafe: false,
  itemPropId: '',
  itemPropName: '',
};

type Action =
  | { type: ActionType.SET_POPULAR_LOCATION_TYPE };

const guardFilterReducer = (state: State, action: Action) => {
  if (action.type === ActionType.SET_POPULAR_LOCATION_TYPE) {
    return {
      ...state,
      isTypeSafe: true,
      itemPropId: 'id',
      itemPropName: 'name',
    };
  }

  return state;
};

// eslint-disable-next-line
export const useRuntimeTypeGuards = <T extends object>(items: Array<T>): {
  isTypeSafe: boolean, itemPropId: string, itemPropName: string,
} => {
  const [
    state, reducerDispatch,
  ] = useReducer<Reducer<State, Action>>(guardFilterReducer, initialState);
  const {
    isTypeSafe, itemPropId, itemPropName,
  } = state;

  useEffect(() => {
    if (items.length > 0) {
      const item: T = items[0];

      if ('name' in item) {
        reducerDispatch({ type: ActionType.SET_POPULAR_LOCATION_TYPE });
      }
    }
  }, [items]);

  return {
    isTypeSafe,
    itemPropId,
    itemPropName,
  };
};
