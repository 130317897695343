import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '../../common';

import { BookingRoomHolder, BookingRoomHolderErrors } from '../../../models';

import titles from '../../../configs/titles';
import AgeQualifyingCode from '../../../models/AgeQualifyingCode.enum';

const useStyles = makeStyles(() => createStyles({
  textfield: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#000',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));

interface BookingDetailsRoomHolderProps {
  index: number;
  roomHolder: BookingRoomHolder;
  roomHolderErrors: BookingRoomHolderErrors;
  onChange: (
    roomHolder: BookingRoomHolder,
    roomHolderErrors: BookingRoomHolderErrors,
    roomHolderIndex: number,
  ) => void;
}

const BookingDetailsRoomHolder: FC<BookingDetailsRoomHolderProps> = (
  props: BookingDetailsRoomHolderProps,
) => {
  const {
    index,
    roomHolder,
    roomHolderErrors,
    onChange,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (type: string, value: string): void => {
    onChange(
      { ...roomHolder, [type]: value },
      { ...roomHolderErrors, [type]: value.trim() === '' },
      index,
    );
  };

  return (
    <>
      { roomHolder && (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box width="10%">
            <FormControl fullWidth variant="outlined" margin="normal" className={classes.textfield}>
              <InputLabel htmlFor="title">{t('title')}</InputLabel>
              <Select
                native
                label={t('title')}
                inputProps={{ id: 'title' }}
                onChange={(event) => handleChange('name_prefix', event.target.value as string)}
                value={roomHolder.name_prefix}
                error={roomHolderErrors.name_prefix}
              >
                <option aria-label="None" value="" />
                {titles.map(
                  (el) => <option key={el.value} value={el.value}>{t(el.label)}</option>,
                )}
              </Select>
            </FormControl>
          </Box>
          <Box width="35%">
            <TextField
              fullWidth
              variant="outlined"
              label={t('firstName')}
              margin="normal"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              onChange={(event) => handleChange('given_name', event.target.value)}
              value={roomHolder.given_name}
              error={roomHolderErrors.given_name}
            />
          </Box>
          <Box width="35%">
            <TextField
              fullWidth
              variant="outlined"
              label={t('surname')}
              margin="normal"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              onChange={(event) => handleChange('surname', event.target.value)}
              value={roomHolder.surname}
              error={roomHolderErrors.surname}
            />
          </Box>
          <Box width="10%" display="flex" alignItems="center">
            { roomHolder.age_qualifying_code === AgeQualifyingCode.ADULT && <Box>{t('bookingReservationRoomAdultText', { number: index + 1 })}</Box> }
            { roomHolder.age_qualifying_code === AgeQualifyingCode.CHILD && <Box>{t('bookingReservationRoomChildAgeText', { age: roomHolder.age })}</Box> }
          </Box>
        </Box>
      )}
    </>
  );
};

export default BookingDetailsRoomHolder;
