import { STORE_HOTELS_COORDINATES } from '../actions/actionTypes';

import { AvailabilityLight } from '../../models';
import ApiList, { getEmptyApiList } from '../../models/ApiList';

interface HotelsCoordinatesReducer {
  items: ApiList<AvailabilityLight>;
}

const initialState: HotelsCoordinatesReducer = {
  items: getEmptyApiList(),
};

export default (
  state: HotelsCoordinatesReducer = initialState,
  action: { type: string; payload: HotelsCoordinatesReducer },
): HotelsCoordinatesReducer => {
  if (action.type === STORE_HOTELS_COORDINATES) {
    return {
      ...state,
      items: action.payload.items,
    };
  }

  return state;
};
