import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControl,
  TextField,
  Select,
} from '../../common';

import { locationRemote } from '../../../api/services';

import {
  ApiList,
  BookingPersonData,
  BookingPersonDataErrors,
  Country,
} from '../../../models';

import titles from '../../../configs/titles';
import useStyles from './bookingBillingAddressStyles';

interface BookingBillingAddressProps {
  personData: BookingPersonData;
  personDataErrors: BookingPersonDataErrors;
  onChange: (personData: BookingPersonData) => void;
}

const BookingBillingAddress: FC<BookingBillingAddressProps> = (
  props: BookingBillingAddressProps,
) => {
  const { personData, personDataErrors, onChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [countries, setCountries] = useState<Array<Country>>([]);

  useEffect(() => {
    let isComponentAlive = true;
    const page = 0;
    const size = 300; // total countries in the world: 197
    const sortBy = 'countryName';

    locationRemote.getCountries(page, size, sortBy).then((resp: ApiList<Country>) => {
      const { content } = resp;
      if (isComponentAlive && content) {
        setCountries(content);
      }
    }).catch(() => setCountries([]));

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const handleChange = (prop: string, type: string, value: string): void => {
    const data = { ...personData, [prop]: { ...personData[prop], [type]: value } };

    onChange(data);
  };

  return (
    <>
      <Box fontWeight="bold" mb={2}>{t('bookingReservationCardHolderAddress')}</Box>
      <Box className={classes.nameAddressContent}>
        <Box width="20%">
          <Box fontWeight="bold" mb={'8px'}>{t('title')}</Box>
          <FormControl fullWidth variant="outlined" className={classes.textfield}>
            <Select
              native
              inputProps={{ id: 'title' }}
              onChange={(event) => handleChange('person', 'title', event.target.value as string)}
              value={personData.person.title}
              error={personDataErrors.person.title}
            >
              <option aria-label="None" value="" />
              {titles.map(
                (el) => <option key={el.value} value={el.value}>{t(el.label)}</option>,
              )}
            </Select>
          </FormControl>
        </Box>
        <Box width="35%">
          <Box fontWeight="bold">{t('firstName')}</Box>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            autoComplete="off"
            type="text"
            classes={{ root: classes.textfield }}
            onChange={(event) => handleChange('person', 'firstName', event.target.value)}
            value={personData.person.firstName}
            error={personDataErrors.person.firstName}
          />
        </Box>
        <Box width="35%">
          <Box fontWeight="bold">{t('lastName')}</Box>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            autoComplete="off"
            type="text"
            classes={{ root: classes.textfield }}
            onChange={(event) => handleChange('person', 'lastName', event.target.value)}
            value={personData.person.lastName}
            error={personDataErrors.person.lastName}
          />
        </Box>
      </Box>
      <Box className={classes.addressContent}>
        <Box fontWeight="bold">{t('streetAddress')}</Box>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="text"
          classes={{ root: classes.textfield }}
          onChange={(event) => handleChange('address', 'streetLine', event.target.value)}
          value={personData.address.streetLine}
          error={personDataErrors.address.streetLine}
        />
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="text"
          classes={{ root: classes.textfield }}
          onChange={(event) => handleChange('address', 'streetLineSecond', event.target.value)}
          value={personData.address.streetLineSecond}
          error={personDataErrors.address.streetLineSecond}
        />
      </Box>
      <Box className={classes.nameAddressContent}>
        <Box width="35%">
          <Box fontWeight="bold">{t('city')}</Box>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            autoComplete="off"
            type="text"
            classes={{ root: classes.textfield }}
            onChange={(event) => handleChange('address', 'cityName', event.target.value)}
            value={personData.address.cityName}
            error={personDataErrors.address.cityName}
          />
        </Box>
        <Box width="35%">
          <Box fontWeight="bold">{t('stateProvince')}</Box>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            autoComplete="off"
            type="text"
            classes={{ root: classes.textfield }}
            onChange={(event) => handleChange('address', 'stateProvince', event.target.value)}
            value={personData.address.stateProvince}
            error={personDataErrors.address.stateProvince}
          />
        </Box>
        <Box width="20%">
          <Box fontWeight="bold">{t('postalCode')}</Box>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            autoComplete="off"
            type="text"
            classes={{ root: classes.textfield }}
            onChange={(event) => handleChange('address', 'postalCode', event.target.value)}
            value={personData.address.postalCode}
            error={personDataErrors.address.postalCode}
          />
        </Box>
      </Box>
      <Box>
        <Box fontWeight="bold">{t('country')}</Box>
        <FormControl fullWidth variant="outlined" margin="dense" className={classes.textfield}>
          <Select
            native
            inputProps={{ id: 'title' }}
            onChange={(event) => handleChange('address', 'countryCode', event.target.value as string)}
            value={personData.address.countryCode}
            error={personDataErrors.address.countryCode}
          >
            <option aria-label="None" value="" />
            {countries.map((country: Country) => (
              <option
                key={country.countryCode}
                value={country.countryCode}
              >
                {country.countryName}
              </option>
            )) }
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

const MemoizedBookingBillingAddress = React.memo(BookingBillingAddress);
export default MemoizedBookingBillingAddress;
