import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import nextId from 'react-id-generator';

import {
  Box,
  CardMedia,
  FormControl,
  TextField,
  Select,
} from '../../common';

import { BookingCardData, BookingCardDataErrors } from '../../../models';

import { BOOKING_EXPIRY_DATE_YEARS_RANGE } from '../../../configs/environments';
import useStyles from './bookingCardDetailsStyles';
import paymentIcons from '../../../configs/paymentIcons';

const MONTH = 'Month';
const YEAR = 'Year';
const months = [
  { label: MONTH, value: -1 },
  { label: '01-Jan', value: 1 },
  { label: '02-Feb', value: 2 },
  { label: '03-Mar', value: 3 },
  { label: '04-Apr', value: 4 },
  { label: '05-May', value: 5 },
  { label: '06-Jun', value: 6 },
  { label: '07-Jul', value: 7 },
  { label: '08-Aug', value: 8 },
  { label: '09-Sep', value: 9 },
  { label: '10-Oct', value: 10 },
  { label: '11-Nov', value: 11 },
  { label: '12-Dec', value: 12 },
];

const lastYear: Moment.Moment = Moment().subtract(1, 'year');
const initialYearNumber = Number(Moment(lastYear).format('YYYY'));
const rangeOffset = 2;
const yearsRange = BOOKING_EXPIRY_DATE_YEARS_RANGE;

const years: Array<string | number> = Array.from(
  { length: yearsRange + rangeOffset }, (_, i) => {
    if (i === 0) return YEAR; return initialYearNumber + i;
  },
);

interface BookingCardDetailsProps {
  cardData: BookingCardData;
  cardErrors: BookingCardDataErrors;
  onChange: (cardData: BookingCardData, cardErrors: BookingCardDataErrors) => void;
}

const BookingCardDetails: FC<BookingCardDetailsProps> = (props: BookingCardDetailsProps) => {
  const { cardData, cardErrors, onChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (type: string, value: string): void => {
    const data = { ...cardData, [type]: value };
    const errors = { ...cardErrors };

    switch (type) {
      case 'cardNumber': errors[type] = Number.isNaN(Number(value)); break;
      case 'expMonth': errors[type] = value === '-1'; break;
      case 'expYear': errors[type] = value === YEAR; break;
      default: break;
    }

    onChange(data, errors);
  };

  return (
    <>
      <Box fontWeight="bold" mb={2}>{t('bookingReservationCardDetailsTitle')}</Box>
      <Box>
        <Box className={classes.cardNumberContent}>
          <Box fontWeight="bold">{t('bookingReservationCardNumberText')}</Box>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            autoComplete="off"
            classes={{ root: classes.textfield }}
            value={cardData.cardNumber}
            onChange={(event) => handleChange('cardNumber', event.target.value)}
            error={cardErrors.cardNumber}
            helperText={cardErrors.cardNumber ? t('bookingReservationInvalidCard') : ''}
          />
        </Box>
        <Box className={classes.expiryDateContent}>
          <Box fontWeight="bold">{t('bookingReservationExpirationDateText')}</Box>
          <Box display="flex" flexDirection="row" mt={'8px'}>
            <Box width="20%" mr="2px">
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.textfield}
              >
                <Select
                  native
                  value={cardData.expMonth}
                  onChange={(event) => handleChange('expMonth', event.target.value as string)}
                  error={cardErrors.expMonth}
                >
                  { months.map((month) => (
                    <option key={month.value} value={month.value}>{month.label}</option>
                  )) }
                </Select>
              </FormControl>
            </Box>
            <Box width="20%">
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.textfield}
              >
                <Select
                  native
                  value={cardData.expYear}
                  onChange={(event) => handleChange('expYear', event.target.value as string)}
                  error={cardErrors.expYear}
                >
                  { years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  )) }
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box width="20%">
          <Box fontWeight="bold">{t('bookingReservationSecurityCodeText')}</Box>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            autoComplete="off"
            classes={{ root: classes.textfield }}
          />
        </Box>
        <Box className={classes.paymentIconContent}>
          { paymentIcons.map((iconData) => (
            <CardMedia
              key={nextId('img-')}
              className={classes.paymentIcon}
              component="img"
              alt={iconData.name}
              image={iconData.icon}
            />
          )) }
        </Box>
      </Box>
    </>
  );
};

const MemoizedBookingCardDetails = React.memo(BookingCardDetails);
export default MemoizedBookingCardDetails;
