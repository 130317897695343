import { Dispatch } from 'redux';
import { User } from '../../models';
import { LOGIN_SUCCESS, LOGIN_FAIL } from './actionTypes';

function storeLogin(user: User): { type: string; payload: User; } {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
}

function removeLogin(): { type: string; } {
  return {
    type: LOGIN_FAIL,
  };
}

const loginSuccess = (user: User) => ((dispatch: Dispatch): void => {
  sessionStorage.setItem('user', JSON.stringify(user));
  dispatch(storeLogin(user));
});

const loginFail = () => ((dispatch: Dispatch): void => {
  sessionStorage.removeItem('user');
  dispatch(removeLogin());
});

export {
  loginSuccess,
  loginFail,
};
