import React, { FC, ReactNode } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent } from '../../common';

import TabContent from './TabContent';

import { ApiList, BookingReservation } from '../../../models';

const useStyles = makeStyles(() => createStyles({
  card: {
    width: '100%',
    minWidth: 450,
    minHeight: '60vh',
    margin: '8px 0',
  },
}));

interface BookingTabContentProps {
  children?: ReactNode;
  index: number;
  items: ApiList<BookingReservation>,
  value: number;
}

const BookingTabContent: FC<BookingTabContentProps> = (props: BookingTabContentProps) => {
  const {
    children,
    value,
    index,
    items,
    ...other
  } = props;
  const classes = useStyles();

  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line
      {...other}
    >
      <Card className={classes.card}>
        <CardContent>
          <Box>
            { items.content.map((reservation: BookingReservation) => (
              <TabContent key={reservation.reservation_id} reservation={reservation} />
            ))}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const MemoizedBookingTabContent = React.memo(BookingTabContent);
export default MemoizedBookingTabContent;
