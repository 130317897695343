import React, { FC } from 'react';

import { CropOriginal as ImageIcon } from '@material-ui/icons';
import { Box } from '../../../common';

import useStyles from './hotelLoadingImageStyles';

const HotelLoadingImage: FC<unknown> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loadingImage}>
      <Box className={classes.imageContent}>
        <ImageIcon className={classes.imageIcon} />
      </Box>
    </Box>
  );
};

export default HotelLoadingImage;
