import React, { FC } from 'react';

import CommonCheckboxFilter from '../commonCheckboxFilter/CommonCheckboxFilter';

import { useLogic } from './hooks/useLogic';

const ChainFilter: FC<unknown> = () => {
  const {
    hotelChainFilterRoot,
    chains,
    chainsMoreLess,
    chainsSelect,
    expanded,
    onChainSelected,
    onChainSelectionClear,
    onExpandCollapse,
  } = useLogic();

  return (
    <CommonCheckboxFilter
      expanded={expanded}
      items={chains}
      itemsMoreLess={chainsMoreLess}
      itemsSelect={chainsSelect}
      itemsFilterRoot={hotelChainFilterRoot}
      title={'filterChainTitle'}
      onExpandCollapse={onExpandCollapse}
      onItemSelected={onChainSelected}
      onItemSelectionClear={onChainSelectionClear}
    />
  );
};

const MemoizedChainFilter = React.memo(ChainFilter);
export default MemoizedChainFilter;
