import {
  ChangeEvent,
  Reducer,
  useEffect,
  useReducer,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { amenityRemote } from '../../../../../../api/services';

import { Amenity, ApiList } from '../../../../../../models';

import { clearAllSelectedHotelAmenities, selectHotelAmenity, unSelectHotelAmenity } from '../../../../../../store/actions/filtersAction';
import { RootState } from '../../../../../../store/reducers';

import { CancelablePromise, makePromiseCancelable } from '../../../../../../utils/makePromiseCancelable';

import { INITIAL_FILTER_ITEMS } from '../../../../../../configs/environments';

const getFilterSelection = (
  selectedAmenitiesRoot: Array<number>, amenities: Array<Amenity>,
): Array<boolean> => {
  const amenitiesInitial: Array<boolean> = Array.from(amenities, () => false);
  const selectedIndexes: Array<number> = selectedAmenitiesRoot.map(
    (amenityId: number) => amenities.findIndex(
      (amenity: Amenity) => amenity.amenityId === amenityId,
    ),
  );

  if (selectedAmenitiesRoot.length === 0) {
    return amenitiesInitial;
  }
  
  const { length } = selectedIndexes;

  for (let i = 0; i < length; i += 1) {
    amenitiesInitial[selectedIndexes[i]] = true;
  }

  return amenitiesInitial;
};

enum ActionType {
  SET_AMENITIES_FAIL_FETCH = 'SET_AMENITIES_FAIL_FETCH',
  SET_AMENITIES_SELECT = 'SET_AMENITIES_SELECT',
  SET_AMENITIES_SUCCESS_FETCH = 'SET_AMENITIES_SUCCESS_FETCH',
  SET_EXPAND_WITH_AMENITIES_MORE_LESS = 'SET_EXPAND_WITH_AMENITIES_MORE_LESS',
}

interface State {
  amenities: Array<Amenity>,
  amenitiesMoreLess: Array<Amenity>,
  amenitiesSelect: Array<boolean>,
  expanded: boolean,
}

const initialState: State = {
  amenities: [],
  amenitiesMoreLess: [],
  amenitiesSelect: [],
  expanded: false,
};

type Action =
  // eslint-disable-next-line max-len
  | { type: ActionType.SET_AMENITIES_FAIL_FETCH, amenities: Array<Amenity>, amenitiesSelect: Array<boolean> }
  | { type: ActionType.SET_AMENITIES_SELECT, amenitiesSelect: Array<boolean> }
  // eslint-disable-next-line max-len
  | { type: ActionType.SET_AMENITIES_SUCCESS_FETCH, amenities: Array<Amenity>, amenitiesMoreLess: Array<Amenity>, amenitiesSelect: Array<boolean> }
  // eslint-disable-next-line max-len
  | { type: ActionType.SET_EXPAND_WITH_AMENITIES_MORE_LESS, expanded: boolean, amenitiesMoreLess: Array<Amenity> };

const amenityFilterReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_AMENITIES_FAIL_FETCH: return {
      ...state,
      amenities: [...action.amenities],
      amenitiesSelect: [...action.amenitiesSelect],
    };
    case ActionType.SET_AMENITIES_SELECT: return {
      ...state,
      amenitiesSelect: [...action.amenitiesSelect],
    };
    case ActionType.SET_AMENITIES_SUCCESS_FETCH: return {
      ...state,
      amenities: [...action.amenities],
      amenitiesMoreLess: [...action.amenitiesMoreLess],
      amenitiesSelect: [...action.amenitiesSelect],
    };
    case ActionType.SET_EXPAND_WITH_AMENITIES_MORE_LESS: return {
      ...state,
      expanded: action.expanded,
      amenitiesMoreLess: [...action.amenitiesMoreLess],
    };

    default: return state;
  }
};

export const useLogic = (): {
  hotelAmenityFilterRoot: Array<number>,
  amenities: Array<Amenity>,
  amenitiesMoreLess: Array<Amenity>,
  amenitiesSelect: Array<boolean>,
  expanded: boolean,
  onAmenitySelected: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  onAmenitySelectionClear: () => void,
  onExpandCollapse: () => void,
} => {
  const dispatch = useDispatch();
  const [
    state, reducerDispatch,
  ] = useReducer<Reducer<State, Action>>(amenityFilterReducer, initialState);
  const {
    amenities, amenitiesMoreLess, amenitiesSelect, expanded,
  } = state;

  const hotelAmenityFilterRoot: Array<number> = useSelector(
    (store: RootState) => store.filters.amenity,
  );

  useEffect(() => {
    const cancelableAmenities: CancelablePromise = makePromiseCancelable(
      amenityRemote.getAmenitiesFilters().then((resp: ApiList<Amenity>) => {
        const { content } = resp;

        if (content) {
          const amenitiesChecked: Array<boolean> = getFilterSelection(
            hotelAmenityFilterRoot, content,
          );

          reducerDispatch({
            type: ActionType.SET_AMENITIES_SUCCESS_FETCH,
            amenities: content,
            amenitiesMoreLess: [...content.slice(0, INITIAL_FILTER_ITEMS)],
            amenitiesSelect: amenitiesChecked,
          });
        }
      }).catch(() => {
        reducerDispatch({
          type: ActionType.SET_AMENITIES_FAIL_FETCH,
          amenities: [],
          amenitiesSelect: [],
        });
      }),
    );
  
    // eslint-disable-next-line
    return () => {
      cancelableAmenities.cancel();
    };
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const amenitiesChecked: Array<boolean> = getFilterSelection(
      hotelAmenityFilterRoot, amenities,
    );

    reducerDispatch({ type: ActionType.SET_AMENITIES_SELECT, amenitiesSelect: amenitiesChecked });
  // eslint-disable-next-line
  }, [hotelAmenityFilterRoot]);

  const onExpandCollapse = (): void => {
    const isExpanded = !expanded;
    let amenitiesCopy = [...amenities];

    if (!isExpanded) {
      amenitiesCopy = [...amenities.slice(0, INITIAL_FILTER_ITEMS)];
    }

    reducerDispatch({
      type: ActionType.SET_EXPAND_WITH_AMENITIES_MORE_LESS,
      expanded: isExpanded,
      amenitiesMoreLess: amenitiesCopy,
    });
  };

  const onAmenitySelected = (
    event: ChangeEvent<HTMLInputElement>, checked: boolean,
  ): void => {
    if (!event) return;

    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const fallbackIfNull = '0-0';
    const amenityData: Array<string> = (eventTarget.getAttribute('data-amenityid-index') || fallbackIfNull).split('-');
    const amenityId = Number(amenityData[0]);
    const amenityIndex = Number(amenityData[1]);

    if (amenityId === 0) return;

    const amenitiesSelectCopy = [...amenitiesSelect];
    amenitiesSelectCopy[amenityIndex] = checked;

    dispatch(checked ? selectHotelAmenity(amenityId) : unSelectHotelAmenity(amenityId));
    reducerDispatch({
      type: ActionType.SET_AMENITIES_SELECT,
      amenitiesSelect: amenitiesSelectCopy,
    });
  };

  const onAmenitySelectionClear = (): void => {
    const initialAmenitiesChecked: Array<boolean> = Array.from(amenities, () => false);

    reducerDispatch({
      type: ActionType.SET_AMENITIES_SELECT,
      amenitiesSelect: initialAmenitiesChecked,
    });
    dispatch(clearAllSelectedHotelAmenities());
  };

  return {
    hotelAmenityFilterRoot,
    amenities,
    amenitiesMoreLess,
    amenitiesSelect,
    expanded,
    onAmenitySelected,
    onAmenitySelectionClear,
    onExpandCollapse,
  };
};
