import {
  ChangeEvent,
  Reducer,
  useEffect,
  useReducer,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { chainRemote } from '../../../../../../api/services';

import { ApiList, Chain } from '../../../../../../models';

import { clearAllSelectedHotelChains, selectHotelChain, unSelectHotelChain } from '../../../../../../store/actions/filtersAction';
import { RootState } from '../../../../../../store/reducers';

import { CancelablePromise, makePromiseCancelable } from '../../../../../../utils/makePromiseCancelable';

import { INITIAL_FILTER_ITEMS } from '../../../../../../configs/environments';

const getFilterSelection = (
  selectedChainsRoot: Array<number>,
  chains: Array<Chain>,
): Array<boolean> => {
  const chainsInitial: Array<boolean> = Array.from(chains, () => false);
  const selectedIndexes: Array<number> = selectedChainsRoot.map(
    (chainId: number) => chains.findIndex(
      (chain: Chain) => chain.chainId === chainId,
    ),
  );

  if (selectedChainsRoot.length === 0) {
    return chainsInitial;
  }
  
  const { length } = selectedIndexes;

  for (let i = 0; i < length; i += 1) {
    chainsInitial[selectedIndexes[i]] = true;
  }

  return chainsInitial;
};

enum ActionType {
  SET_CHAINS_FAIL_FETCH = 'SET_CHAINS_FAIL_FETCH',
  SET_CHAINS_SELECT = 'SET_CHAINS_SELECT',
  SET_CHAINS_SUCCESS_FETCH = 'SET_CHAINS_SUCCESS_FETCH',
  SET_EXPAND_WITH_CHAINS_MORE_LESS = 'SET_EXPAND_WITH_CHAINS_MORE_LESS',
}

interface State {
  chains: Array<Chain>,
  chainsMoreLess: Array<Chain>,
  chainsSelect: Array<boolean>,
  expanded: boolean,
}

const initialState: State = {
  chains: [],
  chainsMoreLess: [],
  chainsSelect: [],
  expanded: false,
};

type Action =
  // eslint-disable-next-line max-len
  | { type: ActionType.SET_CHAINS_FAIL_FETCH, chains: Array<Chain>, chainsSelect: Array<boolean> }
  | { type: ActionType.SET_CHAINS_SELECT, chainsSelect: Array<boolean> }
  // eslint-disable-next-line max-len
  | { type: ActionType.SET_CHAINS_SUCCESS_FETCH, chains: Array<Chain>, chainsMoreLess: Array<Chain>, chainsSelect: Array<boolean> }
  // eslint-disable-next-line max-len
  | { type: ActionType.SET_EXPAND_WITH_CHAINS_MORE_LESS, expanded: boolean, chainsMoreLess: Array<Chain> };

const chainFilterReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_CHAINS_FAIL_FETCH: return {
      ...state,
      chains: [...action.chains],
      chainsSelect: [...action.chainsSelect],
    };
    case ActionType.SET_CHAINS_SELECT: return {
      ...state,
      chainsSelect: [...action.chainsSelect],
    };
    case ActionType.SET_CHAINS_SUCCESS_FETCH: return {
      ...state,
      chains: [...action.chains],
      chainsMoreLess: [...action.chainsMoreLess],
      chainsSelect: [...action.chainsSelect],
    };
    case ActionType.SET_EXPAND_WITH_CHAINS_MORE_LESS: return {
      ...state,
      expanded: action.expanded,
      chainsMoreLess: [...action.chainsMoreLess],
    };

    default: return state;
  }
};

export const useLogic = (): {
  hotelChainFilterRoot: Array<number>,
  chains: Array<Chain>,
  chainsMoreLess: Array<Chain>,
  chainsSelect: Array<boolean>,
  expanded: boolean,
  onChainSelected: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  onChainSelectionClear: () => void,
  onExpandCollapse: () => void,
} => {
  const dispatch = useDispatch();
  const [
    state, reducerDispatch,
  ] = useReducer<Reducer<State, Action>>(chainFilterReducer, initialState);
  const {
    chains, chainsMoreLess, chainsSelect, expanded,
  } = state;

  const hotelChainFilterRoot: Array<number> = useSelector(
    (store: RootState) => store.filters.chain,
  );

  useEffect(() => {
    const cancelableChains: CancelablePromise = makePromiseCancelable(
      chainRemote.getChainsFilters().then((resp: ApiList<Chain>) => {
        const { content } = resp;

        if (content) {
          const chainsChecked: Array<boolean> = getFilterSelection(
            hotelChainFilterRoot, content,
          );

          reducerDispatch({
            type: ActionType.SET_CHAINS_SUCCESS_FETCH,
            chains: content,
            chainsMoreLess: [...content.slice(0, INITIAL_FILTER_ITEMS)],
            chainsSelect: chainsChecked,
          });
        }
      }).catch(() => {
        reducerDispatch({
          type: ActionType.SET_CHAINS_FAIL_FETCH,
          chains: [],
          chainsSelect: [],
        });
      }),
    );
  
    // eslint-disable-next-line
    return () => {
      cancelableChains.cancel();
    };
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const chainsChecked: Array<boolean> = getFilterSelection(
      hotelChainFilterRoot, chains,
    );

    reducerDispatch({ type: ActionType.SET_CHAINS_SELECT, chainsSelect: chainsChecked });
    // eslint-disable-next-line
  }, [hotelChainFilterRoot]);

  const onExpandCollapse = (): void => {
    const isExpanded = !expanded;
    let chainsCopy = [...chains];

    if (!isExpanded) {
      chainsCopy = [...chains.slice(0, INITIAL_FILTER_ITEMS)];
    }

    reducerDispatch({
      type: ActionType.SET_EXPAND_WITH_CHAINS_MORE_LESS,
      expanded: isExpanded,
      chainsMoreLess: chainsCopy,
    });
  };

  const onChainSelected = (
    event: ChangeEvent<HTMLInputElement>, checked: boolean,
  ): void => {
    if (!event) return;

    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const fallbackIfNull = '0-0';
    const chainData: Array<string> = (eventTarget.getAttribute('data-chainid-index') || fallbackIfNull).split('-');
    const chainId = Number(chainData[0]);
    const chainIndex = Number(chainData[1]);

    if (chainId === 0) return;

    const chainsSelectCopy = [...chainsSelect];
    chainsSelectCopy[chainIndex] = checked;

    dispatch(checked ? selectHotelChain(chainId) : unSelectHotelChain(chainId));
    reducerDispatch({ type: ActionType.SET_CHAINS_SELECT, chainsSelect: chainsSelectCopy });
  };

  const onChainSelectionClear = (): void => {
    const initialChainsChecked: Array<boolean> = Array.from(chains, () => false);

    reducerDispatch({ type: ActionType.SET_CHAINS_SELECT, chainsSelect: initialChainsChecked });
    dispatch(clearAllSelectedHotelChains());
  };

  return {
    hotelChainFilterRoot,
    chains,
    chainsMoreLess,
    chainsSelect,
    expanded,
    onChainSelected,
    onChainSelectionClear,
    onExpandCollapse,
  };
};
