import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import nextId from 'react-id-generator';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
} from '../../../common';

import FullScreenMapDialog from '../../../map/fullScreenMapDialog/FullScreenMapDialog';
import PropertyAddress from '../../../hotel/hotelDetailsPage/propertyAddress/PropertyAddress';

import { BookingReservation, PropertyDetails as PropertyDetailsModel } from '../../../../models';
import AgeQualifyingCode from '../../../../models/AgeQualifyingCode.enum';

import { useOpenMap } from '../../../../hooks/useOpenMap';

import { currencySign } from '../../../../utils/currencyMapper';
import { CURRENCY_HUNDRED_UNIT } from '../../../../configs/environments';

const useStyles = makeStyles(() => createStyles({
  card: {
    width: '100%',
    minWidth: 450,
    margin: '8px 0',
  },
  cardContent: {
    paddingTop: 9,
  },
  mapButtonContainer: {
    '& Button': {
      textTransform: 'none',
    },
    '& .MuiButton-text': {
      padding: '6px 0',
    },
  },
}));

const COMMON_BOX_MARGIN_TOP = 2;

export interface BookingConfirmationDetailsProps {
  property: PropertyDetailsModel;
  reservation: BookingReservation;
}

const BookingConfirmationDetails: FC<BookingConfirmationDetailsProps> = (
  props: BookingConfirmationDetailsProps,
) => {
  const { property, reservation } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { openMap, handleOpenMap, handleCloseMap } = useOpenMap();

  try {
    return (
      <>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box fontSize="h6.fontSize">{t('bookingConfirmationDetailsTitle')}</Box>
            <Box mt={COMMON_BOX_MARGIN_TOP}>
              <Box fontWeight="bold">{`${t('hotel')}:`}</Box>
              <Box>{property.property_name}</Box>
              <Box mt={COMMON_BOX_MARGIN_TOP} fontWeight="bold">{`${t('address')}:`}</Box>
              <PropertyAddress address={property.propertyAddress[0]} />
              <Box className={classes.mapButtonContainer}>
                <Button color="secondary" onClick={handleOpenMap}>{t('propertyDetailsViewOnMapButton')}</Button>
              </Box>
              <Box mt={COMMON_BOX_MARGIN_TOP}>
                <Box component="span" fontWeight="bold">{`${t('bookingReservationRoomsText')}: `}</Box>
                <Box component="span">{`${reservation.number_of_rooms}, `}</Box>
                <Box component="span" fontWeight="bold">{`${t('bookingReservationAdultsText')}: `}</Box>
                <Box component="span">{`${reservation.adults}, `}</Box>
                <Box component="span" fontWeight="bold">{`${t('bookingReservationChildrenText')}: `}</Box>
                <Box component="span">{reservation.children}</Box>
              </Box>
              <Box mt={COMMON_BOX_MARGIN_TOP}>
                { reservation?.people?.map((room, roomIndex: number) => (
                  <Box key={nextId('bdr-')}>
                    <Box mt={COMMON_BOX_MARGIN_TOP} fontWeight="bold">{`${t('roomCountLabel')} ${roomIndex + 1}:`}</Box>
                    <>
                      { room.map((guest, guestIndex: number) => (
                        <Box key={nextId('bdrg-')}>
                          { guest.age_qualifying_code === AgeQualifyingCode.ADULT ? (
                            <Box component="span">{`${t('bookingConfirmationAdultTitle')} ${guestIndex + 1}: `}</Box>
                          ) : (
                            <Box component="span">{`${t('bookingConfirmationChildTitle')} ${t('bookingConfirmationAge')} ${guest.age}: `}</Box>
                          )}
                          <Box component="span">{`${guest.person_name.name_prefix} ${guest.person_name.given_name} ${guest.person_name.surname}`}</Box>
                        </Box>
                      )) }
                    </>
                  </Box>
                ))}
              </Box>
              <Box mt={COMMON_BOX_MARGIN_TOP}>
                <Box component="span" fontWeight="bold">{`${t('bookingConfirmationRoomTypeTitle')}: `}</Box>
                <Box component="span">{reservation.room_type_description}</Box>
              </Box>
              <Box mt={COMMON_BOX_MARGIN_TOP}>
                <Box component="span" fontWeight="bold">{`${t('bookingConfirmationTotalPaidTitle')}: `}</Box>
                <Box component="span">{`${currencySign(reservation.currency)}${reservation.total / CURRENCY_HUNDRED_UNIT}, `}</Box>
                <Box component="span" fontWeight="bold">{`${t('bookingConfirmationCurrencyTitle')}: `}</Box>
                <Box component="span">{reservation.currency}</Box>
              </Box>
              <Box mt={COMMON_BOX_MARGIN_TOP}>
                <Box fontWeight="bold">{`${t('bookingConfirmationInclusionDescrTitle')}: `}</Box>
                <Box>{reservation.inclusion_description}</Box>
              </Box>
              <Box mt={COMMON_BOX_MARGIN_TOP}>
                <Box component="span" fontWeight="bold">{`${t('bookingConfirmationNoteTitle')}: `}</Box>
                <Box component="span" fontWeight="bold" fontStyle="italic">{t('bookingConfirmationNoteDescriptionText')}</Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
        { openMap && property.latitude && property.longitude
          && (
            <FullScreenMapDialog
              defaultCoordinates={{
                lat: property.latitude,
                lng: property.longitude,
              }}
              open={openMap}
              title={property.property_name}
              handleClose={handleCloseMap}
            />
          )}
      </>
    );
  } catch {
    return <Box />;
  }
};

const MemoizedBookingConfirmationDetails = React.memo(BookingConfirmationDetails);
export default MemoizedBookingConfirmationDetails;
