import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { userRemote } from '../../api/services';

import { loginFail } from '../../store/actions/loginAction';

import { LOGIN_ROUTE_PATH } from '../../routes/routesPath';

export const useSignOut = (): { onSignOutClicked: () => void } => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSignOutClicked = (): void => {
    userRemote.logout().then((resp: string) => {
      if (resp && typeof resp === 'string' && resp === 'Logged out') {
        dispatch(loginFail());
        history.push(LOGIN_ROUTE_PATH);
      }
    });
  };

  return { onSignOutClicked };
};
