import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import nextId from 'react-id-generator';

import {
  Breadcrumbs,
  Card,
  CardContent,
  Link,
  Typography,
} from '../../common';

import { HOME_ROUTE_PATH } from '../../../routes/routesPath';

import useStyles from './hotelBreadcrumbContainerStyles';

interface HotelBreadcrumbProps {
  breadcrumbsItems: Array<string>;
}

const HotelBreadcrumbContainer: FC<HotelBreadcrumbProps> = (props: HotelBreadcrumbProps) => {
  const { breadcrumbsItems } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardContent>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="inherit" href={HOME_ROUTE_PATH}>
            {t('breadcrumbsHomeLinkText')}
          </Link>
          { breadcrumbsItems.map(
            (text: string) => <Typography key={nextId('brcr-')} color="textPrimary">{text}</Typography>,
          )}
        </Breadcrumbs>
      </CardContent>
    </Card>
  );
};

const MemoizedBreadcrumbContainer = React.memo(HotelBreadcrumbContainer);
export default MemoizedBreadcrumbContainer;
