import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    cardRoot: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      minHeight: 90,
      '& .MuiCardContent-root': {
        paddingTop: 20,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
    },
    logoContainer: {
      flex: 0.4,
    },
    brandImage: {
      width: 118,
      height: 50,
    },
    titleContainer: {
      flex: 2,
    },
  },
));
