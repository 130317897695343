import { SET_SELECTED_CHECK_IN_CHECK_OUT } from '../actions/actionTypes';

import { CheckInCheckOut } from '../../models';

const initialState: CheckInCheckOut = { checkIn: null, checkOut: null };

export default (
  state: CheckInCheckOut = initialState, action: { type: string; payload: CheckInCheckOut },
): CheckInCheckOut => {
  if (action.type === SET_SELECTED_CHECK_IN_CHECK_OUT) {
    return { ...action.payload };
  }

  return state;
};
