import React, { FC } from 'react';

import BookingDetailsRoom from '../room/BookingDetailsRoom';

import { BookingRoomHolder, BookingRoomHolderErrors } from '../../../models';

interface BookingDetailsRoomProps {
  roomHolders: Array<Array<BookingRoomHolder>>;
  roomHoldersErrors: Array<Array<BookingRoomHolderErrors>>;
  onChange: (
    roomHolders: Array<Array<BookingRoomHolder>>,
    roomHoldersErrors: Array<Array<BookingRoomHolderErrors>>,
    arrIndex: number,
  ) => void;
}

const BookingDetailsRooms: FC<BookingDetailsRoomProps> = (props: BookingDetailsRoomProps) => {
  const { roomHolders, roomHoldersErrors, onChange } = props;

  const handleChange = (
    currRoomHolders: Array<BookingRoomHolder>,
    currRoomHoldersErrors: Array<BookingRoomHolderErrors>,
    index: number,
  ): void => {
    onChange(
      roomHolders.splice(index, 1, currRoomHolders),
      roomHoldersErrors.splice(index, 1, currRoomHoldersErrors),
      index,
    );
  };

  return (
    <>
      { roomHolders.map((room: Array<BookingRoomHolder>, roomIndex: number) => (
        <BookingDetailsRoom
          key={room[0].uniqueGroupId}
          index={roomIndex}
          room={room}
          roomErrors={roomHoldersErrors[roomIndex]}
          onChange={handleChange}
        />
      )) }
    </>
  );
};

const MemoizedBookingDetailsRooms = React.memo(BookingDetailsRooms);
export default MemoizedBookingDetailsRooms;
