import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    react_autosuggest__container: {
      width: '100%',
    },
    react_autosuggest__suggestions_container__open: {
      position: 'absolute',
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: '0px 0px 12px #888888',
      borderRadius: 4,
      zIndex: 2,
    },
    react_autosuggest__suggestions_list: {
      margin: '0 0 0 -5px',
      padding: 0,
      listStyleType: 'none',
    },
    react_autosuggest__suggestion__highlighted: {
      color: '#fff',
      borderRadius: 4,
      backgroundColor: theme.palette.primary.main,
      marginLeft: 4,
    },
  },
));
