import genericApi from '../genericApi';

import { ApiList, Chain } from '../../models';

import { PORTAL_BE_ROUTE_PREFIX, REQUESTED_CHAIN_SIZE } from '../../configs/environments';

const chainRemote = {

  getChainsFilters: (): Promise<ApiList<Chain>> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/hotel-chains/master?size=${REQUESTED_CHAIN_SIZE}`),
};

export default chainRemote;
