import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Add as AddIcon, Remove as SubtractIcon } from '@material-ui/icons';
import { Box, IconButton, Tooltip } from '..';

import NumberSelectorType from '../../../models/NumberSelectorType.enum';

import useStyles from './numberSelectorStyles';
import configs from '../../../configs/environments';

interface NumberSelectorProps {
  max: number;
  min: number;
  type: string;
  value: number;
  roomIndex: number;
  onChange: (newValue: number) => void;
}

const STEP = configs.room.step;

const NumberSelector: FC<NumberSelectorProps> = (props: NumberSelectorProps) => {
  const {
    max,
    min,
    type,
    value,
    roomIndex,
    onChange,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const decreaseDisabled = (): boolean => (
    value - STEP < min
  );

  const increaseDisabled = (): boolean => (
    value + STEP > max
  );

  const decreaseTooltipText = (): string => (
    type === NumberSelectorType.ADULTS
      ? t('roomDecreaseTooltipText', { type: NumberSelectorType.ADULTS, roomIndex })
      : t('roomDecreaseTooltipText', { type: NumberSelectorType.CHILDREN, roomIndex })
  );

  const increaseTooltipText = (): string => (
    type === NumberSelectorType.ADULTS
      ? t('roomIncreaseTooltipText', { type: NumberSelectorType.ADULTS, roomIndex })
      : t('roomIncreaseTooltipText', { type: NumberSelectorType.CHILDREN, roomIndex })
  );
  
  return (
    <Box className={classes.content}>
      <Box>
        <Tooltip title={decreaseTooltipText()} aria-label="decrease">
          <Box>
            <IconButton
              disabled={decreaseDisabled()}
              aria-label="decrease"
              size="small"
              className={classes.iconButton}
              onClick={() => onChange(-STEP)}
            >
              <SubtractIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <Box className={classes.valueContainer}>
        <Box className={classes.value}>{value}</Box>
      </Box>
      <Box>
        <Tooltip title={increaseTooltipText()} aria-label="increase">
          <Box>
            <IconButton
              disabled={increaseDisabled()}
              aria-label="increase"
              size="small"
              className={classes.iconButton}
              onClick={() => onChange(STEP)}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default NumberSelector;
