import { STORE_AVAILABILITY_SEARCH_PARAMS } from '../actions/actionTypes';

import { AvailabilitySearchParams } from '../../models';

import { DEFAULT_FALLBACK_CURRENCY } from '../../configs/environments';

const initialState: AvailabilitySearchParams = {
  currency: DEFAULT_FALLBACK_CURRENCY,
  date_range: { check_in: '', check_out: '' },
  room_candidates: [],
  search_criteria: {
    hotel_location_ref: {
      destination_type: '',
      location_id: '',
    },
  },
};

export default (
  state: AvailabilitySearchParams = initialState,
  action: { type: string; payload: AvailabilitySearchParams },
): AvailabilitySearchParams => {
  if (action.type === STORE_AVAILABILITY_SEARCH_PARAMS) {
    return { ...action.payload };
  }

  return state;
};
