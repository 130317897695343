import React, { FC, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Box } from '../common';

import PopularLocationContext from '../../contexts/PopularLocationContext';

import BookingConfirmation from '../booking/confirmation/BookingConfirmation';
import BookingDetailsPage from '../booking/details/BookingDetailsPage';
import Footer from './layout/footer/Footer';
import Header from './layout/header/Header';
import HotelPage from './hotelDetailsPage/HotelDetailsPage';
import HotelsPage from './hotelsPage/HotelsPage';

import { PointOfInterest } from '../../models';

import {
  BOOKING_CONFIRMATION_ROUTE_PATH,
  BOOKING_ROUTE_PATH,
  HOME_ROUTE_PATH,
  HOTEL_ROUTE_PATH,
  HOTELS_ROUTE_PATH,
} from '../../routes/routesPath';

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    background: '#eceeee',
  },
  contentContainer: {
    [theme.breakpoints.between('sm', 'md')]: {
      marginLeft: '2%',
      marginRight: '2%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      marginLeft: '4%',
      marginRight: '4%',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      marginLeft: '6%',
      marginRight: '6%',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '15%',
      marginRight: '15%',
    },
  },
}));

const HotelContainer: FC<unknown> = () => {
  const classes = useStyles();
  const [year] = useState<number>(new Date().getFullYear());
  const [locationContext, setLocationContext] = useState<PointOfInterest | null>(null);
  
  const memoizedLocationValue = useMemo(
    () => ({ locationContext, setLocationContext }), [locationContext, setLocationContext],
  );

  return (
    <Box className={classes.content}>
      <Box className={classes.contentContainer}>
        <PopularLocationContext.Provider value={memoizedLocationValue}>
          <Header />
          <Switch>
            <Route path={`${HOTEL_ROUTE_PATH}/:propertyId${BOOKING_ROUTE_PATH}/:reservationId${BOOKING_CONFIRMATION_ROUTE_PATH}`} component={BookingConfirmation} />
            <Route path={`${HOTEL_ROUTE_PATH}/:propertyId${BOOKING_ROUTE_PATH}`} component={BookingDetailsPage} />
            <Route path={HOTEL_ROUTE_PATH} component={HotelPage} />
            <Route path={HOTELS_ROUTE_PATH} component={HotelsPage} />
            <Redirect to={HOME_ROUTE_PATH} />
          </Switch>
          <Footer year={year} />
        </PopularLocationContext.Provider>
      </Box>
    </Box>
  );
};

export default HotelContainer;
