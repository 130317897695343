import { STORE_AVAILABILITY_NEIGHBORHOOD } from '../actions/actionTypes';

const initialState: Array<number> = [];

export default (
  state: Array<number> = initialState, action: { type: string; payload: Array<number> },
): Array<number> => {
  if (action.type === STORE_AVAILABILITY_NEIGHBORHOOD) {
    return action.payload;
  }

  return state;
};
