import React, { FC } from 'react';
import { ReactImageGalleryItem } from 'react-image-gallery';
import nextId from 'react-id-generator';

import { Box } from '../../../common';

import PropertyRoom from './propertyRoom/PropertyRoom';

import { Amenity, Availability, AvailabilityProduct } from '../../../../models';

interface PropertyRoomsProps {
  amenities: Array<Amenity>;
  guests: { rooms: number; adults: number; children: number; };
  images: Array<ReactImageGalleryItem> | null;
  propertyAvailability: Array<Availability>;
  propertyId: number;
}

const PropertyRooms: FC<PropertyRoomsProps> = (props: PropertyRoomsProps) => {
  const {
    amenities,
    guests,
    images,
    propertyAvailability,
    propertyId,
  } = props;

  const imagesLength = Array.isArray(images) ? images.length : 0;
  const hasMoreThanMainImage = imagesLength > 1;

  try {
    return (
      <Box>
        { propertyAvailability[0].products.map((product: AvailabilityProduct, index: number) => (
          <PropertyRoom
            key={nextId('room-')}
            amenities={amenities}
            guests={guests}
            imageIndex={hasMoreThanMainImage ? index % imagesLength : 0}
            images={images}
            product={product}
            propertyId={propertyId}
          />
        ))}
      </Box>
    );
  } catch {
    return <Box />;
  }
};

const MemoizedPropertyRooms = React.memo(PropertyRooms);
export default MemoizedPropertyRooms;
