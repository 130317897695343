export default interface RadiusSelection {
  selected: {
    value: number | string;
    unit: string;
  };
  opposite: {
    value: number | string;
    unit: string;
  };
}

/**
 * DEFAULT_FALLBACK_UNIT = 'IMPERIAL'
 * so the selected unit must be 'mi'
 */
export const defaultRadiusSelected: RadiusSelection = {
  opposite: { unit: 'km', value: 'None' },
  selected: { unit: 'mi', value: 'None' },
};
