import { SET_HOTELS_LIST_PAGE } from '../actions/actionTypes';

const initialState = 0;

export default (
  state: number = initialState, action: { type: string, payload: number },
): number => {
  if (action.type === SET_HOTELS_LIST_PAGE) {
    return action.payload;
  }

  return state;
};
