import { SET_HOTELS_HEADER_SEARCHED_TITLE } from '../actions/actionTypes';

interface HeaderTitleState {
  title: string;
}

const initialState: HeaderTitleState = { title: '' };

export default (
  state: HeaderTitleState = initialState, action: { type: string; payload: string },
): HeaderTitleState => {
  if (action.type === SET_HOTELS_HEADER_SEARCHED_TITLE) {
    return {
      ...state,
      title: action.payload,
    };
  }

  return state;
};
