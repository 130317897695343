import {
  ChangeEvent,
  Reducer,
  useEffect,
  useReducer,
} from 'react';

import { bookingRemote } from '../../../../../api/services';

import { BookingReservation } from '../../../../../models';
import ApiList, { getEmptyApiList } from '../../../../../models/ApiList';
import ReservationApiStatus from '../../../../../models/ReservationApiStatus.enum';

const INITIAL_TAB_INDEX = 0;
const tabIndexToStatusMapping: { [key: number]: ReservationApiStatus } = {
  0: ReservationApiStatus.ACTIVE,
  1: ReservationApiStatus.FAILED,
  2: ReservationApiStatus.CANCELLED,
};

enum ActionType {
  FETCHING = 'FETCHING',
  SET_RESERVATIONS = 'SET_RESERVATIONS',
  SET_TAB = 'SET_TAB',
  SET_PAGE = 'SET_PAGE',
}

interface State {
  isFetching: boolean;
  page: number;
  reservations: ApiList<BookingReservation>;
  tabIndex: number;
}

const initialState: State = {
  isFetching: false,
  page: 0,
  reservations: getEmptyApiList(),
  tabIndex: INITIAL_TAB_INDEX,
};

type Action =
  | { type: ActionType.FETCHING }
  | { type: ActionType.SET_RESERVATIONS, reservations: ApiList<BookingReservation> }
  | { type: ActionType.SET_TAB, tabIndex: number }
  | { type: ActionType.SET_PAGE, page: number };

const bookingReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.FETCHING: return {
      ...state,
      isFetching: true,
    };
    case ActionType.SET_RESERVATIONS: return {
      ...state,
      isFetching: false,
      reservations: { ...action.reservations },
    };
    case ActionType.SET_TAB: return {
      ...state,
      page: 0,
      tabIndex: action.tabIndex,
    };
    case ActionType.SET_PAGE: return {
      ...state,
      page: action.page,
    };
    default: return state;
  }
};

export const useLogic = (): {
  isFetching: boolean;
  page: number;
  reservations: ApiList<BookingReservation>,
  tabIndex: number,
  handleTabChange: (event: ChangeEvent<unknown>, newValue: number) => void,
  onPageChange: (selectedItem: { selected: number; }) => void;
} => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(bookingReducer, initialState);
  const {
    isFetching,
    page,
    reservations,
    tabIndex,
  } = state;

  // eslint-disable-next-line
  const handleTabChange = (event: ChangeEvent<unknown>, newValue: number) => {
    dispatch({ type: ActionType.SET_TAB, tabIndex: newValue });
  };

  useEffect(() => {
    let reservationsToSave: ApiList<BookingReservation> = getEmptyApiList();
    dispatch({ type: ActionType.FETCHING });

    bookingRemote.getReservationsByStatus(page, tabIndexToStatusMapping[tabIndex])
      .then((resp: ApiList<BookingReservation>) => {
        const { content } = resp;

        if (content) {
          reservationsToSave = resp;
        }

        dispatch({ type: ActionType.SET_RESERVATIONS, reservations: reservationsToSave });
      }).catch(() => {
        dispatch({ type: ActionType.SET_RESERVATIONS, reservations: getEmptyApiList() });
      });
  }, [page, tabIndex]);

  const onPageChange = (selectedItem: { selected: number; }): void => {
    dispatch({ type: ActionType.SET_PAGE, page: selectedItem.selected });
  };

  return {
    isFetching,
    page,
    reservations,
    tabIndex,
    handleTabChange,
    onPageChange,
  };
};
