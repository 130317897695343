import { combineReducers } from 'redux';

import filtersReducer from './filtersReducer';
import bookingReducer from './bookingReducer';
import checkInCheckOutReducer from './checkInCheckOutReducer';
import fetchingReducer from './fetchingReducer';
import hotelsCoordinatesReducer from './hotelsCoordinatesReducer';
import hotelsHeaderReducer from './hotelsHeaderReducer';
import hotelsNeighborhoodReducer from './hotelsNeighborhoodReducer';
import hotelsPageReducer from './hotelsPageReducer';
import hotelsReducer from './hotelsReducer';
import locationReducer from './locationReducer';
import loginReducer from './loginReducer';
import neighborhoodReducer from './neighborhoodReducer';
import popularLocationsReducer from './popularLocationsReducer';
import propertyReducer from './propertyReducer';
import radiusReducer from './radiusReducer';
import roomsReducer from './roomsReducer';
import searchButtonReducer from './searchButtonReducer';
import searchParamsReducer from './searchParamsReducer';
import sortByReducer from './sortByReducer';

const rootReducer = combineReducers({
  avNeighborhoods: neighborhoodReducer,
  filters: filtersReducer,
  booking: bookingReducer,
  checkInCheckOut: checkInCheckOutReducer,
  fetching: fetchingReducer,
  hotels: hotelsReducer,
  hotelsCoordinates: hotelsCoordinatesReducer,
  hotelsHeader: hotelsHeaderReducer,
  hotelsNeighborhoods: hotelsNeighborhoodReducer,
  hotelsPage: hotelsPageReducer,
  location: locationReducer,
  login: loginReducer,
  property: propertyReducer,
  radius: radiusReducer,
  rooms: roomsReducer,
  searchButton: searchButtonReducer,
  availabilityParams: searchParamsReducer,
  sortBy: sortByReducer,
  popularLocations: popularLocationsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
