import React, { FC } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Box } from '../../../common';

import AmenityIcon from '../../../common/amenityIcon/AmenityIcon';

import { Amenity } from '../../../../models';

const useStyles = makeStyles(() => createStyles({
  amenitiesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  amenityContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '25%',
    marginBottom: '15px',
  },
  amenityTextContainer: {
    marginTop: -2,
    marginLeft: 5,
    display: 'flex',
    alignItems: 'center',
  },
}));

interface PropertyAmenitiesProps {
  amenities: Array<Amenity>;
}

const PropertyAmenities: FC<PropertyAmenitiesProps> = (props: PropertyAmenitiesProps) => {
  const { amenities } = props;
  const classes = useStyles();

  try {
    return (
      <Box className={classes.amenitiesContainer}>
        { amenities.map((amenity: Amenity) => (
          <Box key={amenity.amenityId} className={classes.amenityContainer}>
            <AmenityIcon factId={amenity.amenityId} />
            <Box className={classes.amenityTextContainer}>{amenity.amenityName}</Box>
          </Box>
        ))}
      </Box>
    );
  } catch {
    return <Box className={classes.amenitiesContainer} />;
  }
};

const MemoizedPropertyAmenities = React.memo(PropertyAmenities);
export default MemoizedPropertyAmenities;
