import { useContext } from 'react';
import cloneDeep from 'lodash/clone';

import PopularLocationContext, { PopularLocationContextModel } from '../../../../../contexts/PopularLocationContext';

import {
  AvailabilityLight,
} from '../../../../../models';

export const useLocationAsFakeAvailability = (openMap: boolean, hotels: Array<AvailabilityLight>): {
  hotelsWithPopularLocation: Array<AvailabilityLight>,
} => {
  const { locationContext } = useContext<PopularLocationContextModel>(PopularLocationContext);

  let hotelsWithPopularLocation: Array<AvailabilityLight> = hotels;

  if (openMap && locationContext) {
    hotelsWithPopularLocation = cloneDeep(hotels);

    const POI: AvailabilityLight = {
      coordinates: {
        latitude: locationContext.latitude,
        longitude: locationContext.longitude,
      },
      fake: true,
      property_info: {
        name: locationContext.name,
        property_id: -1,
      },
    };

    hotelsWithPopularLocation.push(POI);
  }

  return {
    hotelsWithPopularLocation,
  };
};
