import {
  CLEAR_ALL_SELECTED,
  CLEAR_ALL_SELECTED_HOTEL_AMENITIES,
  CLEAR_ALL_SELECTED_HOTEL_CHAINS,
  CLEAR_ALL_SELECTED_HOTEL_NEIGHBORHOODS,
  CLEAR_ALL_SELECTED_HOTEL_RATINGS,
  STORE_HOTEL_AMENITY,
  STORE_HOTEL_CHAIN,
  STORE_HOTEL_NEIGHBORHOOD,
  STORE_HOTEL_RATING,
  STORE_POPULAR_LOCATION,
  REMOVE_HOTEL_AMENITY,
  REMOVE_HOTEL_CHAIN,
  REMOVE_HOTEL_NEIGHBORHOOD,
  REMOVE_HOTEL_RATING,
  REMOVE_POPULAR_LOCATION,
} from './actionTypes';

const clearAllSelected = (): { type: string } => (
  {
    type: CLEAR_ALL_SELECTED,
  }
);

const clearAllSelectedHotelAmenities = (): { type: string } => (
  {
    type: CLEAR_ALL_SELECTED_HOTEL_AMENITIES,
  }
);

const clearAllSelectedHotelChains = (): { type: string } => (
  {
    type: CLEAR_ALL_SELECTED_HOTEL_CHAINS,
  }
);

const clearAllSelectedHotelNeighborhoods = (): { type: string } => (
  {
    type: CLEAR_ALL_SELECTED_HOTEL_NEIGHBORHOODS,
  }
);

const clearAllSelectedHotelRatings = (): { type: string } => (
  {
    type: CLEAR_ALL_SELECTED_HOTEL_RATINGS,
  }
);

const selectHotelAmenity = (data: number): { type: string, payload: number } => (
  {
    type: STORE_HOTEL_AMENITY,
    payload: data,
  }
);

const unSelectHotelAmenity = (data: number): { type: string, payload: number } => (
  {
    type: REMOVE_HOTEL_AMENITY,
    payload: data,
  }
);

const selectHotelChain = (data: number): { type: string, payload: number } => (
  {
    type: STORE_HOTEL_CHAIN,
    payload: data,
  }
);

const unSelectHotelChain = (data: number): { type: string, payload: number } => (
  {
    type: REMOVE_HOTEL_CHAIN,
    payload: data,
  }
);

const selectHotelNeighborhood = (data: number): { type: string, payload: number } => (
  {
    type: STORE_HOTEL_NEIGHBORHOOD,
    payload: data,
  }
);

const unSelectHotelNeighborhood = (data: number): { type: string, payload: number } => (
  {
    type: REMOVE_HOTEL_NEIGHBORHOOD,
    payload: data,
  }
);

const selectHotelRating = (data: number): { type: string, payload: number } => (
  {
    type: STORE_HOTEL_RATING,
    payload: data,
  }
);

const unSelectHotelRating = (data: number): { type: string, payload: number } => (
  {
    type: REMOVE_HOTEL_RATING,
    payload: data,
  }
);

const selectPopularLocation = (data: string): { type: string, payload: string } => (
  {
    type: STORE_POPULAR_LOCATION,
    payload: data,
  }
);

const unSelectPopularLocation = (): { type: string } => (
  {
    type: REMOVE_POPULAR_LOCATION,
  }
);

export {
  clearAllSelected,
  clearAllSelectedHotelAmenities,
  clearAllSelectedHotelChains,
  clearAllSelectedHotelNeighborhoods,
  clearAllSelectedHotelRatings,
  selectHotelAmenity,
  selectHotelChain,
  selectHotelNeighborhood,
  selectHotelRating,
  selectPopularLocation,
  unSelectHotelAmenity,
  unSelectHotelChain,
  unSelectHotelNeighborhood,
  unSelectHotelRating,
  unSelectPopularLocation,
};
