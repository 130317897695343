import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Box } from '../../../common';

import ChildAge from '../childAge/ChildAge';

const useStyles = makeStyles(() => createStyles({
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

interface ChildAgeSelectionProps {
  ages: Array<number>;
  onSelect: (selectedAge: number, ageIndex: number) => void;
}

const ChildAgeSelection: FC<ChildAgeSelectionProps> = (props: ChildAgeSelectionProps) => {
  const { ages, onSelect } = props;
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      { ages.map((age: number, index: number) => (
        <ChildAge
          // eslint-disable-next-line
          key={index}
          age={age}
          index={index}
          onSelect={(selectedAge: number) => onSelect(selectedAge, index)}
        />
      ))}
    </Box>
  );
};

export default ChildAgeSelection;
