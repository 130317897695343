import {
  MutableRefObject, Reducer, useEffect, useReducer, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { receiveHotels } from '../../../../../store/actions/hotelsAction';
import { setHotelsHeaderAction } from '../../../../../store/actions/hotelsHeaderAction';
import { hotelsCurrentPage } from '../../../../../store/actions/hotelsPageAction';
import { sethHotelsNeighborhoods } from '../../../../../store/actions/hotelsNeighborhoodAction';
import { storeAvailabilitySearchParams } from '../../../../../store/actions/searchParamsAction';
import { setPopularLocations } from '../../../../../store/actions/popularLocationsAction';
import { clearAllSelectedHotelNeighborhoods, unSelectPopularLocation } from '../../../../../store/actions/filtersAction';

import {
  ApiList,
  Availability,
  AvailabilitySearchCriteriaIdsParams,
  AvailabilitySearchParams,
  CheckInCheckOut as CheckInCheckOutModel,
  FiltersStoreData,
  Location,
  Neighborhood,
  PointOfInterest,
  RadiusSelection,
  Room as RoomModel,
} from '../../../../../models';
import { AvailabilitySearchPropertyFilter } from '../../../../../models/AvailabilitySearchParams';

import { locationRemote, pointsOfInterestRemote } from '../../../../../api/services';

import { RootState } from '../../../../../store/reducers';

import { CancelablePromise, makePromiseCancelable } from '../../../../../utils/makePromiseCancelable';

import { constructAvailabilityRequestParams } from '../../../../../utils/availabilitySearchParams';
import { DEFAULT_FALLBACK_CURRENCY } from '../../../../../configs/environments';

enum ActionType {
  SET_BREADCRUMBS = 'SET_BREADCRUMBS',
  SET_HOTELS = 'SET_HOTELS',
  SET_LAST_LOCATION_ID = 'SET_LAST_LOCATION_ID',
  SET_LAST_SORT_BY = 'SET_LAST_SORT_BY',
  SET_PAGE = 'SET_PAGE',
  SET_PAGE_AND_LAST_SORT_BY = 'SET_PAGE_AND_LAST_SORT_BY',
}

interface State {
  breadcrumbs: Array<string>;
  hotels: ApiList<Availability>;
  page: number;
  lastLocationId: string | null;
  lastSortBy: string;
}

const initialState: State = {
  breadcrumbs: [],
  hotels: {} as ApiList<Availability>,
  page: 0,
  lastLocationId: null,
  lastSortBy: '',
};

type Action =
  | { type: ActionType.SET_BREADCRUMBS, breadcrumbs: Array<string> }
  | { type: ActionType.SET_HOTELS, hotels: ApiList<Availability> }
  | { type: ActionType.SET_LAST_LOCATION_ID, lastLocationId: string | null }
  | { type: ActionType.SET_LAST_SORT_BY, lastSortBy: string }
  | { type: ActionType.SET_PAGE, page: number }
  | { type: ActionType.SET_PAGE_AND_LAST_SORT_BY, page: number, lastSortBy: string };

const hotelsPageReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_BREADCRUMBS: return {
      ...state,
      breadcrumbs: [...action.breadcrumbs],
    };
    case ActionType.SET_HOTELS: return {
      ...state,
      hotels: action.hotels,
    };
    case ActionType.SET_LAST_LOCATION_ID: return {
      ...state,
      lastLocationId: action.lastLocationId,
    };
    case ActionType.SET_LAST_SORT_BY: return {
      ...state,
      lastSortBy: action.lastSortBy,
    };
    case ActionType.SET_PAGE: return {
      ...state,
      page: action.page,
    };
    case ActionType.SET_PAGE_AND_LAST_SORT_BY: return {
      ...state,
      lastSortBy: action.lastSortBy,
      page: action.page,
    };

    default: return state;
  }
};

const LOCATION_CITY_ID_PREFIX = 'C-';

export const useLogic = (): {
  breadcrumbs: Array<string>,
  isFetching: boolean,
  isFetchMade: MutableRefObject<boolean>,
  hotels: ApiList<Availability>,
  onPageChange: (selectedItem: { selected: number; }) => void;
} => {
  const dispatch = useDispatch();
  const isFetchMade = useRef<boolean>(false);

  const availabilityNeighborhoodsRoot: Array<number> = useSelector(
    (store: RootState) => store.avNeighborhoods,
  );
  const dateRangesRoot: CheckInCheckOutModel = useSelector(
    (store: RootState) => store.checkInCheckOut,
  );
  const hotelFiltersRoot: FiltersStoreData = useSelector(
    (store: RootState) => store.filters,
  );
  const hotelsPageRoot: number = useSelector((store: RootState) => store.hotelsPage);
  const hotelsRoot: ApiList<Availability> = useSelector(
    (store: RootState) => store.hotels.items,
  );
  const hotelsSortByRoot: string = useSelector((store: RootState) => store.sortBy.list);
  const isFetching: boolean = useSelector((store: RootState) => store.fetching.hotels);
  const locationRoot: Location = useSelector((store: RootState) => store.location);
  const radiusRoot: RadiusSelection = useSelector((store: RootState) => store.radius);
  const roomsRoot: Array<RoomModel> = useSelector((store: RootState) => store.rooms);
  const searchParamsRoot: AvailabilitySearchParams = useSelector(
    (store: RootState) => store.availabilityParams,
  );
  // eslint-disable-next-line max-len
  const userCurrencyRoot: string = useSelector(
    (store: RootState) => store.login.currency?.name || DEFAULT_FALLBACK_CURRENCY,
  );

  const hydrateInitialState = (): State => ({
    breadcrumbs: [],
    hotels: hotelsRoot,
    page: hotelsPageRoot,
    lastLocationId: null,
    lastSortBy: hotelsSortByRoot,
  });

  const [state, reducerDispatch] = useReducer<Reducer<State, Action>, State>(
    hotelsPageReducer, initialState, hydrateInitialState,
  );
  const {
    breadcrumbs, hotels, page, lastLocationId, lastSortBy,
  } = state;

  let cancelablePOI: CancelablePromise;

  const doFetchPopularLocations = (locationId: string): void => {
    if (locationId === 'null' && !locationId.startsWith(LOCATION_CITY_ID_PREFIX)) return;

    const locationIdWithoutPrefix: string = locationId.substring(LOCATION_CITY_ID_PREFIX.length);

    cancelablePOI = makePromiseCancelable(
      pointsOfInterestRemote.getFilterPoints(Number(locationIdWithoutPrefix))
        .then((resp: Array<PointOfInterest>) => {
          if (resp) {
            dispatch(setPopularLocations(resp));
          }
        }).catch(() => dispatch(setPopularLocations([]))),
    );
  };

  let cancelableNeighborhoods: CancelablePromise;

  const doFetchHotelsNeighborhoods = (neighborhoodIDs: Array<number>): void => {
    if (neighborhoodIDs.length === 0) {
      dispatch(sethHotelsNeighborhoods([]));
      return;
    }

    cancelableNeighborhoods = makePromiseCancelable(
      locationRemote.getNeighborhoodsById(neighborhoodIDs.join(','))
        .then((resp: Array<Neighborhood>) => {
          let neighborhoodsIds: Array<Neighborhood> = [];

          if (resp) {
            neighborhoodsIds = resp;
          }

          dispatch(sethHotelsNeighborhoods(neighborhoodsIds));
        }).catch(() => dispatch(sethHotelsNeighborhoods([]))),
    );
  };
  useEffect(() => {
    const currentSearchId: string | null = sessionStorage.getItem('cid');
    const prevSearchId: string | null = sessionStorage.getItem('pid');

    if (locationRoot.id !== 'null') {
      reducerDispatch({
        type: ActionType.SET_BREADCRUMBS,
        breadcrumbs: [
          locationRoot.country_name,
          locationRoot.destination_name,
          locationRoot.location_name,
        ],
      });

      if ((dateRangesRoot.checkIn && dateRangesRoot.checkOut) && currentSearchId !== prevSearchId) {
        const firstPage = 0;
        const sortOptions: Array<string> = [hotelsSortByRoot];
        let params: AvailabilitySearchParams = constructAvailabilityRequestParams(
          userCurrencyRoot,
          dateRangesRoot,
          roomsRoot,
          locationRoot,
          sortOptions,
          hotelFiltersRoot,
          radiusRoot,
        );

        /**
         * This IF statement: first copy `availabilityParams`, then sets `poi_id` to null,
         * make request with new params and finally update `availabilityParams` in Redux.
         * Because due the asynchronous actions, can't wait to update Redux and then get new params.
         */
        if (lastLocationId && locationRoot.id !== lastLocationId) {
          dispatch(unSelectPopularLocation());
          dispatch(clearAllSelectedHotelNeighborhoods());

          const searchCriteriaIdsParams: AvailabilitySearchCriteriaIdsParams = {
            // cast is needed for the typescript to compile
            ...params.search_criteria as AvailabilitySearchCriteriaIdsParams,
          };

          if (searchCriteriaIdsParams.property_filter) { // needed for the typescript to compile
            const filterParams: AvailabilitySearchPropertyFilter = {
              ...searchCriteriaIdsParams.property_filter,
              amenity_ids: [...searchCriteriaIdsParams.property_filter.amenity_ids],
              chain_ids: [...searchCriteriaIdsParams.property_filter.chain_ids],
              neighborhood_ids: [],
              poi_id: null,
              ratings: [...searchCriteriaIdsParams.property_filter.ratings],
              sort_criteria: [...searchCriteriaIdsParams.property_filter.sort_criteria],
            };

            params = {
              ...params,
              search_criteria: {
                ...params.search_criteria,
                property_filter: filterParams,
              },
            };
          }
        }

        reducerDispatch({
          type: ActionType.SET_PAGE_AND_LAST_SORT_BY,
          lastSortBy: hotelsSortByRoot,
          page: firstPage,
        });
        dispatch(receiveHotels(firstPage, params));
        dispatch(hotelsCurrentPage(firstPage));
        dispatch(storeAvailabilitySearchParams(params));
        isFetchMade.current = true;
      }
    }

    dispatch(setHotelsHeaderAction());
  // eslint-disable-next-line
  }, [dateRangesRoot, roomsRoot, locationRoot, dispatch]);

  useEffect(() => {
    reducerDispatch({ type: ActionType.SET_HOTELS, hotels: hotelsRoot });
  }, [hotelsRoot]);

  useEffect(() => {
    if (locationRoot.id !== lastLocationId) {
      reducerDispatch({ type: ActionType.SET_LAST_LOCATION_ID, lastLocationId: locationRoot.id });
      doFetchPopularLocations(locationRoot.id);
    }
    // eslint-disable-next-line
    return () => {
      if (cancelablePOI) {
        cancelablePOI.cancel();
      }
    };
  // eslint-disable-next-line
  }, [locationRoot]);

  useEffect(() => {
    if (page !== hotelsPageRoot) {
      dispatch(receiveHotels(hotelsPageRoot, searchParamsRoot));
      reducerDispatch({ type: ActionType.SET_PAGE, page: hotelsPageRoot });
    }
  // eslint-disable-next-line
  }, [hotelsPageRoot, searchParamsRoot]);

  useEffect(() => {
    if (locationRoot.id && dateRangesRoot.checkIn && lastSortBy !== hotelsSortByRoot) {
      const sortOptions: Array<string> = [hotelsSortByRoot];
      const params: AvailabilitySearchParams = constructAvailabilityRequestParams(
        userCurrencyRoot, dateRangesRoot, roomsRoot, locationRoot, sortOptions, hotelFiltersRoot,
      );

      dispatch(receiveHotels(hotelsPageRoot, params));
      reducerDispatch({ type: ActionType.SET_LAST_SORT_BY, lastSortBy: hotelsSortByRoot });
    }
  // eslint-disable-next-line
  }, [hotelsSortByRoot]);

  useEffect(() => {
    doFetchHotelsNeighborhoods(availabilityNeighborhoodsRoot);

    // eslint-disable-next-line
    return () => {
      if (cancelableNeighborhoods) {
        cancelableNeighborhoods.cancel();
      }
    };
    // eslint-disable-next-line
  }, [availabilityNeighborhoodsRoot]);

  const onPageChange = (selectedItem: { selected: number; }): void => {
    dispatch(hotelsCurrentPage(selectedItem.selected));

    window.scrollTo(0, 0);
  };

  return {
    breadcrumbs,
    isFetching,
    isFetchMade,
    hotels,
    onPageChange,
  };
};
