import genericApi from '../genericApi';

import { ApiList, ProviderInfo } from '../../models';

import { PORTAL_BE_ROUTE_PREFIX } from '../../configs/environments';

const providerRemote = {

  getProviders: (): Promise<ApiList<ProviderInfo>> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/provider-infos`),
};

export default providerRemote;
