export interface CancelablePromise {
  promise: Promise<unknown>;
  cancel: () => void;
}

export function makePromiseCancelable<T>(promise: Promise<T>): {
  promise: Promise<unknown>,
  cancel: () => void;
} {
  let hasCanceled = false;

  const wrappedPromise: Promise<unknown> = new Promise((resolve, reject) => {
    promise.then(
      (val) => {
        // eslint-disable-next-line
        hasCanceled ? reject({ isCanceled: true }) : resolve(val);
      },
      (error) => {
        // eslint-disable-next-line
        hasCanceled ? reject({ isCanceled: true }) : reject(error);
      },
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
}
