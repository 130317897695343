import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import nextId from 'react-id-generator';

import {
  AvailabilityError,
  AvailabilityProductWithId,
  BookingCardData,
  BookingCardDataErrors,
  BookingPersonData,
  BookingPersonDataErrors,
  BookingRequestParams,
  BookingResponse,
  BookingRoomHolder,
  BookingRoomHolderErrors,
  CheckInCheckOut as CheckInCheckOutModel,
  Room,
} from '../../../../../models';
import AgeQualifyingCode from '../../../../../models/AgeQualifyingCode.enum';

import { bookingRemote } from '../../../../../api/services';
import { RootState } from '../../../../../store/reducers';

import { BOOKING_CONFIRMATION_ROUTE_PATH, BOOKING_ROUTE_PATH, HOTEL_ROUTE_PATH } from '../../../../../routes/routesPath';
import { bookingRequestParams } from '../../../../../utils/bookingReqParams';

type RoomHolderKey = keyof {
  age: string;
  age_qualifying_code: string;
  given_name: string;
  name_prefix: string;
  surname: string;
  uniqueGroupId: string;
  uniqueId: string;
};

type RoomHolderErrorKey = keyof {
  given_name: string;
  name_prefix: string;
  surname: string;
};

type CardDataErrorKey = keyof {
  cardNumber: boolean;
  expMonth: boolean;
  expYear: boolean;
};

type BookingPersonAddressErrorKey = keyof {
  cityName: boolean;
  countryCode: boolean;
  postalCode: boolean;
  stateProvince: boolean;
  streetLine: boolean;
  streetLineSecond?: boolean;
};

type BookingPersonPersonErrorKey = keyof {
  title: boolean;
  firstName: boolean;
  lastName: boolean;
};

type BookingPersonErrorKey = keyof {
  address: BookingPersonAddressErrorKey;
  person: BookingPersonPersonErrorKey;
};

const hasInvalidRoomHolderInputs = (
  roomHolders: Array<Array<BookingRoomHolder>>,
  roomHoldersErrors: Array<Array<BookingRoomHolderErrors>>,
): {
  hasRoomHolderErrors: boolean,
  roomHolderErrorsArr: Array<Array<BookingRoomHolderErrors>>,
} => {
  let hasRoomHolderErrors = false;
  const { length } = roomHolders;
  const roomHolderErrorsArr: Array<Array<BookingRoomHolderErrors>> = [];

  for (let r = 0; r < length; r += 1) {
    const currRoom: Array<BookingRoomHolder> = roomHolders[r];
    const currRoomErrors: Array<BookingRoomHolderErrors> = [...roomHoldersErrors[r]];
    const roomLength = currRoom.length;

    for (let i = 0; i < roomLength; i += 1) {
      const currRoomHolders: BookingRoomHolder = currRoom[i];
      const roomHoldersError: BookingRoomHolderErrors = { ...currRoomErrors[i] };

      // eslint-disable-next-line
      for (const key in currRoomHolders) {
        // eslint-disable-next-line
        const currRoomHoldersObj: string | number | undefined = currRoomHolders[key as RoomHolderKey];

        if (typeof currRoomHoldersObj === 'string' && !currRoomHoldersObj.trim()) {
          hasRoomHolderErrors = true;
          roomHoldersError[key as RoomHolderErrorKey] = true;
        }
      }

      currRoomErrors[i] = roomHoldersError;
    }

    roomHolderErrorsArr.push(currRoomErrors);
  }

  return { hasRoomHolderErrors, roomHolderErrorsArr };
};

const hasInvalidCardInputs = (cardData: BookingCardData): {
  hasCardErrors: boolean,
  cardErrorsObj: BookingCardDataErrors,
} => {
  let hasCardErrors = false;

  const cardErrorsObj: BookingCardDataErrors = {
    cardNumber: Number.isNaN(Number(cardData.cardNumber)) || cardData.cardNumber === '',
    expMonth: cardData.expMonth === '-1' || cardData.expMonth === '',
    expYear: cardData.expYear === 'Year' || cardData.expYear === '',
  };
  // eslint-disable-next-line
  for (const key in cardErrorsObj) {
    if (cardErrorsObj[key as CardDataErrorKey]) {
      hasCardErrors = cardErrorsObj[key as CardDataErrorKey];
      break;
    }
  }

  return { hasCardErrors, cardErrorsObj };
};

const hasInvalidPersonInputs = (personData: BookingPersonData): {
  hasPersonErrors: boolean; personErrorsObj: BookingPersonDataErrors } => {
  let hasPersonErrors = false;

  const personErrorsObj: BookingPersonDataErrors = {
    address: {
      cityName: personData.address.cityName.trim() === '',
      countryCode: personData.address.countryCode === '',
      postalCode: personData.address.postalCode.trim() === '',
      stateProvince: personData.address.stateProvince.trim() === '',
      streetLine: personData.address.streetLine.trim() === '',
    },
    person: {
      title: personData.person.title === '',
      firstName: personData.person.firstName.trim() === '',
      lastName: personData.person.lastName.trim() === '',
    },
  };
  // eslint-disable-next-line
  for (const key in personErrorsObj) {
    // eslint-disable-next-line
    for (const innerKey in personErrorsObj[key as BookingPersonErrorKey]) {
      // eslint-disable-next-line
      if (personErrorsObj[key as BookingPersonErrorKey][innerKey as BookingPersonAddressErrorKey & BookingPersonPersonErrorKey]) {
        // eslint-disable-next-line
        hasPersonErrors = personErrorsObj[key as BookingPersonErrorKey][innerKey as BookingPersonAddressErrorKey & BookingPersonPersonErrorKey];
        break;
      }
    }
  }
  
  return { hasPersonErrors, personErrorsObj };
};

const sortByAdultsFirstAndChildrenByAgeSecond = (
  personOne: BookingRoomHolder, personTwo: BookingRoomHolder,
): number => {
  const ageOne = !personOne.age ? -1 : personOne.age;
  const ageTwo = !personTwo.age ? -1 : personTwo.age;

  return ageOne < ageTwo ? -1 : 1;
};

const extractRoomHoldersData = (
  roomsRoot: Array<Room>,
  roomHolders: Array<Array<BookingRoomHolder>>,
  roomHoldersErrors: Array<Array<BookingRoomHolderErrors>>,
): {
  roomHoldersCopy: Array<Array<BookingRoomHolder>>,
  roomHoldersErrorsCopy: Array<Array<BookingRoomHolderErrors>>,
} => {
  const roomHoldersCopy = [...roomHolders];
  const roomHoldersErrorsCopy = [...roomHoldersErrors];

  // eslint-disable-next-line no-restricted-syntax
  for (const room of roomsRoot) {
    const currentRoomHolders: Array<BookingRoomHolder> = [];
    const currentRoomHoldersErrors: Array<BookingRoomHolderErrors> = [];
    const adultsLength = room.adults;
    const childrenAges = room.agesOfChild;
    const childrenLength = childrenAges.length;
    const uniqueGroupId = nextId('rhugid-');

    for (let a = 0; a < adultsLength; a += 1) {
      currentRoomHolders.push({
        age_qualifying_code: AgeQualifyingCode.ADULT,
        given_name: '',
        name_prefix: '',
        surname: '',
        uniqueGroupId,
        uniqueId: nextId('rhuid-'),
      });
      currentRoomHoldersErrors.push({ given_name: false, name_prefix: false, surname: false });
    }
    for (let c = 0; c < childrenLength; c += 1) {
      currentRoomHolders.push({
        age: childrenAges[c],
        age_qualifying_code: AgeQualifyingCode.CHILD,
        given_name: '',
        name_prefix: '',
        surname: '',
        uniqueGroupId,
        uniqueId: nextId('rhuid-'),
      });
      currentRoomHoldersErrors.push({ given_name: false, name_prefix: false, surname: false });
    }

    currentRoomHolders.sort(sortByAdultsFirstAndChildrenByAgeSecond);

    roomHoldersCopy.push(currentRoomHolders);
    roomHoldersErrorsCopy.push(currentRoomHoldersErrors);
  }

  return { roomHoldersCopy, roomHoldersErrorsCopy };
};

export const useLogic = (): {
  roomsRoot: Array<Room>,
  bookingErrors: Array<AvailabilityError> | null,
  bookNowDisabled: boolean,
  cardData: BookingCardData,
  cardErrors: BookingCardDataErrors,
  isFetching: boolean,
  personData: BookingPersonData,
  personDataErrors: BookingPersonDataErrors,
  roomHolders: Array<Array<BookingRoomHolder>>,
  roomHoldersErrors: Array<Array<BookingRoomHolderErrors>>,
  onBookNowClicked: () => void,
  onCardDataChange: (cardD: BookingCardData, cardE: BookingCardDataErrors) => void,
  onPersonDataChange: (personD: BookingPersonData) => void,
  onRoomHoldersChange: (
  roomsD: Array<Array<BookingRoomHolder>>,
  roomsE: Array<Array<BookingRoomHolderErrors>>,
  index: number) => void,
} => {
  const history = useHistory();

  const dateRangesRoot: CheckInCheckOutModel = useSelector(
    (state: RootState) => state.checkInCheckOut,
  );
  const productRoot: AvailabilityProductWithId | null = useSelector(
    (state: RootState) => state.booking,
  );
  const roomsRoot: Array<Room> = useSelector((state: RootState) => state.rooms);

  const [roomHolders, setRoomHolders] = useState<Array<Array<BookingRoomHolder>>>([]);
  const [
    roomHoldersErrors,
    setRoomHoldersErrors,
  ] = useState<Array<Array<BookingRoomHolderErrors>>>([]);
  const [cardData, setCardData] = useState<BookingCardData>(
    { cardNumber: '', expMonth: '', expYear: '' },
  );
  const [cardErrors, setCardErrors] = useState<BookingCardDataErrors>(
    { cardNumber: false, expMonth: false, expYear: false },
  );
  const [personData, setPersonData] = useState<BookingPersonData>({
    address: {
      cityName: '',
      countryCode: '',
      postalCode: '',
      stateProvince: '',
      streetLine: '',
      streetLineSecond: '',
    },
    person: {
      title: '',
      firstName: '',
      lastName: '',
    },
  });
  const [personDataErrors, setPersonDataErrors] = useState<BookingPersonDataErrors>({
    address: {
      cityName: false,
      countryCode: false,
      postalCode: false,
      stateProvince: false,
      streetLine: false,
      streetLineSecond: false,
    },
    person: {
      title: false,
      firstName: false,
      lastName: false,
    },
  });
  const [bookNowDisabled, setBookNowDisabled] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [bookingErrors, setBookingErrors] = useState<Array<AvailabilityError> | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const { roomHoldersCopy, roomHoldersErrorsCopy } = extractRoomHoldersData(
      roomsRoot,
      roomHolders,
      roomHoldersErrors,
    );

    setRoomHolders(roomHoldersCopy);
    setRoomHoldersErrors(roomHoldersErrorsCopy);
    if (productRoot) {
      setBookNowDisabled(!productRoot.product.isBookable);
    }
  // eslint-disable-next-line
  }, []);

  const validate = useCallback((): boolean => {
    const {
      hasRoomHolderErrors,
      roomHolderErrorsArr,
    } = hasInvalidRoomHolderInputs(roomHolders, roomHoldersErrors);
    const { hasCardErrors, cardErrorsObj } = hasInvalidCardInputs(cardData);
    const { hasPersonErrors, personErrorsObj } = hasInvalidPersonInputs(personData);

    setRoomHoldersErrors(roomHolderErrorsArr);
    setCardErrors(cardErrorsObj);
    setPersonDataErrors(personErrorsObj);

    return !hasRoomHolderErrors && !hasCardErrors && !hasPersonErrors;
  }, [roomHolders, roomHoldersErrors, cardData, personData]);

  const onBookNowClicked = useCallback((): void => {
    const isValid = validate();

    if (!isValid || !productRoot) {
      return;
    }
    
    setBookNowDisabled(true);
    const bookingData: BookingRequestParams = bookingRequestParams(
      dateRangesRoot,
      roomsRoot,
      productRoot,
      cardData,
      personData,
      roomHolders,
    );

    if (!bookingData.lot && bookingData.reservations.length === 0) {
      return;
    }

    setIsFetching(true);

    bookingRemote.makeReservation(bookingData).then((resp: BookingResponse) => {
      const { reservation_id, errors } = resp;
      setIsFetching(false);

      if (!reservation_id) {
        setBookNowDisabled(false);
        setBookingErrors(errors);
        window.scrollTo(0, 0);
        return;
      }

      history.push(`${HOTEL_ROUTE_PATH}/${productRoot.property.id}${BOOKING_ROUTE_PATH}/${reservation_id}${BOOKING_CONFIRMATION_ROUTE_PATH}`);
    }).catch(() => {
      setBookNowDisabled(false);
      setIsFetching(false);
      setBookingErrors([{ errorCode: '', errorMessage: 'Error' }]);
      window.scrollTo(0, 0);
    });
    // eslint-disable-next-line
  }, [dateRangesRoot, roomsRoot, productRoot, cardData, personData, roomHolders, validate]);

  const onCardDataChange = useCallback(
    (cardD: BookingCardData, cardE: BookingCardDataErrors): void => {
      setCardData(cardD);
      setCardErrors(cardE);
      // eslint-disable-next-line
  }, [cardData, cardErrors]);

  const onRoomHoldersChange = useCallback(
    (
      roomsD: Array<Array<BookingRoomHolder>>,
      roomsE: Array<Array<BookingRoomHolderErrors>>,
      index: number,
    ): void => {
      setRoomHolders((prev) => [
        ...prev.slice(0, index),
        roomsD[0],
        ...prev.slice(index + 1),
      ]);
      setRoomHoldersErrors((prev) => [
        ...prev.slice(0, index),
        roomsE[0],
        ...prev.slice(index + 1),
      ]);
      // eslint-disable-next-line
  }, [roomHolders, roomHoldersErrors]);

  const onPersonDataChange = useCallback((personD: BookingPersonData): void => {
    setPersonData(personD);
  // eslint-disable-next-line
  }, [personData]);

  return {
    roomsRoot,
    bookingErrors,
    bookNowDisabled,
    cardData,
    cardErrors,
    isFetching,
    personData,
    personDataErrors,
    roomHolders,
    roomHoldersErrors,
    onBookNowClicked,
    onCardDataChange,
    onPersonDataChange,
    onRoomHoldersChange,
  };
};
