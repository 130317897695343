import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Button } from '../../common';

import { BookingReservation } from '../../../models';

import { BOOKING_CONFIRMATION_ROUTE_PATH, BOOKING_ROUTE_PATH, HOTEL_ROUTE_PATH } from '../../../routes/routesPath';

import { currencySign } from '../../../utils/currencyMapper';
import ReservationApiStatus from '../../../models/ReservationApiStatus.enum';
import { CURRENCY_HUNDRED_UNIT } from '../../../configs/environments';

const useStyles = makeStyles(() => createStyles({
  reservationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 70,
    '& .MuiButton-label': {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
}));

const dateFormat = 'DD MMM YYYY';
export const formatMonthInApiDate = (date: string): string => Moment(date).format(dateFormat);
const roomText = (numberOfRooms: number): string => (numberOfRooms === 1 ? 'bookingReservationRoomText' : 'bookingReservationRoomsText');

interface TabContentProps {
  reservation: BookingReservation;
}

const TabContent: FC<TabContentProps> = (props: TabContentProps) => {
  const { reservation } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const onViewBookingButtonClicked = (event): void => {
    if (!event) {
      return;
    }

    const { propertyid, reservationid } = event.currentTarget.dataset;

    history.push(`${HOTEL_ROUTE_PATH}/${propertyid}${BOOKING_ROUTE_PATH}/${reservationid}${BOOKING_CONFIRMATION_ROUTE_PATH}`);
  };

  return (
    <Box className={classes.reservationContainer}>
      <Box width="75%">
        <Box>
          <Box component="span" fontWeight="bold">{`${formatMonthInApiDate(reservation.check_in)} - ${formatMonthInApiDate(reservation.check_out)}`}</Box>
          <Box component="span" fontWeight="bold">{` - ${reservation.property_name}, `}</Box>
          <Box component="span">{`${reservation.people[0][0].person_name.name_prefix} ${reservation.people[0][0].person_name.surname} - `}</Box>
          <Box component="span" fontWeight="bold">{`${t('bookingConfirmationVoucherReferenceTitle')}: `}</Box>
          <Box component="span">{reservation.unique_id}</Box>
        </Box>
        <Box>
          <Box component="span">
            {`${reservation.number_of_rooms} ${t(roomText(reservation.number_of_rooms)).toLowerCase()}, `}
            {`${reservation.adults} ${t('propertyDetailsProductAdultsLabel')}, `}
            {`${reservation.children} ${t('propertyDetailsProductChildrenLabel')}, `}
            {`${t('total')}: ${currencySign(reservation.currency)}${reservation.total / CURRENCY_HUNDRED_UNIT}, `}
            {`${t('created')}: ${formatMonthInApiDate(reservation.created_at)}, `}
          </Box>
          { reservation.status === ReservationApiStatus.ACTIVE && (
            <Box component="span">{`${t('cancelBy')}: ${formatMonthInApiDate(reservation.cancel_by)}`}</Box>
          )}
          { reservation.status === ReservationApiStatus.CANCELLED && (
            <>
              <Box component="span" fontWeight="bold">{`${t('cancelled')}: `}</Box>
              <Box component="span">{`${formatMonthInApiDate(reservation.cancelled_on)}, `}</Box>
              <Box component="span" fontWeight="bold">{`${t('cancellationReference')}: `}</Box>
              <Box component="span">{reservation.cancellation_reference}</Box>
            </>
          )}
          { reservation.status === ReservationApiStatus.FAILED && (
            <>
              <Box component="span" fontWeight="bold">{`${t('failed')}: `}</Box>
              <Box component="span">{`${formatMonthInApiDate(reservation.failed_on)}, `}</Box>
              <Box component="span" fontWeight="bold">{`${t('reason')}: `}</Box>
              <Box component="span">{`${t('failed')}: ${reservation.cancel_fail_reason}`}</Box>
            </>
          )}
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={onViewBookingButtonClicked}
          data-propertyid={reservation.property_id}
          data-reservationid={reservation.reservation_id}
        >
          {t('bookingReservationViewBookingText')}
        </Button>
      </Box>
    </Box>
  );
};

const MemoizedTabContent = React.memo(TabContent);
export default MemoizedTabContent;
