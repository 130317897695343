import React, { FC } from 'react';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
} from '@material-ui/icons';
import { Box, Button, ClickAwayListener } from '../../common';

import CheckInCheckOutInput from './checkInCheckOutInput/CheckInCheckOutInput';

import { CheckInCheckOut as CheckInCheckOutModel } from '../../../models';

import { useLogic } from './hooks/useLogic';

import configs from '../../../configs/environments';

import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';

const useStyles = (correctCss: { left: number; }) => makeStyles(() => createStyles({
  container: {
    position: 'absolute',
    width: 619,
    height: 355,
    backgroundColor: '#fff',
    zIndex: 5,
    boxShadow: '0px 0px 12px #888888',
    borderRadius: 8,
    left: correctCss?.left,
  },
  buttonContent: {
    marginRight: 7,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface CheckInCheckOutProps {
  correctCss?: { left: number; };
  hasCheckInCheckOutError: boolean;
  setCheckInCheckError: (hasError: boolean) => void;
  setDateRanges: (ranges: CheckInCheckOutModel) => void;
}

const CheckInCheckOut: FC<CheckInCheckOutProps> = (props: CheckInCheckOutProps) => {
  const {
    correctCss,
    hasCheckInCheckOutError,
    setCheckInCheckError,
    setDateRanges,
  } = props;
  const classes = useStyles(correctCss)();
  const { t } = useTranslation();
  const {
    endDateState,
    focusedInput,
    isOpen,
    startDateState,
    blockPastMonthsNavigation,
    isDayBlocked,
    onClick,
    onClose,
    onDatesChange,
    onFocusChange,
    onResetButtonClicked,
    setVisibleMonth,
  } = useLogic(setCheckInCheckError, setDateRanges);

  const endDateStr = endDateState?.isValid() ? Moment(endDateState).format('MMM DD') : '';
  const startDateStr = startDateState?.isValid() ? Moment(startDateState).format('MMM DD') : '';

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box>
        <CheckInCheckOutInput
          isOpen={isOpen}
          endDate={endDateStr}
          hasError={hasCheckInCheckOutError}
          hasValues={startDateState !== null && endDateState !== null}
          startDate={startDateStr}
          onClick={onClick}
        />
        {isOpen ? (
          <Box className={classes.container}>
            <DayPickerRangeController
              startDate={startDateState?.isValid() ? startDateState : null}
              endDate={endDateState?.isValid() ? endDateState : null}
              onDatesChange={onDatesChange}
              focusedInput={focusedInput}
              onFocusChange={onFocusChange}
              hideKeyboardShortcutsPanel
              numberOfMonths={configs.calendarDisplayNumbers}
              transitionDuration={0}
              minimumNights={configs.calendarMinBookingNights}
              isDayBlocked={isDayBlocked}
              navPrev={<NavigateBeforeIcon />}
              navNext={<NavigateNextIcon />}
              initialVisibleMonth={setVisibleMonth}
              minDate={blockPastMonthsNavigation()}
            />
            <Box className={classes.buttonContent}>
              <Button
                variant="contained"
                color="primary"
                onClick={onResetButtonClicked}
              >
                {t('resetButton')}
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

const MemoizedCheckInCheckOut = React.memo(CheckInCheckOut);
export default MemoizedCheckInCheckOut;
