import { STORE_SELECTED_PRODUCT } from '../actions/actionTypes';

import { AvailabilityProductWithId } from '../../models';

const initialState: AvailabilityProductWithId | null = null;

export default (
  // eslint-disable-next-line
  state: AvailabilityProductWithId | null = initialState, action: { type: string; payload: AvailabilityProductWithId },
): AvailabilityProductWithId | null => {
  if (action.type === STORE_SELECTED_PRODUCT && action.payload) {
    return {
      ...state,
      product: { ...action.payload.product },
      property: { ...action.payload.property },
    };
  }

  return state;
};
