import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import nextId from 'react-id-generator';

import { setCheckInCheckOutAction } from '../../../../../store/actions/checkInCheckOutAction';
import { setRoomsAction } from '../../../../../store/actions/roomsAction';
import { RootState } from '../../../../../store/reducers';

import { CheckInCheckOut as CheckInCheckOutModel, PropertyStoreData, Room as RoomModel } from '../../../../../models';

import { hasInvalidCheckInCheckOut, hasValidRoomSelection } from '../../../../../utils/hotelSearchValidations';
import { HOTEL_ROUTE_PATH } from '../../../../../routes/routesPath';

export const useLogic = (
  dateRanges: CheckInCheckOutModel,
  rooms: Array<RoomModel>,
  setCheckInCheckOutHasAnError: (hasError: boolean) => void,
  // eslint-disable-next-line
): { isHotelFetchingRoot: boolean, onSearchButtonClicked: () => void } => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isHotelFetchingRoot: boolean = useSelector((state: RootState) => state.fetching.hotels);
  const propertyRoot: PropertyStoreData = useSelector((state: RootState) => state.property);

  const validate = (): boolean => {
    const isInvalidCheckInCheckOut = hasInvalidCheckInCheckOut(dateRanges);
    setCheckInCheckOutHasAnError(isInvalidCheckInCheckOut);

    const isValidRoomsSelection = hasValidRoomSelection(rooms);

    return !isInvalidCheckInCheckOut && isValidRoomsSelection;
  };
  
  const onSearchButtonClicked = (): void => {
    const isValid = validate();

    if (isValid) {
      dispatch(setCheckInCheckOutAction(dateRanges));
      dispatch(setRoomsAction(rooms));
      // new search id to mark that new search is requested
      sessionStorage.setItem('cid', nextId());

      const encodedName: string = encodeURIComponent(propertyRoot.name);
      history.push(`${HOTEL_ROUTE_PATH}?pid=${propertyRoot.id}&name=${encodedName}`);
    }
  };

  return {
    isHotelFetchingRoot,
    onSearchButtonClicked,
  };
};
