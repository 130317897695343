import Moment from 'moment';
import nextId from 'react-id-generator';

import {
  AvailabilityCancellationPolicy,
  AvailabilityProductWithId,
  AvailabilityRoomStayGuest,
  BookingCardData,
  BookingPersonData,
  BookingRoomHolder,
  CheckInCheckOut,
  Room,
} from '../models';
import AgeQualifyingCode from '../models/AgeQualifyingCode.enum';
import BookingRequestParams, {
  CancelPenaltyReq,
  GuestCountReq,
  ResGuestReq,
  ReservationReq,
  RoomRateReq,
  RoomRatesReq,
  RoomStayReq,
  RoomStayGuaranteeReq,
} from '../models/BookingRequestParams';

function getAbsoluteDeadline(cancellationPolicies:
Array<AvailabilityCancellationPolicy> | null) {
  return Array.isArray(cancellationPolicies) ? cancellationPolicies[0].deadline : null;
}

export function adultsAndChildrenInARoom(roomGuests: Array<AvailabilityRoomStayGuest>): {
  numOfAdults: number, numOfChildren: number | undefined,
} {
  const { length } = roomGuests;
  let numOfAdults = 0;
  let numOfChildren: number | undefined = 0;

  for (let i = 0; i < length; i += 1) {
    const guest: AvailabilityRoomStayGuest = roomGuests[i];

    if (guest.age_qualifying_code === AgeQualifyingCode.ADULT) {
      numOfAdults += guest.count;
    }
    if (guest.age_qualifying_code === AgeQualifyingCode.CHILD) {
      numOfChildren += guest.count;
    }
  }

  if (numOfChildren === 0) {
    numOfChildren = undefined;
  }

  return { numOfAdults, numOfChildren };
}

const dateFormat = 'YYYY-MM-DD';

const constructBookingRequestParams = (
  dateRangeSelected: CheckInCheckOut,
  roomsSelected: Array<Room>,
  productSelected: AvailabilityProductWithId,
  cardData: BookingCardData,
  personData: BookingPersonData,
  roomHolders: Array<Array<BookingRoomHolder>>,
): BookingRequestParams => {
  const checkInStr = Moment(dateRangeSelected.checkIn).format(dateFormat);
  const checkOutStr = Moment(dateRangeSelected.checkOut).format(dateFormat);
  const { product, property } = productSelected;
  const { room_stays } = product;
  const roomStays: Array<RoomStayReq> = [];
  const roomGuestHolders: Array<ResGuestReq> = [{ profiles: [] }];
  const roomsLength = roomsSelected.length;
  const guaranteeData: RoomStayGuaranteeReq = {
    guarantee_accepted: {
      payment_card: {
        address: {
          state_prov: {
            value: personData.address.stateProvince,
          },
          address_line: [personData.address.streetLine, personData.address.streetLineSecond],
          city_name: personData.address.cityName,
          postal_code: personData.address.postalCode,
          country_name: {
            code: personData.address.countryCode,
          },
        },
        card_code: '',
        card_number: cardData.cardNumber,
        cardholder_name: `${personData.person.firstName} ${personData.person.lastName}`,
        expire_date: `${cardData.expMonth}/${cardData.expYear}`,
        email: '',
        telephone: {
          phone_number: '',
        },
      },
    },
  };

  for (let i = 0; i < roomsLength; i += 1) {
    const cancelPenalties: Array<CancelPenaltyReq> = [];
    const roomRates: Array<RoomRatesReq> = [];
    const roomsRatesLength = room_stays[i].room_rates.length;
    const guestsInARoom: Array<AvailabilityRoomStayGuest> = room_stays[i].guests;
    const { numOfAdults, numOfChildren } = adultsAndChildrenInARoom(guestsInARoom);

    const roomStay: RoomStayReq = {
      basic_property_info: {
        hotel_code: property.id,
        hotel_name: property.name,
      },
      cancel_penalties: cancelPenalties,
      cancellation_policies: room_stays[i].cancellation_policies,
      cancellation_string: room_stays[i].cancellation_string,
      guarantee: i === 0 ? guaranteeData : undefined,
      guest_counts: [],
      room_rates: roomRates,
      room_type: {
        code: room_stays[i].code,
        inclusion_description: room_stays[i].meal_plan,
        room_type_id: product.room_type.room_type_id,
        number_of_adults: numOfAdults,
        number_of_children: numOfChildren,
        room_type_description: product.room_type.description,
      },
      time_span: {
        start: checkInStr,
        end: checkOutStr,
      },
    };

    const rates: Array<RoomRateReq> = [];
    const roomRate: RoomRatesReq = {
      rates,
      rate_plan_code: 'Available', // hardcoded for the moment, BE needed this way
    };
    for (let j = 0; j < roomsRatesLength; j += 1) {
      const rate: RoomRateReq = {
        base: {
          amount_after_tax: (room_stays[i].room_rates[j].rate.local_amount),
          currency_code: room_stays[i].room_rates[j].rate.local_currency,
        },
        effective_date: room_stays[i].room_rates[j].effective_date,
      };

      roomRate.rates.push(rate);
    }

    const roomGuests = room_stays[i].guests;
    const roomGuestsLength = roomGuests.length;

    for (let k = 0; k < roomGuestsLength; k += 1) {
      const guestTypeCount = roomGuests[k].count;

      for (let m = 0; m < guestTypeCount; m += 1) {
        const uniquePrefix = 'gc-';
        const uniqueId = nextId(uniquePrefix);
        const roomGuestHolderCode = Number(uniqueId.substring(uniquePrefix.length));

        const guests: GuestCountReq = {
          age: roomGuests[k].age,
          age_qualifying_code: roomGuests[k].age_qualifying_code,
          res_guest_RPH: roomGuestHolderCode,
        };

        roomStay.guest_counts.push(guests);
      }
    }

    const absoluteDeadline = getAbsoluteDeadline(room_stays[0].cancellation_policies);
    cancelPenalties.push({
      deadline: {
        absolute_deadline: absoluteDeadline,
      },
    });
    roomStays.push(roomStay);
    roomRates.push(roomRate);
    const currRoomHolders: Array<BookingRoomHolder> = roomHolders[i];
    const roomHoldersLength = currRoomHolders.length;

    for (let rh = 0; rh < roomHoldersLength; rh += 1) {
      const currRoomHolder: BookingRoomHolder = currRoomHolders[rh];

      roomGuestHolders[0].profiles.push({
        customer: {
          person_name: {
            given_name: currRoomHolder.given_name,
            name_prefix: currRoomHolder.name_prefix,
            surname: currRoomHolder.surname,
          },
        },
        // `main_guest` flag is always set to the first person in the reservation
        main_guest: i === 0 && rh === 0,
        RPH: roomStay.guest_counts[rh].res_guest_RPH,
      });
    }
  }

  const reservation: ReservationReq = {
    rate: product.rate,
    res_guests: roomGuestHolders,
    room_stays: roomStays,
  };

  return { lot: product.lot, reservations: [reservation] };
};

export const bookingRequestParams = (
  dateRangeSelected: CheckInCheckOut,
  roomsSelected: Array<Room>,
  productSelected: AvailabilityProductWithId,
  cardData: BookingCardData,
  personData: BookingPersonData,
  roomHolders: Array<Array<BookingRoomHolder>>,
): BookingRequestParams => {
  try {
    return constructBookingRequestParams(
      dateRangeSelected,
      roomsSelected,
      productSelected,
      cardData,
      personData,
      roomHolders,
    );
  } catch {
    return { lot: null, reservations: [] };
  }
};
