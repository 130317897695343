import React, { FC } from 'react';

import nextId from 'react-id-generator';

import { Box } from '../../../common';

import { PropertyFactN } from '../../../../models';

interface PropertyPoliciesProps {
  policies: Array<PropertyFactN>;
}

const PropertyPolicies: FC<PropertyPoliciesProps> = (props: PropertyPoliciesProps) => {
  const { policies } = props;

  try {
    return (
      <>
        { policies.length === 0 ? <Box /> : (
          <>
            { policies.map((fact: PropertyFactN) => (
              <Box key={nextId('fct-')} mb={'20px'}>
                <Box fontWeight="bold" mb={'5px'}>{fact.fact_type_name}</Box>
                <Box>{fact.fact_name}</Box>
              </Box>
            )) }
          </>
        )}
      </>
    );
  } catch {
    return <Box />;
  }
};

const MemoizedPropertyPolicies = React.memo(PropertyPolicies);
export default MemoizedPropertyPolicies;
