import {
  CLEAR_ALL_SELECTED,
  CLEAR_ALL_SELECTED_HOTEL_AMENITIES,
  CLEAR_ALL_SELECTED_HOTEL_CHAINS,
  CLEAR_ALL_SELECTED_HOTEL_NEIGHBORHOODS,
  CLEAR_ALL_SELECTED_HOTEL_RATINGS,
  STORE_HOTEL_AMENITY,
  STORE_HOTEL_CHAIN,
  STORE_HOTEL_NEIGHBORHOOD,
  STORE_HOTEL_RATING,
  STORE_POPULAR_LOCATION,
  REMOVE_HOTEL_AMENITY,
  REMOVE_HOTEL_CHAIN,
  REMOVE_HOTEL_NEIGHBORHOOD,
  REMOVE_HOTEL_RATING,
  REMOVE_POPULAR_LOCATION,
} from '../actions/actionTypes';

import { FiltersStoreData } from '../../models';

const initialState: FiltersStoreData = {
  amenity: [],
  chain: [],
  location: null,
  neighborhood: [],
  rating: [],
};

export default (
  // eslint-disable-next-line
  state: FiltersStoreData = initialState, action: { type: string; payload: any },
): FiltersStoreData => {
  switch (action.type) {
    case CLEAR_ALL_SELECTED: {
      const newState: FiltersStoreData = {
        amenity: [],
        chain: [],
        location: null,
        neighborhood: [],
        rating: [],
      };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case CLEAR_ALL_SELECTED_HOTEL_AMENITIES: {
      const newState: FiltersStoreData = { ...state, amenity: [] };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case CLEAR_ALL_SELECTED_HOTEL_CHAINS: {
      const newState: FiltersStoreData = { ...state, chain: [] };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case CLEAR_ALL_SELECTED_HOTEL_NEIGHBORHOODS: {
      const newState: FiltersStoreData = { ...state, neighborhood: [] };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case CLEAR_ALL_SELECTED_HOTEL_RATINGS: {
      const newState: FiltersStoreData = { ...state, rating: [] };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case STORE_HOTEL_AMENITY: {
      const newState: FiltersStoreData = {
        ...state,
        amenity: [...state.amenity].concat([action.payload]),
      };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case STORE_HOTEL_CHAIN: {
      const newState: FiltersStoreData = {
        ...state,
        chain: [...state.chain].concat([action.payload]),
      };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case STORE_HOTEL_NEIGHBORHOOD: {
      const newState: FiltersStoreData = {
        ...state,
        neighborhood: [...state.neighborhood].concat([action.payload]),
      };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case STORE_HOTEL_RATING: {
      const newState: FiltersStoreData = {
        ...state,
        rating: [...state.rating].concat([action.payload]),
      };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case STORE_POPULAR_LOCATION: {
      const newState: FiltersStoreData = { ...state, location: action.payload };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case REMOVE_HOTEL_AMENITY: {
      const newState: FiltersStoreData = {
        ...state,
        amenity: [...state.amenity].filter((item: number) => item !== action.payload),
      };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case REMOVE_HOTEL_CHAIN: {
      const newState: FiltersStoreData = {
        ...state,
        chain: [...state.chain].filter((item: number) => item !== action.payload),
      };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case REMOVE_HOTEL_NEIGHBORHOOD: {
      const newState: FiltersStoreData = {
        ...state,
        neighborhood: [...state.neighborhood].filter((item: number) => item !== action.payload),
      };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case REMOVE_HOTEL_RATING: {
      const newState: FiltersStoreData = {
        ...state,
        rating: [...state.rating].filter((item: number) => item !== action.payload),
      };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }

    case REMOVE_POPULAR_LOCATION: {
      const newState: FiltersStoreData = { ...state, location: null };

      sessionStorage.setItem('filters', JSON.stringify(newState));
      return newState;
    }
    default: return state;
  }
};
