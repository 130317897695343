import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    cardRoot: {
      background: '#696969',
      color: '#fff',
      border: '1px solid #fff',
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      height: 110,
      '& .MuiCardContent-root': {
        paddingTop: 20,
      },
      fontWeight: 'bold',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
    },
    leftContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    copyrightContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: 8,
      fontWeight: 'bold',
      marginTop: 10,
      '& .MuiSvgIcon-root': {
        width: 15,
        height: 15,
        marginRight: 2,
      },
      '& .MuiTypography-subtitle2': {
        fontWeight: 'bold',
      },
    },
    middleContent: {
      flex: 3,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    rightContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    footerButton: {
      textTransform: 'none',
      textDecoration: 'underline',
      fontWeight: 'bold',
      padding: 0,
      minWidth: 0,
      '&:hover': {
        background: 'none',
        textDecoration: 'underline',
      },
    },
    brandImage: {
      width: 90,
      height: 45,
    },
  },
));
