import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'el', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1530,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 8,
      },
    },
  },
  palette: {
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    primary: {
      main: '#D90416',
    },
    secondary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
  },
});

export default theme;
