import { STORE_AVAILABILITY_NEIGHBORHOOD } from './actionTypes';

// eslint-disable-next-line max-len
const storeAvailabilityNeighborhoods = (neighborhoodIds: Array<number>): { type: string, payload: Array<number> } => {
  sessionStorage.setItem('availabilityNeighborhood', JSON.stringify(neighborhoodIds));

  return {
    type: STORE_AVAILABILITY_NEIGHBORHOOD,
    payload: neighborhoodIds,
  };
};

export {
  storeAvailabilityNeighborhoods,
};
