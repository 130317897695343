import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    hotelLoadingCard: {
      width: '100%',
      '& .MuiCardContent-root:last-child': {
        paddingBottom: 10,
      },
    },
    hotelLoadingCardContainer: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        padding: '8px 5px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        flexDirection: 'row',
        padding: '8px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        flexDirection: 'row',
        padding: '8px',
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        flexDirection: 'row',
        padding: '8px',
      },
    },
    hotelLoadingCardImageContainer: {
      display: 'flex',
      width: 275,
      height: 185,
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        justifyContent: null,
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        justifyContent: null,
      },
    },
    hotelLoadingCardContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 0,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: 10,
        marginLeft: 0,
      },
      [theme.breakpoints.between('md', 'lg')]: {
        marginTop: 0,
        marginLeft: 15,
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        marginTop: 0,
        marginLeft: 15,
      },
    },
  },
));
