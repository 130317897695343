import {
  ChangeEvent,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PointOfInterest } from '../../../../../../models';

import { selectPopularLocation, unSelectPopularLocation } from '../../../../../../store/actions/filtersAction';
import { RootState } from '../../../../../../store/reducers';

import PopularLocationContext, { PopularLocationContextModel } from '../../../../../../contexts/PopularLocationContext';

import { INITIAL_FILTER_ITEMS } from '../../../../../../configs/environments';

const getPopularLocation = (
  locations: Array<PointOfInterest>, locationId: string | null,
): PointOfInterest | undefined => locations.find((poi: PointOfInterest) => poi.id === locationId);

enum ActionType {
  SET_EXPAND_COLLAPSE_WITH_MORE_OR_LESS = 'SET_EXPAND_COLLAPSE_WITH_MORE_OR_LESS',
  SET_POINTS_OF_INTEREST = 'SET_POINTS_OF_INTEREST',
  SET_SELECTED_POI = 'SET_SELECTED_POI',
}

interface State {
  expanded: boolean;
  pointsMoreLess: Array<PointOfInterest>;
  pointsOfInterest: Array<PointOfInterest>;
  selectedPoi: string | null;
}

const initialState: State = {
  expanded: false,
  pointsMoreLess: [],
  pointsOfInterest: [],
  selectedPoi: null,
};

type Action =
  // eslint-disable-next-line
  | { type: ActionType.SET_EXPAND_COLLAPSE_WITH_MORE_OR_LESS, expanded: boolean, pointsMoreLess: Array<PointOfInterest> }
  // eslint-disable-next-line
  | { type: ActionType.SET_POINTS_OF_INTEREST, pointsOfInterest: Array<PointOfInterest>, pointsMoreLess: Array<PointOfInterest> }
  | { type: ActionType.SET_SELECTED_POI, selectedPoi: string | null };

const pointsOfInterestReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_EXPAND_COLLAPSE_WITH_MORE_OR_LESS: return {
      ...state,
      expanded: action.expanded,
      pointsMoreLess: [...action.pointsMoreLess],
    };
    case ActionType.SET_POINTS_OF_INTEREST: return {
      ...state,
      expanded: false,
      pointsOfInterest: [...action.pointsOfInterest],
      pointsMoreLess: [...action.pointsMoreLess],
    };
    case ActionType.SET_SELECTED_POI: return {
      ...state,
      selectedPoi: action.selectedPoi,
    };

    default: return state;
  }
};

export const useLogic = (): {
  expanded: boolean,
  pointsMoreLess: Array<PointOfInterest>,
  pointsOfInterest: Array<PointOfInterest>,
  selectedPoi: string | null,
  onExpandCollapse: () => void,
  onPointOfInterestClear: () => void,
  onPointOfInterestSelect: (event: ChangeEvent<HTMLInputElement>) => void,
} => {
  const dispatch = useDispatch();
  
  const hotelPoiFilterRoot: string | null = useSelector(
    (store: RootState) => store.filters.location,
  );
  const popularLocationsRoot: Array<PointOfInterest> = useSelector(
    (store: RootState) => store.popularLocations,
  );

  // eslint-disable-next-line max-len
  const [state, reducerDispatch] = useReducer<Reducer<State, Action>>(pointsOfInterestReducer, initialState);
  const {
    expanded,
    pointsMoreLess,
    pointsOfInterest,
    selectedPoi,
  } = state;

  const { setLocationContext } = useContext<PopularLocationContextModel>(PopularLocationContext);

  useEffect(() => {
    reducerDispatch({
      type: ActionType.SET_POINTS_OF_INTEREST,
      pointsOfInterest: popularLocationsRoot,
      pointsMoreLess: [...popularLocationsRoot.slice(0, INITIAL_FILTER_ITEMS)],
    });
  }, [popularLocationsRoot]);

  useEffect(() => {
    reducerDispatch({
      type: ActionType.SET_SELECTED_POI,
      selectedPoi: hotelPoiFilterRoot,
    });

    if (pointsOfInterest.length > 0 && hotelPoiFilterRoot) {
      // eslint-disable-next-line max-len
      const popularLocation: PointOfInterest | undefined = getPopularLocation(pointsOfInterest, hotelPoiFilterRoot);
      setLocationContext(popularLocation || null);
    }
    // eslint-disable-next-line
  }, [hotelPoiFilterRoot, pointsOfInterest]);

  const onExpandCollapse = (): void => {
    const isExpanded = !expanded;
    let poiCopy = [...pointsOfInterest];

    if (!isExpanded) {
      poiCopy = [...poiCopy.slice(0, INITIAL_FILTER_ITEMS)];
    }

    reducerDispatch({
      type: ActionType.SET_EXPAND_COLLAPSE_WITH_MORE_OR_LESS,
      expanded: isExpanded,
      pointsMoreLess: poiCopy,
    });
  };

  const onPointOfInterestClear = (): void => {
    reducerDispatch({ type: ActionType.SET_SELECTED_POI, selectedPoi: null });
    dispatch(unSelectPopularLocation());
    setLocationContext(null);
  };

  const onPointOfInterestSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    const popularLocationId: string = (event.target as HTMLInputElement).value;

    reducerDispatch({ type: ActionType.SET_SELECTED_POI, selectedPoi: popularLocationId });
    dispatch(selectPopularLocation(popularLocationId));

    // eslint-disable-next-line max-len
    const popularLocation: PointOfInterest | undefined = getPopularLocation(pointsOfInterest, popularLocationId);
    setLocationContext(popularLocation || null);
  };

  return {
    expanded,
    pointsMoreLess,
    pointsOfInterest,
    selectedPoi,
    onExpandCollapse,
    onPointOfInterestClear,
    onPointOfInterestSelect,
  };
};
