import { Context, createContext } from 'react';

import { PointOfInterest } from '../models';

export interface PopularLocationContextModel {
  locationContext: PointOfInterest | null;
  setLocationContext: (Location: PointOfInterest | null) => void;
}

// eslint-disable-next-line
const PopularLocationContext: Context<PopularLocationContextModel> = createContext<PopularLocationContextModel>({ locationContext: null, setLocationContext: () => null });

export default PopularLocationContext;
