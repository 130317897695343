import React, { FC, ReactNode } from 'react';
import { LocationOn as LocationIcon, LocalAirport as AirportIcon } from '@material-ui/icons';
import { Box } from '../../../common';
import { Location } from '../../../../models';
import DestinationType from '../../../../models/DestinationType.enum';

import useStyles from './locationSuggestionsStyles';

interface LocationSuggestionsProps {
  suggestion: Location;
}

const LocationSuggestions: FC<LocationSuggestionsProps> = (props: LocationSuggestionsProps) => {
  const classes = useStyles();
  const { suggestion } = props;

  const setDestinationIcon = (suggestionType: string): ReactNode => {
    switch (suggestionType) {
      case DestinationType.CITY: return <LocationIcon />;
      case DestinationType.AIRPORT: return <AirportIcon />;
      default: return <Box />;
    }
  };
  
  return (
    <Box className={classes.content}>
      <Box className={classes.locationsContent}>
        <Box className={classes.iconContainer}>
          { setDestinationIcon(suggestion.destination_type) }
        </Box>
        <Box className={classes.titleContainer}>
          <Box className={classes.destinationContainer}>
            {suggestion.location_name}
          </Box>
          <Box>
            {`${suggestion.destination_name}, ${suggestion.country_name} `}
          </Box>
        </Box>
        <Box className={classes.typeContainer}>
          {suggestion.destination_type}
        </Box>
      </Box>
    </Box>
  );
};

export default LocationSuggestions;
