import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '../../../common';

import { PropertyText } from '../../../../models';

import useStyles from './propertyDescriptionStyles';

interface PropertyDescriptionProps {
  descriptions: Array<PropertyText>;
}

const PropertyDescription: FC<PropertyDescriptionProps> = (props: PropertyDescriptionProps) => {
  const { descriptions } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<boolean>(false);

  const onExpandCollapse = (): void => (
    setExpanded((expandedPrev) => !expandedPrev)
  );

  try {
    return (
      <Box>
        <Box className={classes.descriptionContainer} key={descriptions[0].section_id}>
          <Box fontWeight="bold" mb={0.5}>{descriptions[0].title}</Box>
          <Box textAlign="justify">{descriptions[0].text}</Box>
        </Box>
        { expanded && descriptions.map((propertyText: PropertyText, index: number) => (index > 0
          && (
            <Box className={classes.descriptionContainer} key={propertyText.section_id}>
              <Box fontWeight="bold" mb={0.5}>{propertyText.title}</Box>
              <Box textAlign="justify">{propertyText.text}</Box>
            </Box>
          )
        ))}
        <Box className={classes.moreLessContainer}>
          <Button
            className={classes.moreLessButton}
            onClick={onExpandCollapse}
          >
            {expanded ? t('propertyDetailsShowLess') : t('propertyDetailsShowMore')}
          </Button>
        </Box>
      </Box>
    );
  } catch {
    return <Box />;
  }
};

const MemoizedPropertyDescription = React.memo(PropertyDescription);
export default MemoizedPropertyDescription;
