import { SortByStoreData } from '../../models';

import { SORT_BY_PRICE_ASC } from '../../configs/environments';

export default function sortByPersistor(): SortByStoreData {
  let filters: SortByStoreData = { list: SORT_BY_PRICE_ASC, details: SORT_BY_PRICE_ASC };
  const sessionSortBy: string | null = sessionStorage.getItem('sortBy');

  if (sessionSortBy) {
    filters = JSON.parse(sessionSortBy);
  }

  return filters;
}
