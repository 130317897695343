import React, { FC } from 'react';

import { Box, Card, CardContent } from '../../../common';

import HotelLoadingContent from '../hotelLoadingContent/HotelLoadingContent';
import HotelLoadingImage from '../hotelLoadingImage/HotelLoadingImage';

import useStyles from './hotelLoadingCardStyles';

const HotelLoadingCard: FC<unknown> = () => {
  const classes = useStyles();

  return (
    <Card className={classes.hotelLoadingCard}>
      <CardContent className={classes.hotelLoadingCardContainer}>
        <Box className={classes.hotelLoadingCardImageContainer}>
          <HotelLoadingImage />
        </Box>
        <Box className={classes.hotelLoadingCardContentContainer}>
          <HotelLoadingContent />
        </Box>
      </CardContent>
    </Card>
  );
};

export default HotelLoadingCard;
