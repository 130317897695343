import { Dispatch } from 'redux';
import { STORE_HOTELS, STORE_HOTELS_COORDINATES } from './actionTypes';
import {
  fetchingHotelsAndCoordinatesStart,
  fetchingHotelsCoordinatesEnd,
  fetchingHotelsCoordinatesStart,
  fetchingHotelsEnd,
  fetchingHotelsStart,
} from './fetchingAction';
import { storeAvailabilityNeighborhoods } from './neighborhoodAction';

import { storeAvailabilitySearchParams } from './searchParamsAction';

import { hotelsRemote } from '../../api/services';

import {
  Availability,
  AvailabilityError,
  AvailabilityLight,
  AvailabilitySearchCriteriaIdsParams,
  AvailabilitySearchParams,
  MapCoordinatesNESW,
} from '../../models';
import ApiList, { getEmptyApiList } from '../../models/ApiList';

import { RootState } from '../reducers';
import { AvailabilitySearchPropertyFilter } from '../../models/AvailabilitySearchParams';

const getNeighborhoodsFromTheAvailability = (hotels: Array<Availability>): Array<number> => {
  let ids: Array<number> = [];

  if (hotels.length > 0) {
    ids = hotels
      .filter((hotel: Availability) => hotel.property_info.neighborhood_id)
      .map((hotel: Availability) => hotel.property_info.neighborhood_id);
  }

  return ids;
};

// eslint-disable-next-line max-len
const storeHotels = (hotels: ApiList<Availability>): { type: string, payload: { items: ApiList<Availability> } } => (
  {
    type: STORE_HOTELS,
    payload: { items: hotels },
  }
);

// eslint-disable-next-line max-len
const receiveHotels = (page: number, params: AvailabilitySearchParams) => ((dispatch: Dispatch, getState: () => RootState): void => {
  if (!params) {
    dispatch(fetchingHotelsEnd());
    return;
  }

  dispatch(fetchingHotelsStart());

  const avNeighborhoodsRoot: Array<number> = getState().avNeighborhoods;

  hotelsRemote.getAvailability(page, params)
    .then((resp: { errors: Array<AvailabilityError>, hotels: ApiList<Availability> }) => {
      if (resp && resp.hotels) {
        dispatch(storeHotels(resp.hotels));

        // eslint-disable-next-line max-len
        const neighborhoodIds: Array<number> = getNeighborhoodsFromTheAvailability(resp.hotels.content);
        
        /**
         * this will prevent updating the ref of `availabilityNeighborhoodsRoot` in the
         * hotelsPage useEffect upon the same ids for the neighborhoodIds
        */
        // eslint-disable-next-line max-len
        if (avNeighborhoodsRoot.toString() !== neighborhoodIds.toString() || neighborhoodIds.length === 0) {
          dispatch(storeAvailabilityNeighborhoods(neighborhoodIds));
        }

        const currentSearchId: string | null = sessionStorage.getItem('cid');
    
        if (currentSearchId) {
          sessionStorage.setItem('pid', currentSearchId);
        }
      }

      dispatch(fetchingHotelsEnd());
    })
    .catch(() => {
      dispatch(storeHotels(getEmptyApiList()));
      dispatch(fetchingHotelsEnd());
    });
});

// eslint-disable-next-line max-len
const storeHotelsCoordinates = (hotels: ApiList<AvailabilityLight>): { type: string, payload: { items: ApiList<AvailabilityLight> } } => (
  {
    type: STORE_HOTELS_COORDINATES,
    payload: { items: hotels },
  }
);

// eslint-disable-next-line max-len
const receiveHotelsCoordinates = (page: number, params: AvailabilitySearchParams) => ((dispatch: Dispatch): void => {
  if (!params) {
    dispatch(fetchingHotelsCoordinatesEnd());
  }

  dispatch(fetchingHotelsCoordinatesStart());

  hotelsRemote.getAvailabilityLight(page, params)
    .then((resp: { errors: Array<AvailabilityError>, hotels: ApiList<AvailabilityLight> }) => {
      if (resp && resp.hotels) {
        dispatch(storeHotelsCoordinates(resp.hotels));
      }

      dispatch(fetchingHotelsCoordinatesEnd());
    })
    .catch(() => {
      dispatch(storeHotelsCoordinates(getEmptyApiList()));
      dispatch(fetchingHotelsCoordinatesEnd());
    });
});

// eslint-disable-next-line max-len
const receiveHotelsAndCoordinatesOnMapChange = (mapCoordinates: MapCoordinatesNESW) => ((dispatch: Dispatch, getState: () => RootState): void => {
  dispatch(fetchingHotelsAndCoordinatesStart());

  const firstPage = 0;
  const params: AvailabilitySearchParams = { ...getState().availabilityParams };
  const searchCriteria: AvailabilitySearchCriteriaIdsParams = {
    ...(params.search_criteria as AvailabilitySearchCriteriaIdsParams),
  };

  if (searchCriteria.property_filter) {
    const propertyFilter: AvailabilitySearchPropertyFilter = { ...searchCriteria.property_filter };

    propertyFilter.coordinate_range = {
      upper_right: {
        latitude: mapCoordinates.ne.lat,
        longitude: mapCoordinates.ne.lng,
      },
      lower_left: {
        latitude: mapCoordinates.sw.lat,
        longitude: mapCoordinates.sw.lng,
      },
    };

    searchCriteria.property_filter = propertyFilter;
  }

  params.search_criteria = searchCriteria;

  hotelsRemote.getAvailabilityLight(firstPage, params)
    .then((resp: { errors: Array<AvailabilityError>, hotels: ApiList<AvailabilityLight> }) => {
      if (resp && resp.hotels) {
        dispatch(storeHotelsCoordinates(resp.hotels));

        if (searchCriteria.property_filter) {
          /**
           * Set `coordinate_range` to null, to prevent fetch availability light in
           * hotelsMapContainer on searchParams changed from the line below with
           * action: storeAvailabilitySearchParams. Don't need to keep this filter
           * in the state at this moment. Also availability and availabilityLight will
           * be with the same response, based on the same search params.
           */
          searchCriteria.property_filter.coordinate_range = null;
        }

        dispatch(storeAvailabilitySearchParams(params));
      }

      dispatch(fetchingHotelsCoordinatesEnd());
    })
    .catch(() => {
      dispatch(storeHotelsCoordinates(getEmptyApiList()));
      dispatch(fetchingHotelsCoordinatesEnd());
    });

  hotelsRemote.getAvailability(firstPage, params)
    .then((resp: { errors: Array<AvailabilityError>, hotels: ApiList<Availability> }) => {
      if (resp && resp.hotels) {
        dispatch(storeHotels(resp.hotels));
      }

      dispatch(fetchingHotelsEnd());
    })
    .catch(() => {
      dispatch(storeHotels(getEmptyApiList()));
      dispatch(fetchingHotelsEnd());
    });
});

export {
  receiveHotels,
  receiveHotelsCoordinates,
  receiveHotelsAndCoordinatesOnMapChange,
};
