import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Card,
  CardContent,
} from '../../common';

import CheckInCheckOut from '../checkInCheckOut/CheckInCheckOut';
import RoomsWidget from '../roomsWidget/RoomsWidget';

import { useCommonSearchLogic } from '../hooks/useCommonSearchLogic';
import { useLogic } from './hooks/useLogic';

import useStyles from './hotelDetailsSearchBarStyles';

const HotelDetailsSearchBar: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    dateRanges,
    hasCheckInCheckOutError,
    rooms,
    onSetDateRanges,
    onSetRooms,
    setCheckInCheckOutHasAnError,
  } = useCommonSearchLogic();
  const {
    isHotelFetchingRoot,
    onSearchButtonClicked,
  } = useLogic(dateRanges, rooms, setCheckInCheckOutHasAnError);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.form}>
          <Box className={classes.inputContainer}>

            <Box className={classes.checkInCheckOutContainer}>
              <CheckInCheckOut
                hasCheckInCheckOutError={hasCheckInCheckOutError}
                setCheckInCheckError={setCheckInCheckOutHasAnError}
                setDateRanges={onSetDateRanges}
              />
            </Box>

            <Box className={classes.roomsContainer}>
              <RoomsWidget rooms={rooms} onSetRooms={onSetRooms} />
            </Box>

            <Box className={classes.buttonContainer}>
              <Button
                disabled={isHotelFetchingRoot}
                color="primary"
                variant="contained"
                type="submit"
                onClick={onSearchButtonClicked}
              >
                <Box fontWeight="fontWeightBold">
                  {t('widgetSearchButtonRefine')}
                </Box>
              </Button>
            </Box>

          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const MemoizedHotelDetailsSearchBar = React.memo(HotelDetailsSearchBar);
export default MemoizedHotelDetailsSearchBar;
