import genericApi from '../genericApi';

import { PointOfInterest } from '../../models';
import { PORTAL_BE_ROUTE_PREFIX } from '../../configs/environments';

const pointsOfInterestRemote = {

  getPropertyPoints: (cityId: number, propertyId: number, limit: number, unit: string): Promise<Array<PointOfInterest>> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/poi/${cityId}/?propertyId=${propertyId}&limit=${limit}&unit=${unit}`),

  getFilterPoints: (cityId: number): Promise<Array<PointOfInterest>> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/poi/${cityId}?sort=name`),
};

export default pointsOfInterestRemote;
