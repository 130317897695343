const titles: Array<{ label: string; value: string; }> = [
  {
    label: 'mr',
    value: 'Mr.',
  },
  {
    label: 'mrs',
    value: 'Mrs.',
  },
  {
    label: 'mstr',
    value: 'Mstr.',
  },
  {
    label: 'ms',
    value: 'Ms.',
  },
];

export default titles;
