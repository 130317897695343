import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LocationOn as LocationIcon } from '@material-ui/icons';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
} from '../../common';

import FullScreenMapDialog from '../fullScreenMapDialog/FullScreenMapDialog';

import { useLogic } from './hooks/useLogic';
import { useOpenMap } from '../../../hooks/useOpenMap';
import { useLocationAsFakeAvailability } from './hooks/useLocationAsFakeAvailability';

import useStyles from './hotelsMapContainerStyles';
import mapImage from '../../../assets/map.png';

const ZOOM_LEVEL = 13;

const HotelsMapContainer: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { openMap, handleOpenMap, handleCloseMap } = useOpenMap();
  const { locationRoot, isFetching, hotels } = useLogic(handleCloseMap);
  const { hotelsWithPopularLocation } = useLocationAsFakeAvailability(openMap, hotels);

  return (
    <>
      <Card className={classes.hotelsMapCard}>
        <CardContent className={classes.hotelsMapCardContainer}>
          <CardMedia
            component="img"
            alt="map image"
            image={mapImage}
            className={classes.mapImage}
          />
          <Box className={classes.pinAndButtonContent}>
            <LocationIcon className={classes.locationPinIcon} />
            <Button variant="contained" color="primary" onClick={handleOpenMap}>
              {t('showOnMapBtnTitle')}
            </Button>
          </Box>
        </CardContent>
      </Card>
      { openMap && (
        <FullScreenMapDialog
          defaultCoordinates={{
            lat: hotels[0]?.coordinates?.latitude || 0,
            lng: hotels[0]?.coordinates?.longitude || 0,
          }}
          isFetchingListCoordinates={isFetching}
          listCoordinates={hotelsWithPopularLocation}
          open={openMap}
          title={`${locationRoot.location_name}, ${locationRoot.destination_name}, ${locationRoot.country_name}`}
          handleClose={handleCloseMap}
          zoom={ZOOM_LEVEL}
        />
      )}
    </>
  );
};

const MemoizedHotelsMapContainer = React.memo(HotelsMapContainer);
export default MemoizedHotelsMapContainer;
