import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactImageGalleryItem } from 'react-image-gallery';

import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {
  Box, Button, Card, CardContent, Collapse,
} from '../../../../common';

import AmenityIcon from '../../../../common/amenityIcon/AmenityIcon';
import HotelLoadingCard from '../../../hotelsLoading/hotelLoadingCard/HotelLoadingCard';
import PropertyRoomGallery from '../../propertyRoomGallery/PropertyRoomGallery';

import { Amenity, AvailabilityProduct } from '../../../../../models';

import { useLogic } from '../hooks/useLogic';

import { currencySign } from '../../../../../utils/currencyMapper';
import { CURRENCY_HUNDRED_UNIT } from '../../../../../configs/environments';
import useStyles from './propertyRoomStyles';

const ROOM_AMENITIES_INITIAL_ITEMS = 3;

// eslint-disable-next-line max-len
const renderInitialAmenityItems = (amenityItems: Array<Amenity>, classes: ClassNameMap): Array<ReactNode> => {
  const items: Array<ReactNode> = [];
  const length = amenityItems.length >= ROOM_AMENITIES_INITIAL_ITEMS
    ? ROOM_AMENITIES_INITIAL_ITEMS
    : amenityItems.length;

  for (let i = 0; i < length; i += 1) {
    items.push(
      <Box key={amenityItems[i].amenityId} className={classes.roomAmenityContainer}>
        <AmenityIcon factId={amenityItems[i].amenityId} />
        <Box className={classes.roomAmenityTextContainer}>{amenityItems[i].amenityName}</Box>
      </Box>,
    );
  }
  return items;
};

// eslint-disable-next-line max-len
const renderRestAmenityItems = (amenityItems: Array<Amenity>, classes: ClassNameMap): Array<ReactNode> => {
  const items: Array<ReactNode> = [];
  const { length } = amenityItems;

  if (length <= ROOM_AMENITIES_INITIAL_ITEMS) return null;

  for (let i = ROOM_AMENITIES_INITIAL_ITEMS; i < length; i += 1) {
    items.push(
      <Box key={amenityItems[i].amenityId} className={classes.roomAmenityContainer}>
        <AmenityIcon factId={amenityItems[i].amenityId} />
        <Box className={classes.roomAmenityTextContainer}>{amenityItems[i].amenityName}</Box>
      </Box>,
    );
  }
  return items;
};

interface PropertyRoomProps {
  amenities: Array<Amenity>;
  guests: { rooms: number; adults: number; children: number; };
  imageIndex: number;
  images: Array<ReactImageGalleryItem> | null;
  product: AvailabilityProduct;
  propertyId: number;
}

const PropertyRoom: FC<PropertyRoomProps> = (props: PropertyRoomProps) => {
  const {
    amenities,
    guests,
    imageIndex,
    images,
    product,
    propertyId,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    expanded,
    onBookButtonClicked,
    onExpandCollapse,
  } = useLogic(product, propertyId);

  const { length } = amenities;
  const showMoreLessButton: boolean = amenities.length > ROOM_AMENITIES_INITIAL_ITEMS;

  try {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box width="100%">
            <Box className={classes.nameAndPriceContainer}>
              <Box className={classes.roomNameContainer}>
                <Box className={classes.nameContainer}>{product.room_type.description}</Box>
              </Box>
              <Box fontSize={17} fontWeight={600} className={classes.priceContainer}>
                { product.rate && (
                  <Box>
                    {currencySign(product.rate.currency)}
                    {product.rate.amount / CURRENCY_HUNDRED_UNIT}
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.content}>
              <Box className={classes.galleryContainer}>
                <PropertyRoomGallery imageIndex={imageIndex} images={images} />
              </Box>
              <Box className={classes.amenitiesContainer}>
                { length > 0 && (
                  <>
                    <Box component="span" fontWeight="bold" mb={0.5}>{`${t('propertyDetailsRoomAmenitiesTitle')}: `}</Box>
                    <Box className={classes.roomAmenitiesContainer}>
                      { renderInitialAmenityItems(amenities, classes) }
                    </Box>
                    <Collapse in={expanded} timeout={500} unmountOnExit>
                      <CardContent className={classes.collapseCardContent}>
                        <Box className={classes.roomAmenitiesContainer}>
                          { renderRestAmenityItems(amenities, classes) }
                        </Box>
                      </CardContent>
                    </Collapse>
                    { showMoreLessButton && (
                      <Box className={classes.moreLessContainer}>
                        <Button className={classes.moreLessButton} onClick={onExpandCollapse}>
                          {expanded ? t('propertyDetailsShowLess') : t('propertyDetailsShowMore')}
                        </Button>
                      </Box>
                    ) }
                  </>
                )}
                <Box component="span" fontWeight="bold">{`${t('bookingReservationInclusionsText')}: `}</Box>
                <Box component="span" pb={'5px'}>{product.room_stays[0].meal_plan}</Box>
                <Box component="span" fontWeight="bold">{`${t('propertyDetailsCancellationPolicyTitle')}: `}</Box>
                <Box component="span">{product.room_stays[0].cancellation_string}</Box>
              </Box>
              <Box className={classes.pricesContainer}>
                <Box className={classes.guestsContainer}>
                  <Box>{`${guests.rooms} ${t(guests.rooms > 1 ? 'propertyDetailsProductRoomsLabel' : 'propertyDetailsProductRoomLabel')}`}</Box>
                  <Box>{`${guests.adults} ${t('propertyDetailsProductAdultsLabel')}, ${guests.children} ${t('propertyDetailsProductChildrenLabel')}`}</Box>
                </Box>
                <Box className={classes.sourceContainer}>
                  <Box component="span" fontWeight="bold">{`${t('propertyDetailsProductSource')}: `}</Box>
                  <Box component="span">{product.lot}</Box>
                </Box>
                <Box className={classes.buttonContainer}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onBookButtonClicked}
                  >
                    {t('bookNowButtonTitle')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  } catch {
    return <Box mt={1}><HotelLoadingCard /></Box>;
  }
};

export default PropertyRoom;
