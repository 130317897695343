import React, { FC } from 'react';

import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import { Box } from '../../../common';

import DefaultNoImage from '../../../common/defaultNoImage/DefaultNoImage';

interface PropertyRoomGalleryProps {
  imageIndex: number;
  images: Array<ReactImageGalleryItem> | null;
}

const PropertyRoomGallery: FC<PropertyRoomGalleryProps> = (props: PropertyRoomGalleryProps) => {
  const { imageIndex, images } = props;

  return (
    <>
      { images && images.length > 0 ? (
        <ImageGallery
          items={images}
          startIndex={imageIndex}
          showBullets={false}
          showFullscreenButton={false}
          showNav={true}
          showPlayButton={false}
          showThumbnails={false}
        />
      ) : (
        <Box width={'290px'}>
          <DefaultNoImage />
        </Box>
      )}
    </>
  );
};

export default PropertyRoomGallery;
