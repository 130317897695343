import { SET_SELECTED_RADIUS } from '../actions/actionTypes';

import RadiusSelection, { defaultRadiusSelected } from '../../models/RadiusSelection';

const initialState: RadiusSelection = defaultRadiusSelected;

export default (
  state: RadiusSelection = initialState, action: { type: string; payload: RadiusSelection },
): RadiusSelection => {
  if (action.type === SET_SELECTED_RADIUS) {
    return { ...action.payload };
  }

  return state;
};
