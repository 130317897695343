import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    iconButton: {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
      '&:hover': {
        color: theme.palette.background.default,
        backgroundColor: '#000',
      },
    },
    valueContainer: {
      marginLeft: 5,
      marginRight: 5,
    },
    value: {
      marginTop: 1,
      fontSize: 20,
      fontWeight: 'bold',
    },
  },
));
