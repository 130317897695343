import React, {
  FC, ReactNode, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Autosuggest from 'react-autosuggest';
import { defaultTheme } from 'react-autosuggest/dist/theme';

import debounce from 'lodash/debounce';

import { destinationRemote } from '../../../api/services';

import LocationInput from './locationInput/LocationInput';
import LocationSuggestions from './locationSuggestions/LocationSuggestions';

import Location, { dummyLocation } from '../../../models/Location';

import configs from '../../../configs/environments';
import useStyles from './locationAutoSuggestStyles';

import { CancelablePromise, makePromiseCancelable } from '../../../utils/makePromiseCancelable';

interface LocationAutoSuggestProps {
  locationOnFocus: boolean;
  onChange: (suggestion: Location) => void;
  onFocus: () => void;
  suggestion: Location;
}

const suggestionDisplayFormat = (suggestion: Location): string => `${suggestion.location_name}, ${suggestion.destination_name}, ${suggestion.country_name}`;

const LocationAutoSuggest: FC<LocationAutoSuggestProps> = (props: LocationAutoSuggestProps) => {
  const {
    locationOnFocus,
    onFocus,
    onChange,
    suggestion,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [stateValue, setValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Array<Location>>([]);
  const [hasSuggestions, setHasSuggestions] = useState<boolean>(false);

  let cancelableDestination: CancelablePromise;

  useEffect(() => {
    if (suggestion && suggestion.id !== 'null') {
      const suggestionAsString = suggestionDisplayFormat(suggestion);
      setValue(suggestionAsString);
      setHasSuggestions(true);
    }

    return () => {
      if (cancelableDestination) {
        cancelableDestination.cancel();
      }
    };
  // eslint-disable-next-line
  }, [suggestion]);

  const onInputBlur = (): void => {
    if (suggestions.length > 0) {
      onChange(suggestions[0]);
    }
  };

  const onInputChange = (event, { newValue }): void => {
    if (!newValue.trim()) {
      onChange(dummyLocation);
    }

    setValue(newValue);
  };

  const onInputFocus = (): void => {
    onFocus();
  };

  // eslint-disable-next-line
  const debouncedGetSuggestions = useCallback(
    debounce((value: string) => {
      if (value.trim().length < 2) {
        return;
      }

      cancelableDestination = makePromiseCancelable(
        destinationRemote.getDestination(value).then((resp: Array<Location>) => {
          if (resp) {
            setSuggestions(resp);
            setHasSuggestions(resp.length > 0);
          } else {
            setSuggestions([]);
            setHasSuggestions(false);
          }
        }).catch(() => {
          setSuggestions([]);
          setHasSuggestions(false);
        }),
      );
      cancelableDestination.promise.then().catch((reason) => reason.isCanceled);
    }, configs.locationSearchDebounceMs),
    [],
  );

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (passedSuggestion: Location) => (
    passedSuggestion
      ? suggestionDisplayFormat(passedSuggestion)
      : ''
  );

  const shouldRenderSuggestions = (value: string, reason: string): boolean => (
    value.trim().length > 0 && reason !== 'input-focused'
  );

  // Use your imagination to render suggestions.
  const renderSuggestion = (passedSuggestion: Location) => (
    <LocationSuggestions suggestion={passedSuggestion} />
  );

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }): void => {
    debouncedGetSuggestions(value);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = (): void => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, autosuggestParams): void => (
    onChange(autosuggestParams.suggestion)
  );

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: t('locationPlaceholder'),
    value: stateValue,
    onBlur: onInputBlur,
    onChange: onInputChange,
    onFocus: onInputFocus,
  };

  const renderInputComponent = (autosuggestProps): ReactNode => (
    <LocationInput
      autosuggestProps={autosuggestProps}
      hasSuggestions={hasSuggestions}
      locationOnFocus={locationOnFocus}
    />
  );

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      shouldRenderSuggestions={shouldRenderSuggestions}
      getSuggestionValue={getSuggestionValue}
      inputProps={inputProps}
      multiSection={false}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      theme={{
        ...defaultTheme,
        container: classes.react_autosuggest__container,
        suggestionsContainerOpen: classes.react_autosuggest__suggestions_container__open,
        suggestionsList: classes.react_autosuggest__suggestions_list,
        suggestionHighlighted: classes.react_autosuggest__suggestion__highlighted,
      }}
    />
  );
};

export default LocationAutoSuggest;
