import genericApi from '../genericApi';

import {
  ApiList,
  Availability,
  AvailabilityError,
  AvailabilityLight,
  AvailabilitySearchParams,
  PropertyDetails,
  PropertyFactN,
} from '../../models';

import { PAGINATION_PAGE_SIZE, PORTAL_BE_ROUTE_PREFIX } from '../../configs/environments';

const AVAILABILITY_ROUTE_PREFIX = 'availability';

const hotelsRemote = {
  // eslint-disable-next-line
  getAvailability: (page: number, searchParams: AvailabilitySearchParams): Promise<{ errors: Array<AvailabilityError>, hotels: ApiList<Availability> }> => (
    genericApi.post(`${PORTAL_BE_ROUTE_PREFIX}/api/${AVAILABILITY_ROUTE_PREFIX}?size=${PAGINATION_PAGE_SIZE}&page=${page}`, searchParams)
  ),
  getProperty: (propertyId: number): Promise<PropertyDetails> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/hotels/${propertyId}?isDetailed=true`),
  getPropertyFacts: (propertyId: number): Promise<Array<PropertyFactN>> => genericApi.get(`${PORTAL_BE_ROUTE_PREFIX}/api/hotels/${propertyId}/facts`),
  // eslint-disable-next-line
  getAvailabilityLight: (page: number, searchParams: AvailabilitySearchParams): Promise<{ errors: Array<AvailabilityError>, hotels: ApiList<AvailabilityLight> }> => (
    genericApi.post(`${PORTAL_BE_ROUTE_PREFIX}/api/${AVAILABILITY_ROUTE_PREFIX}?size=${PAGINATION_PAGE_SIZE}&page=${page}&lightweight=true`, searchParams)
  ),
  // TODO: This should not be a separate function, but the same as `getAvailability`.
  // If pagination is present for the Product, then this should be removed
  // eslint-disable-next-line
  getAvailabilityProducts: (searchParams: AvailabilitySearchParams): Promise<{ errors: Array<AvailabilityError>, hotels: ApiList<Availability> }> => (
    genericApi.post(`${PORTAL_BE_ROUTE_PREFIX}/api/${AVAILABILITY_ROUTE_PREFIX}?size=${1000}`, searchParams)
  ),
};

export default hotelsRemote;
