import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LocationOn as LocationIcon } from '@material-ui/icons';

import { TextField, InputAdornment } from '../../../common';

import { useLogic } from './hooks/useLogic';

import useStyles from './locationInputStyles';

interface LocationInputProps {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  autosuggestProps: any;
  hasSuggestions: boolean;
  locationOnFocus: boolean;
}

/* eslint-disable react/jsx-props-no-spreading */
const LocationInput: FC<LocationInputProps> = (props: LocationInputProps) => {
  const { autosuggestProps, hasSuggestions, locationOnFocus } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    hasAnError, renderErrorText, locationIconColor,
  } = useLogic(autosuggestProps, classes, hasSuggestions, locationOnFocus, t);

  return (
    <TextField
      fullWidth
      variant="filled"
      label={t('locationLabel')}
      margin="normal"
      classes={{ root: classes.root }}
      InputProps={{
        inputRef: (node) => {
          autosuggestProps.ref(node);
        },
        startAdornment: (
          <InputAdornment position="start">
            <LocationIcon className={locationIconColor()} />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
      error={hasAnError()}
      helperText={renderErrorText()}
      {...autosuggestProps}
    />
  );
};

export default LocationInput;
