import React, { FC } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { LocationOn as LocationIcon } from '@material-ui/icons';

import { Box } from '../../../common';

import { PointOfInterest } from '../../../../models';

const useStyles = makeStyles(() => createStyles({
  pointContent: {
    marginBottom: 12,
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

interface PropertyPointsOfInterestProps {
  poi: Array<PointOfInterest>;
}

const PropertyPointsOfInterest: FC<PropertyPointsOfInterestProps> = (
  props: PropertyPointsOfInterestProps,
) => {
  const { poi } = props;
  const classes = useStyles();

  try {
    return (
      <>
        { poi.map((point: PointOfInterest) => (
          <Box key={point.id} className={classes.pointContent}>
            <LocationIcon />
            <Box ml={'10px'} fontSize={'16px'}>{`${point.name}, ${point.distance} ${point.unit}`}</Box>
          </Box>
        )) }
      </>
    );
  } catch {
    return <Box />;
  }
};

const MemoizedPropertyPointsOfInterest = React.memo(PropertyPointsOfInterest);
export default MemoizedPropertyPointsOfInterest;
