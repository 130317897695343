import React, { FC, ReactNode } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '22px 0',
  },
}));

interface HeaderProps {
  children: ReactNode;
}

const Header: FC<HeaderProps> = (props: HeaderProps) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <header className={classes.header}>
      {children}
    </header>
  );
};

export default Header;
