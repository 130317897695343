import { Room } from '../../models';

import configs from '../../configs/environments';

const ADULTS_DEFAULT = configs.room.adults.default;
const CHILDREN_DEFAULT = configs.room.children.default;

const newRoom: Room = {
  agesOfChild: [],
  adults: ADULTS_DEFAULT,
  children: CHILDREN_DEFAULT,
};

export default function roomsPersistor(): Array<Room> {
  let rooms: Array<Room> = [newRoom];
  const sessionRooms: string | null = sessionStorage.getItem('rooms');

  if (sessionRooms) {
    rooms = JSON.parse(sessionRooms);
  }

  return rooms;
}
