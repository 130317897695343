import React, { FC, ReactNode } from 'react';

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Link,
} from '../..';

import { HOME_ROUTE_PATH } from '../../../../routes/routesPath';

import defaultBrandImage from '../../../../assets/brand.png';
import useStyles from './headerCommonStyles';

interface HeaderCommonProps {
  children: ReactNode,
}

const HeaderCommon: FC<HeaderCommonProps> = (props: HeaderCommonProps) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <header>
      <Card classes={{ root: classes.cardRoot }}>
        <CardContent>
          <Box className={classes.content}>
            <Box className={classes.logoContainer}>
              <Link href={HOME_ROUTE_PATH}>
                <CardMedia
                  className={classes.brandImage}
                  component="img"
                  alt="Room Rocket"
                  image={defaultBrandImage}
                />
              </Link>
            </Box>
            <Box className={classes.titleContainer}>{children}</Box>
          </Box>
        </CardContent>
      </Card>
    </header>
  );
};

export default HeaderCommon;
