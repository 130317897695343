import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '../../../common';

import ClearFilters from '../clearFilters/ClearFilters';

import { useRuntimeTypeGuards } from './hooks/useRuntimeTypeGuards';

import { INITIAL_FILTER_ITEMS } from '../../../../configs/environments';

import useStyles from './commonRadioFilterStyles';

interface CommonCommonRadioFilterProp<T> {
  expanded: boolean;
  items: Array<T>;
  itemsMoreLess: Array<T>;
  selectedItem: string | null
  title: string;
  onExpandCollapse: () => void,
  onItemSelected: (event: ChangeEvent<HTMLInputElement>) => void,
  onItemSelectionClear: () => void,
}

// eslint-disable-next-line
const CommonRadioFilter = <T extends object>(props: CommonCommonRadioFilterProp<T>) => {
  const {
    expanded,
    items,
    itemsMoreLess,
    selectedItem,
    title,
    onExpandCollapse,
    onItemSelected,
    onItemSelectionClear,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    isTypeSafe, itemPropId, itemPropName,
  } = useRuntimeTypeGuards(items);

  return (
    <>
      { isTypeSafe && items.length > 0 && (
        <>
          <Box className={classes.titleContent}>
            <Box fontWeight="bold" lineHeight={1.8}>{t(title)}</Box>
            <ClearFilters showClear onSelectionClear={onItemSelectionClear} />
          </Box>
          <FormControl className={classes.form}>
            <RadioGroup
              aria-label={title}
              className={classes.radioGroup}
              value={selectedItem}
              onChange={onItemSelected}
            >
              { itemsMoreLess.map((item: T) => (
                <FormControlLabel
                  key={item[itemPropId]}
                  value={item[itemPropId]}
                  control={<Radio color="default" classes={{ root: classes.radio }} />}
                  label={<Box fontSize="14px">{item[itemPropName]}</Box>}
                  className={classes.formControlLabel}
                />
              ))}
            </RadioGroup>
            <Box marginLeft="auto">
              { items.length > INITIAL_FILTER_ITEMS && (
                <Button className={classes.moreLessButton} onClick={onExpandCollapse}>
                  {expanded ? t('propertyDetailsShowLess') : t('propertyDetailsShowMore')}
                </Button>
              ) }
            </Box>
          </FormControl>
        </>
      )}
    </>
  );
};

export default CommonRadioFilter;
