import React, { FC } from 'react';

import { Box } from '../../../common';

import { PropertyAddress as PropertyAddressModel } from '../../../../models';

interface PropertyAddressProps {
  address: PropertyAddressModel;
}

const PropertyAddress: FC<PropertyAddressProps> = (props: PropertyAddressProps) => {
  const { address } = props;

  const streetNumber: string = address.street_number || '';
  const street: string = address.street || '';
  const city: string = address.city_name || '';
  const countryName: string = address.country_code.countryName || '';

  try {
    return (
      <>
        { streetNumber && street && <Box>{`${streetNumber} ${street},`}</Box> }
        { streetNumber && !street && <Box>{`${streetNumber},`}</Box> }
        { !streetNumber && street && <Box>{`${street},`}</Box> }
        { city && countryName && <Box>{`${city}, ${countryName}`}</Box> }
        { city && !countryName && <Box>{`${city},`}</Box> }
        { !city && countryName && <Box>{`${countryName},`}</Box> }
      </>
    );
  } catch {
    return <Box />;
  }
};

const MemoizedPropertyAddress = React.memo(PropertyAddress);
export default MemoizedPropertyAddress;
