import {
  REQUEST_HOTELS_AND_COORDINATES_START,
  REQUEST_HOTELS_COORDINATES_END,
  REQUEST_HOTELS_COORDINATES_START,
  REQUEST_HOTELS_END,
  REQUEST_HOTELS_START,
} from './actionTypes';

const fetchingHotelsAndCoordinatesStart = (): { type: string } => (
  { type: REQUEST_HOTELS_AND_COORDINATES_START }
);

const fetchingHotelsCoordinatesEnd = (): { type: string } => (
  { type: REQUEST_HOTELS_COORDINATES_END }
);

const fetchingHotelsCoordinatesStart = (): { type: string } => (
  { type: REQUEST_HOTELS_COORDINATES_START }
);

const fetchingHotelsEnd = (): { type: string } => (
  { type: REQUEST_HOTELS_END }
);

const fetchingHotelsStart = (): { type: string } => (
  { type: REQUEST_HOTELS_START }
);

export {
  fetchingHotelsAndCoordinatesStart,
  fetchingHotelsCoordinatesEnd,
  fetchingHotelsCoordinatesStart,
  fetchingHotelsEnd,
  fetchingHotelsStart,
};
