import {
  Reducer, useEffect, useMemo, useReducer,
} from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { ReactImageGalleryItem } from 'react-image-gallery';

import {
  Amenity,
  ApiList,
  Availability,
  AvailabilityError,
  AvailabilitySearchParams,
  CheckInCheckOut as CheckInCheckOutModel,
  ImagesInfo,
  PointOfInterest,
  PropertyDetails as PropertyDetailsModel,
  PropertyFactN,
  Room as RoomModel,
} from '../../../../../../models';

import { amenityRemote, hotelsRemote, pointsOfInterestRemote } from '../../../../../../api/services';

import { RootState } from '../../../../../../store/reducers';

import { useMeasurementUnit } from '../../../../../../hooks/useMeasurementUnit';

import {
  DEFAULT_FALLBACK_CURRENCY, IMAGES_BASE_URL, IMAGE_EXTENSION, IMAGE_ORIGINAL_SIZE,
  IMAGE_SIZE_SMALL, POINT_OF_INTEREST_LIMIT, ROOM_AMENITY_IDS,
} from '../../../../../../configs/environments';

import { constructAvailabilityRequestParams } from '../../../../../../utils/availabilitySearchParams';
import { CancelablePromise, makePromiseCancelable } from '../../../../../../utils/makePromiseCancelable';

const extractImagesUrl = (
  propertyId: number, imagesJson: ImagesInfo,
): {
  allImages: Array<ReactImageGalleryItem>,
  accommodationImages: Array<ReactImageGalleryItem>,
} => {
  const imgBaseUrl = `${IMAGES_BASE_URL}/${propertyId}`;

  const mainImageItem: ReactImageGalleryItem = {
    original: `${imgBaseUrl}/main${IMAGE_ORIGINAL_SIZE}.${IMAGE_EXTENSION}`,
    thumbnail: `${imgBaseUrl}/main${IMAGE_SIZE_SMALL}.${IMAGE_EXTENSION}`,
  };

  const imageItems: Array<ReactImageGalleryItem> = [mainImageItem];
  const accommodationItems: Array<ReactImageGalleryItem> = [];

  const entries: Array<[string, number | undefined]> = Object.entries(imagesJson.types);
  // eslint-disable-next-line
  for (const [key, value] of entries) {
    if (!value) break;

    for (let i = 0; i < value; i += 1) {
      const imgUrl = `${imgBaseUrl}/${key}_${i + 1}`;

      const image: ReactImageGalleryItem = {
        original: `${imgUrl}${IMAGE_ORIGINAL_SIZE}.${IMAGE_EXTENSION}`,
        thumbnail: `${imgUrl}${IMAGE_SIZE_SMALL}.${IMAGE_EXTENSION}`,
      };

      if (key === 'accommodation') {
        accommodationItems.push(image);
      }

      imageItems.push(image);
    }
  }

  if (accommodationItems.length === 0) {
    accommodationItems.push(mainImageItem);
  }

  return { allImages: imageItems, accommodationImages: accommodationItems };
};

// BE api configuration, where `public static final int HOTEL_AMENITIES = 2;`
const HOTEL_AMENITY_ID = 2;
// BE api configuration, where `public static final int ROOM_AMENITIES = 3;`
const ROOM_AMENITY_ID = 3;

const extractAmenities = (amenities: Array<Amenity>): {
  extractedProperty: Array<Amenity>,
  extractedRoom: Array<Amenity>,
} => {
  const extractedProperty: Array<Amenity> = [];
  const extractedRoom: Array<Amenity> = [];

  amenities.forEach((amenity: Amenity) => {
    if (ROOM_AMENITY_IDS.includes(amenity.amenityId)) {
      extractedRoom.push(amenity);
    } else {
      extractedProperty.push(amenity);
    }
  });

  return { extractedProperty, extractedRoom };
};

enum ActionType {
  FETCHING_AVAILABILITY = 'FETCHING_AVAILABILITY',
  SET_ALL_IMAGES = 'SET_ALL_IMAGES',
  SET_AVAILABILITY = 'SET_AVAILABILITY',
  SET_PROPERTY_IMAGES = 'SET_PROPERTY_IMAGES',
  SET_PROPERTY_AND_ROOM_AMENITIES = 'SET_PROPERTY_AND_ROOM_AMENITIES',
  SET_PROPERTY_POI = 'SET_PROPERTY_POI',
  SET_PROPERTY_POLICIES = 'SET_PROPERTY_POLICIES',
}

interface State {
  fetchingAvailability: boolean;
  propertyAmenities: Array<Amenity>;
  propertyAvailability: Array<Availability>;
  propertyPoi: Array<PointOfInterest>;
  propertyPolicies: Array<PropertyFactN>;
  propertyImages: Array<ReactImageGalleryItem> | null;
  roomAmenities: Array<Amenity>;
  roomImages: Array<ReactImageGalleryItem> | null;
}

const initialState: State = {
  fetchingAvailability: true,
  propertyAmenities: [],
  propertyAvailability: [],
  propertyPoi: [],
  propertyPolicies: [],
  propertyImages: null,
  roomAmenities: [],
  roomImages: null,
};

type Action =
  | { type: ActionType.FETCHING_AVAILABILITY }
  // eslint-disable-next-line max-len
  | { type: ActionType.SET_ALL_IMAGES, propertyImages: Array<ReactImageGalleryItem>, roomImages: Array<ReactImageGalleryItem> }
  | { type: ActionType.SET_AVAILABILITY, propertyAvailability: Array<Availability> }
  | { type: ActionType.SET_PROPERTY_IMAGES, propertyImages: Array<ReactImageGalleryItem> }
  // eslint-disable-next-line max-len
  | { type: ActionType.SET_PROPERTY_AND_ROOM_AMENITIES, propertyAmenities: Array<Amenity>, roomAmenities: Array<Amenity> }
  | { type: ActionType.SET_PROPERTY_POI, propertyPoi: Array<PointOfInterest> }
  | { type: ActionType.SET_PROPERTY_POLICIES, propertyPolicies: Array<PropertyFactN> };

const propertyDetailsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.FETCHING_AVAILABILITY: return {
      ...state,
      fetchingAvailability: true,
    };
    case ActionType.SET_ALL_IMAGES: return {
      ...state,
      propertyImages: [...action.propertyImages],
      roomImages: [...action.roomImages],
    };
    case ActionType.SET_AVAILABILITY: return {
      ...state,
      fetchingAvailability: false,
      propertyAvailability: [...action.propertyAvailability],
    };
    case ActionType.SET_PROPERTY_IMAGES: return {
      ...state,
      propertyImages: [...action.propertyImages],
    };
    case ActionType.SET_PROPERTY_AND_ROOM_AMENITIES: return {
      ...state,
      propertyAmenities: [...action.propertyAmenities],
      roomAmenities: [...action.roomAmenities],
    };
    case ActionType.SET_PROPERTY_POI: return {
      ...state,
      propertyPoi: [...action.propertyPoi],
    };
    case ActionType.SET_PROPERTY_POLICIES: return {
      ...state,
      propertyPolicies: [...action.propertyPolicies],
    };
    default: return state;
  }
};

export const useLogic = (propertyDetails: PropertyDetailsModel): {
  fetchingAvailability: boolean,
  propertyAmenities: Array<Amenity>,
  propertyAvailability: Array<Availability>,
  propertyImages: Array<ReactImageGalleryItem> | null,
  propertyPoi: Array<PointOfInterest>,
  propertyPolicies: Array<PropertyFactN>,
  roomAmenities: Array<Amenity>;
  roomImages: Array<ReactImageGalleryItem> | null,
  memoizedGuests: { rooms: number, adults: number, children: number },
  memoizedMapCoordinates: { lat: number, lng: number },
} => {
  const { measurementUnit } = useMeasurementUnit();
  const dateRangesRoot: CheckInCheckOutModel = useSelector(
    (store: RootState) => store.checkInCheckOut,
  );
  const productsSortByRoot: string = useSelector((store: RootState) => store.sortBy.details);
  const roomsRoot: Array<RoomModel> = useSelector((store: RootState) => store.rooms);
  const userCurrencyRoot: string = useSelector(
    (store: RootState) => store.login.currency?.name || DEFAULT_FALLBACK_CURRENCY,
  );

  const [
    state, dispatch,
  ] = useReducer<Reducer<State, Action>>(propertyDetailsReducer, initialState);
  const {
    fetchingAvailability,
    propertyAmenities,
    propertyAvailability,
    propertyImages,
    propertyPoi,
    propertyPolicies,
    roomAmenities,
    roomImages,
  } = state;

  useEffect(() => {
    const cancelableImages: CancelablePromise = makePromiseCancelable(
      axios.get(`${IMAGES_BASE_URL}/${propertyDetails.property_id}/info.json`)
        .then((info: AxiosResponse<ImagesInfo>) => {
          const { data } = info;
          if (data) {
            const {
              allImages,
              accommodationImages,
            } = extractImagesUrl(propertyDetails.property_id, data);

            dispatch({
              type: ActionType.SET_ALL_IMAGES,
              propertyImages: allImages,
              roomImages: accommodationImages,
            });
          }
        }).catch(() => dispatch({ type: ActionType.SET_PROPERTY_IMAGES, propertyImages: [] })),
    );
    cancelableImages.promise.then().catch((reason) => reason.isCanceled);
    
    const excludeAmenityIds: Array<number> = [HOTEL_AMENITY_ID, ROOM_AMENITY_ID];

    const cancelablePropertyPolicies: CancelablePromise = makePromiseCancelable(
      hotelsRemote.getPropertyFacts(propertyDetails.property_id)
        .then((resp: Array<PropertyFactN>) => {
          if (resp) {
            const extractedPolicies: Array<PropertyFactN> = resp.filter((fact: PropertyFactN) => (
              !excludeAmenityIds.includes(fact.fact_type)
            ));

            dispatch({
              type: ActionType.SET_PROPERTY_POLICIES,
              propertyPolicies: extractedPolicies,
            });
          }
        })
        .catch(() => dispatch({ type: ActionType.SET_PROPERTY_POLICIES, propertyPolicies: [] })),
    );
    cancelablePropertyPolicies.promise.then().catch((reason) => reason.isCanceled);

    const cancelablePropertyAmenities: CancelablePromise = makePromiseCancelable(
      amenityRemote.getPropertyAmenities(propertyDetails.property_id)
        .then((resp: Array<Amenity>) => {
          if (resp) {
            const { extractedProperty, extractedRoom } = extractAmenities(resp);

            dispatch({
              type: ActionType.SET_PROPERTY_AND_ROOM_AMENITIES,
              propertyAmenities: extractedProperty,
              roomAmenities: extractedRoom,
            });
          }
        })
        .catch(() => dispatch({
          type: ActionType.SET_PROPERTY_AND_ROOM_AMENITIES,
          propertyAmenities: [],
          roomAmenities: [],
        })),
    );
    cancelablePropertyAmenities.promise.then().catch((reason) => reason.isCanceled);

    const cancelablePOI: CancelablePromise = makePromiseCancelable(
      pointsOfInterestRemote.getPropertyPoints(
        propertyDetails.city_id.cityId,
        propertyDetails.property_id,
        POINT_OF_INTEREST_LIMIT,
        measurementUnit,
      ).then((resp: Array<PointOfInterest>) => {
        if (resp) {
          dispatch({ type: ActionType.SET_PROPERTY_POI, propertyPoi: resp });
        }
      }).catch(() => dispatch({ type: ActionType.SET_PROPERTY_POI, propertyPoi: [] })),
    );
    cancelablePOI.promise.then().catch((reason) => reason.isCanceled);

    return () => {
      cancelableImages.cancel();
      cancelablePropertyAmenities.cancel();
      cancelablePOI.cancel();
      cancelablePropertyPolicies.cancel();
    };
    // eslint-disable-next-line
  }, [propertyDetails]);

  let cancelableAvailability: CancelablePromise;

  const doFetchAvailability = (): void => {
    if (!dateRangesRoot.checkIn && !dateRangesRoot.checkOut) return;

    dispatch({ type: ActionType.FETCHING_AVAILABILITY });

    const sortOptions: Array<string> = [productsSortByRoot];
    const params: AvailabilitySearchParams = constructAvailabilityRequestParams(
      userCurrencyRoot, dateRangesRoot, roomsRoot, [propertyDetails.property_id], sortOptions,
    );

    cancelableAvailability = makePromiseCancelable(
      hotelsRemote.getAvailabilityProducts(params)
        .then((resp: { errors: Array<AvailabilityError>, hotels: ApiList<Availability> }) => {
          const { hotels } = resp;
          let propAvailability: Array<Availability> = [];

          if (hotels && hotels.content?.length > 0) {
            propAvailability = hotels.content;
          }

          dispatch({ type: ActionType.SET_AVAILABILITY, propertyAvailability: propAvailability });
        })
        .catch(() => dispatch({ type: ActionType.SET_AVAILABILITY, propertyAvailability: [] })),
    );
    cancelableAvailability.promise.then().catch((reason) => reason.isCanceled);
  };

  useEffect(() => {
    doFetchAvailability();

    return () => {
      if (cancelableAvailability) {
        cancelableAvailability.cancel();
      }
    };
  // eslint-disable-next-line
  }, [dateRangesRoot, propertyDetails, roomsRoot, productsSortByRoot]);

  // eslint-disable-next-line
  const memoizedMapCoordinates = useMemo(() => {
    return {
      lat: propertyDetails.latitude,
      lng: propertyDetails.longitude,
    };
  }, [propertyDetails]);

  const memoizedGuests = useMemo(() => {
    const roomsLength = roomsRoot.length;
    let adults = 0;
    let children = 0;

    for (let i = 0; i < roomsLength; i += 1) {
      adults += roomsRoot[i].adults;
      children += roomsRoot[i].children;
    }

    return {
      rooms: roomsRoot.length,
      adults,
      children,
    };
  }, [roomsRoot]);

  return {
    fetchingAvailability,
    propertyAmenities,
    propertyAvailability,
    propertyImages,
    propertyPoi,
    propertyPolicies,
    roomAmenities,
    roomImages,
    memoizedGuests,
    memoizedMapCoordinates,
  };
};
