import { useState } from 'react';

export const useOpenMap = (): {
  openMap: boolean, handleOpenMap: () => void, handleCloseMap: () => void,
} => {
  const [openMap, setOpenMap] = useState<boolean>(false);

  const handleOpenMap = (): void => {
    setOpenMap(true);
  };

  const handleCloseMap = (): void => {
    setOpenMap(false);
  };

  return { openMap, handleOpenMap, handleCloseMap };
};
