import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, CardMedia, Grid } from '../common';

import Header from './layout/Header';
import HotelSearchWidget from '../search/hotelSearchWidget/HotelSearchWidget';
import WelcomeMessage from './welcomeMessage/WelcomeMessage';

import { hotelsCurrentPage } from '../../store/actions/hotelsPageAction';

import { useSignOut } from '../../hooks/useSignOut';

import useStyles from './homeContainerStyles';
import defaultBrandImage from '../../assets/brand.png';

const FIRST_PAGE = 0;

const Home: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { onSignOutClicked } = useSignOut();

  useEffect(() => {
    dispatch(hotelsCurrentPage(FIRST_PAGE));
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Grid container direction="column" item className={classes.body}>
        <Grid container direction="column" item className={classes.container}>
          <Header>
            <CardMedia
              className={classes.brandImage}
              component="img"
              alt="Room Rocket"
              image={defaultBrandImage}
            />
            <Button
              color="inherit"
              className={classes.signOutButton}
              onClick={onSignOutClicked}
            >
              {t('signOut')}
            </Button>
          </Header>
          <Grid container direction="row" className={classes.content}>
            <Grid item className={classes.welcomeMessageContainer}>
              <WelcomeMessage />
            </Grid>
            <Grid item className={classes.searchWidgetContainer}>
              <HotelSearchWidget />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
