import React, { FC } from 'react';

import { Box } from '../../common';

import Hotel from '../hotelListUnit/Hotel';

import { Availability } from '../../../models';

import useStyles from './hotelsStyles';

interface HotelsProps {
  hotels: Array<Availability>;
}

const Hotels: FC<HotelsProps> = (props: HotelsProps) => {
  const { hotels } = props;
  const classes = useStyles();

  return (
    <Box className={classes.hotelsContainer}>
      { hotels.map((hotel: Availability) => (
        <Box key={hotel.property_info.property_id} className={classes.hotelContainer}>
          <Hotel hotel={hotel} />
        </Box>
      ))}
    </Box>
  );
};

const MemoizedHotels = React.memo(Hotels);
export default MemoizedHotels;
