import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    hotelSearchBarContainer: {
      marginTop: 8,
      marginBottom: 2,
    },
    layoutContainer: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        flexWrap: 'none',
        flexDirection: 'column',
      },
      [theme.breakpoints.between('md', 'xl')]: {
        flexWrap: 'none',
        flexDirection: 'row',
      },
    },
    hotelsContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    mapAndFilterContainer: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row-reverse',
      },
    },
    sortByContainer: {
      marginBottom: 8,
      alignSelf: 'flex-end',
      width: '35%',
    },
  },
));
