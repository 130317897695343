// Make sure react-hot-loader is required before react and react-dom
import { hot } from 'react-hot-loader/root';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';

import Routes from './routes/Routes';

const App: React.FunctionComponent = () => (
  <BrowserRouter>
    <CssBaseline />
    <Routes />
  </BrowserRouter>
);

export default hot(App);
