import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { bookingRemote, hotelsRemote, providerRemote } from '../../../../../api/services';

import {
  ApiList,
  BookingCancellationResponse,
  BookingReservation,
  PropertyDetails as PropertyDetailsModel,
  PropertyStoreData,
  ProviderInfo,
} from '../../../../../models';
import ReservationApiStatus from '../../../../../models/ReservationApiStatus.enum';

import { setBookingConfirmationHederAction, setHotelDetailsHeaderAction } from '../../../../../store/actions/hotelsHeaderAction';
import { RootState } from '../../../../../store/reducers';

export const useLogic = (): {
  cancellation: BookingCancellationResponse | null,
  isCancelBookingDisabled: boolean,
  isCancellationResponseOpen: boolean,
  isConfirmationOpen: boolean,
  isFetching: boolean,
  propertyDetails: PropertyDetailsModel | null,
  providerInfo: ProviderInfo | null | undefined,
  reservation: BookingReservation | null,
  onCancelBookingClicked: () => void,
  onConfirmCancelReservation: () => void,
  onCancelButton: () => void,
  onResponseDialogClose: () => void,
} => {
  const dispatch = useDispatch();

  const { propertyId, reservationId } = useParams<{ propertyId: string; reservationId: string; }>();

  const propertyRoot: PropertyStoreData = useSelector((state: RootState) => state.property);

  const [cancellation, setCancellation] = useState<BookingCancellationResponse | null>(null);
  const [isCancelBookingDisabled, setIsCancelBookingDisabled] = useState<boolean>(false);
  const [
    isCancellationResponseOpen, setIsCancellationResponseOpen,
  ] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [propertyDetails, setPropertyDetails] = useState<PropertyDetailsModel | null>(null);
  const [providerInfo, setProviderInfo] = useState<ProviderInfo | null | undefined>(null);
  const [reservation, setReservation] = useState<BookingReservation | null>(null);

  useEffect(() => {
    dispatch(setBookingConfirmationHederAction('', ''));
    window.scrollTo(0, 0);

    if (!propertyId || !reservationId) {
      return;
    }

    const propertyID = Number(propertyId);
    const reservationID = Number(reservationId);

    Promise.all([
      hotelsRemote.getProperty(propertyID),
      bookingRemote.getReservation(reservationID),
      providerRemote.getProviders(),
    ]).then((resp: [PropertyDetailsModel, ApiList<BookingReservation>, ApiList<ProviderInfo>]) => {
      const propertyResp: PropertyDetailsModel = resp[0];
      const reservationResp: ApiList<BookingReservation> = resp[1];
      const providersResp: ApiList<ProviderInfo> = resp[2];
      let reservationToSave: BookingReservation | null = null;

      if (propertyResp && propertyResp.property_id) {
        setPropertyDetails(propertyResp);
      }

      dispatch(setBookingConfirmationHederAction(
        reservationResp.content[0].property_name,
        propertyResp.city_id.cityName,
      ));

      if (reservationResp && reservationResp.content) {
        // eslint-disable-next-line
        reservationToSave = reservationResp.content[0];
      }

      setReservation(reservationToSave);

      const { content } = providersResp;

      if (providersResp && content && content.length > 0) {
        const providerMatch: ProviderInfo | undefined = content.find(
          (provInfo: ProviderInfo) => provInfo.providerId === reservationResp.content[0].lot,
        );
        setProviderInfo(providerMatch);
      }
    }).catch(() => {
      dispatch(setHotelDetailsHeaderAction(propertyRoot.name));
      setReservation(null);
    });

  // eslint-disable-next-line
  }, []);

  const onCancelBookingClicked = (): void => {
    if (!reservation || !reservation.unique_id
      || reservation.status !== ReservationApiStatus.ACTIVE) {
      return;
    }

    setIsConfirmationOpen(true);
  };

  const onConfirmCancelReservation = (): void => {
    if (!reservation) return;

    setIsConfirmationOpen(false);
    setIsCancelBookingDisabled(true);
    setIsFetching(true);

    bookingRemote.cancelReservation(reservation.lot, reservation.unique_id)
      .then((resp: BookingCancellationResponse) => {
        if (resp && resp.response_type) {
          setCancellation(resp);
          setIsCancellationResponseOpen(true);
        }
        setIsFetching(false);
      })
      .catch(() => {
        setCancellation(null);
        setIsFetching(false);
      });
  };

  const onCancelButton = (): void => {
    setIsConfirmationOpen(false);
  };

  const onResponseDialogClose = (): void => {
    setIsCancellationResponseOpen(false);
  };

  return {
    cancellation,
    isCancelBookingDisabled,
    isCancellationResponseOpen,
    isConfirmationOpen,
    isFetching,
    propertyDetails,
    providerInfo,
    reservation,
    onCancelBookingClicked,
    onConfirmCancelReservation,
    onCancelButton,
    onResponseDialogClose,
  };
};
