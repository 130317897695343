import { FiltersStoreData } from '../../models';

export default function filtersPersistor(): FiltersStoreData {
  let filters: FiltersStoreData = {
    amenity: [], chain: [], location: null, neighborhood: [], rating: [],
  };
  const sessionFilters: string | null = sessionStorage.getItem('filters');

  if (sessionFilters) {
    filters = JSON.parse(sessionFilters);
  }

  return filters;
}
