import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Clear as ClearIcon, Check as CheckIcon } from '@material-ui/icons';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../..';

const useStyles = makeStyles(() => createStyles({
  cancelBtn: {
    '& .MuiButton-label': {
      fontWeight: 'bold',
      padding: '0 15px',
    },
  },
  confirmBtn: {
    '& .MuiButton-label': {
      fontWeight: 'bold',
      padding: '3px 15px',
    },
  },
}));

interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  text: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = (props: ConfirmationDialogProps) => {
  const {
    isOpen,
    title,
    text,
    onClose,
    onConfirm,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button className={classes.cancelBtn} onClick={onClose} variant="contained" startIcon={<ClearIcon />}>{t('no')}</Button>
        <Button className={classes.confirmBtn} onClick={onConfirm} color="primary" variant="contained" startIcon={<CheckIcon />}>{t('yes')}</Button>
      </DialogActions>

    </Dialog>
  );
};

export default ConfirmationDialog;
