import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    hotelCard: {
      width: '100%',
      '& .MuiCardContent-root:last-child': {
        paddingBottom: 8,
      },
    },
    hotelCardContainer: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        padding: '8px 5px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        flexDirection: 'row',
        padding: '8px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        flexDirection: 'row',
        padding: '8px',
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        flexDirection: 'row',
        padding: '8px',
      },
    },
    imageContainer: {
      width: 275,
      height: 187,
      cursor: 'pointer',
    },
    cardMedia: {
      width: '100%',
      height: '100%',
      borderRadius: 8,
    },
    content: {
      flex: 3,
      marginLeft: 10,
      marginRight: 5,
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        display: 'unset',
      },
    },
    nameAndDescriptionContainer: {
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.between('lg', 'xl')]: {
        flex: 1.8,
      },
    },
    nameContainer: {
      paddingTop: 3,
      fontWeight: 600,
      fontSize: 17,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    pricesContainer: {
      flex: 0.5,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.between('lg', 'xl')]: {
        flex: 0.7,
      },
    },
    priceContainer: {
      fontSize: 17,
      fontWeight: 600,
      textAlign: 'right',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
));
