import { SET_POPULAR_LOCATIONS } from '../actions/actionTypes';

import { PointOfInterest } from '../../models';

const initialState: Array<PointOfInterest> = [];

// eslint-disable-next-line max-len
export default (state: Array<PointOfInterest> = initialState, action: { type: string; payload: Array<PointOfInterest> }): Array<PointOfInterest> => {
  if (action.type === SET_POPULAR_LOCATIONS) {
    return action.payload;
  }

  return state;
};
