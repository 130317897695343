import React, { FC } from 'react';

import { LocationOn as LocationIcon } from '@material-ui/icons';
import { Box } from '../../../common';

import { AvailabilityLight } from '../../../../models';

import { useLogic } from './hooks/useLogic';
import { useOpenMap } from '../../../../hooks/useOpenMap';

import useStyles from './mapMarkerStyles';

interface MapMarkerProps {
  data?: AvailabilityLight;
}

const MapMarker: FC<MapMarkerProps> = (props: MapMarkerProps) => {
  const { data } = props;
  const classes = useStyles();
  const { handleClick } = useLogic(data);
  const { openMap, handleOpenMap, handleCloseMap } = useOpenMap();

  return (
    <Box onMouseEnter={handleOpenMap} onMouseLeave={handleCloseMap}>
      <LocationIcon
        stroke="white"
        strokeWidth={1}
        className={classes.marker}
        onClick={handleClick}
      />
      { data && openMap && (
        <Box className={classes.markerTooltipContent}>
          <Box component="span" className={classes.markerTooltipText}>
            {data.property_info.name}
          </Box>
        </Box>
      ) }
    </Box>
  );
};

const MemoizedMapMarker = React.memo(MapMarker);
export default MemoizedMapMarker;
