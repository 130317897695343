import { STORE_PROPERTY_DATA } from '../actions/actionTypes';

import { PropertyStoreData } from '../../models';

const initialState: PropertyStoreData = {
  id: null,
  name: '',
};

export default (
  state: PropertyStoreData = initialState, action: { type: string; payload: PropertyStoreData },
): PropertyStoreData => {
  if (action.type === STORE_PROPERTY_DATA) {
    return {
      ...state,
      id: action.payload.id,
      name: action.payload.name,
    };
  }

  return state;
};
