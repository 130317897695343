import { SET_SORT_BY_DETAILS_PAGE, SET_SORT_BY_LIST_PAGE } from '../actions/actionTypes';

import { SortByStoreData } from '../../models';

const initialState: SortByStoreData = {
  list: 'asc',
  details: 'asc',
};

export default (
  state: SortByStoreData = initialState, action: { type: string; payload: string },
): SortByStoreData => {
  switch (action.type) {
    case SET_SORT_BY_DETAILS_PAGE: {
      const newState: SortByStoreData = {
        ...state,
        details: action.payload,
      };

      sessionStorage.setItem('sortBy', JSON.stringify(newState));
      return newState;
    }
    case SET_SORT_BY_LIST_PAGE: {
      const newState: SortByStoreData = {
        ...state,
        list: action.payload,
      };

      sessionStorage.setItem('sortBy', JSON.stringify(newState));
      return newState;
    }
    default: return state;
  }
};
