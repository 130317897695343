import { useSelector } from 'react-redux';

import { RootState } from '../../store/reducers';

import { DEFAULT_FALLBACK_UNIT } from '../../configs/environments';

const UNIT_KM = 'km';
const UNIT_MI = 'mi';

export const useMeasurementUnit = (): {
  apiUnitNameRoot: string;
  measurementUnit: string;
  UNIT_KM: string;
  UNIT_MI: string;
} => {
  const apiUnitNameRoot: string = useSelector(
    (state: RootState) => state.login.unit?.name || DEFAULT_FALLBACK_UNIT,
  );

  return {
    apiUnitNameRoot,
    measurementUnit: apiUnitNameRoot === DEFAULT_FALLBACK_UNIT ? UNIT_MI : UNIT_KM,
    UNIT_KM,
    UNIT_MI,
  };
};
