import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    cardRoot: {
      marginBottom: 8,
      '& .MuiCardContent-root:last-child': {
        paddingBottom: 16,
      },
    },
    home: {
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  },
));
