import React, { FC } from 'react';

import { Box, Card, CardContent } from '../../../common';

import HotelLoadingContent from '../../../hotel/hotelsLoading/hotelLoadingContent/HotelLoadingContent';

import useStyles from './bookingConfirmationLoadingStyles';

const BookingConfirmationLoading: FC<unknown> = () => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.hotelLoadingCard}>
        <CardContent className={classes.hotelLoadingCardContainer}>
          <Box className={classes.hotelLoadingCardContentContainer}>
            <HotelLoadingContent />
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.hotelLoadingCard}>
        <CardContent className={classes.hotelLoadingCardContainer}>
          <Box className={classes.hotelLoadingCardContentContainer}>
            <HotelLoadingContent />
            <HotelLoadingContent />
            <HotelLoadingContent />
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.hotelLoadingCard}>
        <CardContent className={classes.hotelLoadingCardContainer}>
          <Box className={classes.hotelLoadingCardContentContainer}>
            <HotelLoadingContent />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

const MemoizedBookingConfirmationLoading = React.memo(BookingConfirmationLoading);
export default MemoizedBookingConfirmationLoading;
