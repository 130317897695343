import React, { FC } from 'react';
import nextId from 'react-id-generator';

import {
  Star as FilledStarIcon,
  StarHalf as StarHalfIcon,
} from '@material-ui/icons';
import { Box } from '../../common';

export const HALF_RATING = 0.5;

interface HotelRatingProps {
  rating: number;
}

const HotelRating: FC<HotelRatingProps> = (props: HotelRatingProps) => {
  const { rating } = props;

  const ratingToMathFloor = Math.floor(rating);

  return (
    <Box display="flex" flexDirection="row">
      { Array.from({ length: ratingToMathFloor }, () => (
        <FilledStarIcon key={nextId('fsi-')} fontSize="small" />
      )) }

      { rating - ratingToMathFloor === HALF_RATING ? (
        <StarHalfIcon fontSize="small" />
      ) : null }
    </Box>
  );
};

export default HotelRating;
