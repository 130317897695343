import React, { FC } from 'react';

import CommonRadioFilter from '../commonRadioFilter/CommonRadioFilter';

import { useLogic } from './hooks/useLogic';

const PointsOfInterestFilter: FC<unknown> = () => {
  const {
    expanded,
    pointsMoreLess,
    pointsOfInterest,
    selectedPoi,
    onExpandCollapse,
    onPointOfInterestClear,
    onPointOfInterestSelect,
  } = useLogic();

  return (
    <CommonRadioFilter
      expanded={expanded}
      items={pointsOfInterest}
      itemsMoreLess={pointsMoreLess}
      selectedItem={selectedPoi}
      title={'filterPoiTitle'}
      onExpandCollapse={onExpandCollapse}
      onItemSelected={onPointOfInterestSelect}
      onItemSelectionClear={onPointOfInterestClear}
    />
  );
};

const MemoizedPointsOfInterestFilter = React.memo(PointsOfInterestFilter);
export default MemoizedPointsOfInterestFilter;
