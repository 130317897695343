import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent } from '../../common';

import BookingDetailsRoomHolder from '../roomHolder/BookingDetailsRoomHolder';

import { BookingRoomHolder, BookingRoomHolderErrors } from '../../../models';

const useStyles = makeStyles(() => createStyles({
  card: {
    width: '100%',
    minWidth: 450,
    minHeight: 106,
    margin: '8px 0',
  },
}));

interface BookingDetailsRoomProps {
  index: number;
  room: Array<BookingRoomHolder>;
  roomErrors: Array<BookingRoomHolderErrors>;
  onChange: (
    roomHolder: Array<BookingRoomHolder>,
    roomHolderErrors: Array<BookingRoomHolderErrors>,
    roomIndex: number,
  ) => void;
}

const BookingDetailsRoom: FC<BookingDetailsRoomProps> = (props: BookingDetailsRoomProps) => {
  const {
    index,
    room,
    roomErrors,
    onChange,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (
    roomHolder: BookingRoomHolder,
    roomHolderErrors: BookingRoomHolderErrors,
    roomHolderIndex: number,
  ): void => {
    onChange(
      room.splice(roomHolderIndex, 1, roomHolder),
      roomErrors.splice(roomHolderIndex, 1, roomHolderErrors),
      index,
    );
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Box fontSize="h6.fontSize">{t('roomDetails', { index: index + 1 })}</Box>
          { room.map((holder: BookingRoomHolder, holderIndex: number) => (
            <BookingDetailsRoomHolder
              key={holder.uniqueId}
              index={holderIndex}
              onChange={handleChange}
              roomHolder={holder}
              roomHolderErrors={roomErrors[holderIndex]}
            />
          )) }
        </CardContent>
      </Card>
    </>
  );
};

export default BookingDetailsRoom;
