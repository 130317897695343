import configs from '../configs/environments';

import { Api } from '../models';
import EnvironmentType from '../models/EnvironmentType.enum';

const defineBaseUrl = (): string => {
  const apiGateway: Api = configs.api;
  let baseUrl = `${apiGateway.protocol}://${apiGateway.url}`;
  
  if (process.env.REACT_APP_ENV === EnvironmentType.LOCAL) {
    baseUrl += `:${apiGateway.port}/`;
  } else {
    baseUrl += '/';
  }

  return baseUrl;
};

export default defineBaseUrl();
