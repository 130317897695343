import { PropertyStoreData } from '../../models';

export default function propertyPersistor(): PropertyStoreData {
  let property: PropertyStoreData = { id: null, name: '' };
  const sessionProperty: string | null = sessionStorage.getItem('property');

  if (sessionProperty) {
    property = JSON.parse(sessionProperty);
  }

  return property;
}
