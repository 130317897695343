import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles(
  {
    loadingContent: {
      height: '100%',
      width: '100%',
      padding: '5px 0',
    },
    linearRoot: {
      borderRadius: 8,
      height: 20,
    },
    linearColorPrimary: {
      backgroundColor: '#f0f0f0',
    },
    linearBarColorPrimary: {
      backgroundColor: '#cccccc',
    },
    loadingHeadingText: {
      width: '40%',
      marginBottom: 15,
    },
    loadingDescription: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    loadingDescriptionText1: {
      width: '20%',
    },
    loadingDescriptionText2: {
      width: '65%',
    },
    loadingDescriptionText3: {
      width: '60%',
    },
    loadingDescriptionText4: {
      width: '30%',
    },
  },
));
