import React, { FC } from 'react';

import { CircularProgress } from '..';

interface ButtonProgressCircleIconProps {
  isFetching: boolean;
}

const ButtonProgressCircleIcon: FC<ButtonProgressCircleIconProps> = (
  props: ButtonProgressCircleIconProps,
) => {
  const { isFetching } = props;

  if (isFetching) {
    return <CircularProgress size={20} thickness={4.5} />;
  }

  return null;
};

export default ButtonProgressCircleIcon;
