import React, { FC } from 'react';

import { useLogic } from './hooks/useLogic';

import CommonCheckboxFilter from '../commonCheckboxFilter/CommonCheckboxFilter';

const NeighborhoodFilter: FC<unknown> = () => {
  const {
    hotelNeighborhoodFilterRoot,
    neighborhoods,
    neighborhoodsMoreLess,
    neighborhoodsSelect,
    expanded,
    onNeighborhoodSelected,
    onNeighborhoodSelectionClear,
    onExpandCollapse,
  } = useLogic();

  return (
    <CommonCheckboxFilter
      expanded={expanded}
      items={neighborhoods}
      itemsMoreLess={neighborhoodsMoreLess}
      itemsSelect={neighborhoodsSelect}
      itemsFilterRoot={hotelNeighborhoodFilterRoot}
      title={'filterNeighborhoodTitle'}
      onExpandCollapse={onExpandCollapse}
      onItemSelected={onNeighborhoodSelected}
      onItemSelectionClear={onNeighborhoodSelectionClear}
    />
  );
};

const MemoizedNeighborhoodFilter = React.memo(NeighborhoodFilter);
export default MemoizedNeighborhoodFilter;
