import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Card, CardContent,
} from '../../common';

import { RootState } from '../../../store/reducers';

import CheckInCheckOut from '../checkInCheckOut/CheckInCheckOut';
import LocationAutoSuggest from '../locationAutoSuggest/LocationAutoSuggest';
import RadiusSearchInput from '../radiusSearchInput/RadiusSearchInput';
import RoomsWidget from '../roomsWidget/RoomsWidget';

import { useCommonLogic } from '../hooks/useCommonLogic';

import useStyles from './hotelSearchBarStyles';

const HotelSearchBar: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    hasCheckInCheckOutError,
    locationOnFocus,
    rooms,
    suggestion,
    onSetDateRanges,
    onSetLocation,
    onSetRooms,
    onSearchButtonClicked,
    setCheckInCheckOutHasAnError,
    setOnFocusLocation,
  } = useCommonLogic();

  const isHotelFetchingRoot: boolean = useSelector((state: RootState) => state.fetching.hotels);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.form}>
          <Box className={classes.inputContainer}>

            <Box className={classes.locationContainer}>
              <LocationAutoSuggest
                locationOnFocus={locationOnFocus}
                onChange={onSetLocation}
                onFocus={setOnFocusLocation}
                suggestion={suggestion}
              />
              <RadiusSearchInput />
            </Box>

            <Box className={classes.checkInCheckOutContainer}>
              <CheckInCheckOut
                hasCheckInCheckOutError={hasCheckInCheckOutError}
                setCheckInCheckError={setCheckInCheckOutHasAnError}
                setDateRanges={onSetDateRanges}
              />
            </Box>

            <Box className={classes.roomsContainer}>
              <RoomsWidget rooms={rooms} onSetRooms={onSetRooms} />
            </Box>

            <Box className={classes.buttonContainer}>
              <Button
                disabled={isHotelFetchingRoot}
                color="primary"
                variant="contained"
                type="submit"
                onClick={onSearchButtonClicked}
              >
                <Box fontWeight="fontWeightBold">
                  {t('widgetSearchButtonRefine')}
                </Box>
              </Button>
            </Box>

          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const MemoizedHotelSearchBar = React.memo(HotelSearchBar);
export default MemoizedHotelSearchBar;
