import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControl, InputLabel, MenuItem, Select,
} from '../../common';

import { useLogic } from './hooks/useLogic';

import useStyles from './radiusSearchInputStyles';

const RadiusSearchInput: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    radiusValues, value, handleRadiusChange, renderValue,
  } = useLogic();

  return (
    <FormControl
      margin="normal"
      variant="filled"
      classes={{ root: classes.root }}
    >
      <InputLabel shrink id="radius">{ t('radiusLabel') }</InputLabel>
      <Select
        inputProps={{
          id: 'radius',
          /**
           * this prevent from applying `padding-right: 15px` to body
           * from material-ui when popup the Select and it's not a native
           */
          MenuProps: { disableScrollLock: true },
        }}
        name="radius"
        disableUnderline
        onChange={handleRadiusChange}
        value={value}
      >
        { radiusValues.map(
          (radius: number | string, index: number) => (
            <MenuItem key={radius} value={radius} data-index={index}>
              { renderValue(radius) }
            </MenuItem>
          ),
        ) }
      </Select>
    </FormControl>
  );
};

export default RadiusSearchInput;
