import { SET_SELECTED_LOCATION } from '../actions/actionTypes';

import Location, { dummyLocation } from '../../models/Location';

const initialState: Location = dummyLocation;

export default (
  state: Location = initialState, action: { type: string; payload: Location },
): Location => {
  if (action.type === SET_SELECTED_LOCATION) {
    return { ...action.payload };
  }

  return state;
};
