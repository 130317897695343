import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    hotelsMapCard: {
      width: 270,
      height: 193,
      borderRadius: 8,
      margin: '0 0 8px 0',
    },
    hotelsMapCardContainer: {
      padding: '8px',
    },
    mapImage: {
      borderRadius: 8,
    },
    pinAndButtonContent: {
      position: 'relative',
      top: -130,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    locationPinIcon: {
      color: theme.palette.primary.main,
      fontSize: 55,
      marginBottom: 10,
    },
  },
));
