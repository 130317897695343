import { User } from '../../models';
import genericApi from '../genericApi';

const userRemote = {

  login: (data: { username: string; password: string }): Promise<User> => genericApi.post('user/authenticate', data),
  logout: (): Promise<string> => genericApi.get('user/logout'),
};

export default userRemote;
