import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles(
  {
    root: {
      flexGrow: 1,
    },
    container: {
      justifyContent: 'center',
      [theme.breakpoints.between('xs', 'sm')]: {
        marginRight: 0,
        paddingRight: 0,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        marginRight: 100,
        paddingRight: 64,
      },
      [theme.breakpoints.up('md')]: {
        marginRight: 100,
        paddingRight: 0,
      },
    },
    titleContainer: {
      [theme.breakpoints.only('sm')]: {
        marginBottom: 36,
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: 18,
      },
    },
    titleText: {
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: 34,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: 40,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 50,
      },
    },
  },
));
