import { Environment } from '../models';
import EnvironmentType from '../models/EnvironmentType.enum';

// common
export const BOOKING_EXPIRY_DATE_YEARS_RANGE = 20;
const CONTACT_US_URL = 'https://www.roomrocket.com/contact-us/';
const CHECK_IN_CHECK_OUT_NUMBER_OF_CALENDARS = 2;
const CHECK_IN_CHECK_OUT_CLOSE_TIMER_MS = 800;
const CHECK_IN_CHECK_OUT_MIN_BOOKING_NIGHTS = 1;
const CHECK_IN_CHECK_OUT_BLOCK_PAST_MONTHS_NAVIGATION = true;
export const CURRENCY_HUNDRED_UNIT = 100;
export const DEFAULT_FALLBACK_CURRENCY = 'USD';
export const DEFAULT_FALLBACK_UNIT = 'IMPERIAL';
export const GOOGLE_MAP_API_KEY = '';
export const GOOGLE_MAP_FULL_SCREEN_TOOLBAR_HEIGHT = 48;
export const HOTEL_LIST_DESC_TRIM_LENGTH = 250;
export const HOTEL_LOADING_CARDS = 5;
const LOCATION_SEARCH_DEBOUNCE_WAIT_MS = 500;
export const MAX_STAR_RATING_VALUE = 5;
export const PAGINATION_PAGE_SIZE = 25;
export const PORTAL_BE_ROUTE_PREFIX = 'portalbe';
export const POINT_OF_INTEREST_LIMIT = 5;
export const ROOM_AMENITY_IDS = [2, 7, 14];
const TERMS_OF_USE_URL = 'https://www.roomrocket.com';

// images
export const IMAGES_BASE_URL = 'https://roomrocket-images.s3.us-east-2.amazonaws.com';
export const IMAGE_EXTENSION = 'jpg';
export const IMAGE_SIZE_MEDIUM = '';
export const IMAGE_SIZE_LARGE = '_l';
export const IMAGE_SIZE_SMALL = '_s';
export const IMAGE_ORIGINAL_SIZE = IMAGE_SIZE_MEDIUM;

// filters
export const INITIAL_FILTER_ITEMS = 5;
export const SHOW_AMENITY_FILTER = true;
export const SHOW_CHAIN_FILTER = true;
export const SHOW_NEIGHBORHOOD_FILTER = true;
export const SHOW_POI_FILTER = true;
export const SHOW_RATING_FILTER = true;
export const REQUESTED_CHAIN_SIZE = 8;

// sort
export const SORT_BY_PRICE_ASC = 'BESTPRICEASC';
export const SORT_BY_PRICE_DESC = 'BESTPRICEDESC';

// rooms
const ROOM_DEFAULT_ADULTS = 2;
const ROOM_MAX_ADULTS = 4;
const ROOM_MIN_ADULTS = 1;
const ROOM_DEFAULT_CHILDREN = 0;
const ROOM_MAX_CHILDREN = 2;
const ROOM_MIN_CHILDREN = 0;
const ROOMS_MAX_RESERVATION = 8;
const ROOMS_MIN_RESERVATION = 1;
const ROOM_INCREASE_DECREASE_STEP = 1;

// env specific
const PROTOCOL_LOCAL = 'http';
const URL_LOCAL = 'localhost';
const PORT_LOCAL = '8080';

const PROTOCOL_DEV = 'https';
const URL_DEV = 'otaportalbackend-env.eba-uu5r9gb6.us-east-2.elasticbeanstalk.com';

const PROTOCOL_PROD = '';
const URL_PROD = '';

const CONTACT_EMAIL = 'GroundControl@RoomRocket.com';

const local: Environment = {
  api: {
    protocol: PROTOCOL_LOCAL,
    url: URL_LOCAL,
    port: PORT_LOCAL,
  },
  calendarDisplayNumbers: CHECK_IN_CHECK_OUT_NUMBER_OF_CALENDARS,
  calendarCloseTimeMs: CHECK_IN_CHECK_OUT_CLOSE_TIMER_MS,
  calendarMinBookingNights: CHECK_IN_CHECK_OUT_MIN_BOOKING_NIGHTS,
  calendarBlockPastMonthsNavigation: CHECK_IN_CHECK_OUT_BLOCK_PAST_MONTHS_NAVIGATION,
  contactUsUrl: CONTACT_US_URL,
  envType: EnvironmentType.LOCAL,
  locationSearchDebounceMs: LOCATION_SEARCH_DEBOUNCE_WAIT_MS,
  room: {
    adults: {
      default: ROOM_DEFAULT_ADULTS,
      max: ROOM_MAX_ADULTS,
      min: ROOM_MIN_ADULTS,
    },
    children: {
      default: ROOM_DEFAULT_CHILDREN,
      max: ROOM_MAX_CHILDREN,
      min: ROOM_MIN_CHILDREN,
    },
    room: {
      max: ROOMS_MAX_RESERVATION,
      min: ROOMS_MIN_RESERVATION,
    },
    step: ROOM_INCREASE_DECREASE_STEP,
  },
  termsOfUseUrl: TERMS_OF_USE_URL,
  contactEmail: CONTACT_EMAIL,
};

const dev: Environment = {
  api: {
    protocol: PROTOCOL_DEV,
    url: URL_DEV,
  },
  calendarDisplayNumbers: CHECK_IN_CHECK_OUT_NUMBER_OF_CALENDARS,
  calendarCloseTimeMs: CHECK_IN_CHECK_OUT_CLOSE_TIMER_MS,
  calendarMinBookingNights: CHECK_IN_CHECK_OUT_MIN_BOOKING_NIGHTS,
  calendarBlockPastMonthsNavigation: CHECK_IN_CHECK_OUT_BLOCK_PAST_MONTHS_NAVIGATION,
  contactUsUrl: CONTACT_US_URL,
  envType: EnvironmentType.DEV,
  locationSearchDebounceMs: LOCATION_SEARCH_DEBOUNCE_WAIT_MS,
  room: {
    adults: {
      default: ROOM_DEFAULT_ADULTS,
      max: ROOM_MAX_ADULTS,
      min: ROOM_MIN_ADULTS,
    },
    children: {
      default: ROOM_DEFAULT_CHILDREN,
      max: ROOM_MAX_CHILDREN,
      min: ROOM_MIN_CHILDREN,
    },
    room: {
      max: ROOMS_MAX_RESERVATION,
      min: ROOMS_MIN_RESERVATION,
    },
    step: ROOM_INCREASE_DECREASE_STEP,
  },
  termsOfUseUrl: TERMS_OF_USE_URL,
  contactEmail: CONTACT_EMAIL,
};

const prod: Environment = {
  api: {
    protocol: PROTOCOL_PROD,
    url: URL_PROD,
  },
  calendarDisplayNumbers: CHECK_IN_CHECK_OUT_NUMBER_OF_CALENDARS,
  calendarCloseTimeMs: CHECK_IN_CHECK_OUT_CLOSE_TIMER_MS,
  calendarMinBookingNights: CHECK_IN_CHECK_OUT_MIN_BOOKING_NIGHTS,
  calendarBlockPastMonthsNavigation: CHECK_IN_CHECK_OUT_BLOCK_PAST_MONTHS_NAVIGATION,
  contactUsUrl: CONTACT_US_URL,
  envType: EnvironmentType.PROD,
  locationSearchDebounceMs: LOCATION_SEARCH_DEBOUNCE_WAIT_MS,
  room: {
    adults: {
      default: ROOM_DEFAULT_ADULTS,
      max: ROOM_MAX_ADULTS,
      min: ROOM_MIN_ADULTS,
    },
    children: {
      default: ROOM_DEFAULT_CHILDREN,
      max: ROOM_MAX_CHILDREN,
      min: ROOM_MIN_CHILDREN,
    },
    room: {
      max: ROOMS_MAX_RESERVATION,
      min: ROOMS_MIN_RESERVATION,
    },
    step: ROOM_INCREASE_DECREASE_STEP,
  },
  termsOfUseUrl: TERMS_OF_USE_URL,
  contactEmail: CONTACT_EMAIL,
};

const processEnv: { env: Environment } = { env: dev };

switch (process.env.REACT_APP_ENV) {
  case EnvironmentType.DEV: processEnv.env = dev; break;
  case EnvironmentType.LOCAL: processEnv.env = local; break;
  case EnvironmentType.PROD: processEnv.env = prod; break;
  default: processEnv.env = dev; break;
}

export default processEnv.env;
