import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    width: '22%',
    '& .MuiSelect-root': {
      backgroundColor: theme.palette.background.default,
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      boxShadow: '0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 1px 0px 0px rgb(0 0 0 / 14%), 2px 2px 0px -1px rgb(0 0 0 / 12%)',
      padding: '30px 0 12px',
    },
    '& .MuiSelect-select .MuiSelect-filled .MuiFilledInput-input': {
      padding: '30px 0 12px',
    },
    '& .MuiInputLabel-filled': {
      transform: 'translate(0px, 10px) scale(0.75)',
    },
    '& .MuiInputLabel-filled.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'unset',
    },
  },
}));
