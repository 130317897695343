import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Card, CardContent,
} from '../../common';

import BookingConfirmationDetails from './details/BookingConfirmationDetails';
import BookingConfirmationError from './error/BookingConfirmationError';
import BookingConfirmationLoading from './confirmationLoading/BookingConfirmationLoading';
import BookingConfirmationPayment from './payment/BookingConfirmationPayment';
import BookingVoucherConfirmation from './voucherConfirmation/BookingVoucherConfirmation';
import ButtonProgressCircleIcon from '../../common/buttonPogressCircleIcon/ButtonProgressCircleIcon';
import CancellationResponseDialog from './cancellationResponseDialog/CancellationResponseDialog';
import ConfirmationDialog from '../../common/dialog/confirmation/ConfirmationDialog';

import ReservationApiStatus from '../../../models/ReservationApiStatus.enum';

import { useLogic } from './hooks/useLogic';

import useStyles from './bookingConfirmationStyles';

const BookingConfirmation: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    cancellation,
    isCancelBookingDisabled,
    isCancellationResponseOpen,
    isConfirmationOpen,
    isFetching,
    propertyDetails,
    providerInfo,
    reservation,
    onCancelBookingClicked,
    onConfirmCancelReservation,
    onCancelButton,
    onResponseDialogClose,
  } = useLogic();

  return (
    <>
      { reservation?.warning && <BookingConfirmationError warning={reservation.warning} /> }
      <Box fontSize="h5.fontSize" p={'10px 0 5px 16px'}>{t('bookingConfirmationHeader')}</Box>
      { (!propertyDetails || !reservation || !providerInfo) && <BookingConfirmationLoading /> }
      { propertyDetails && reservation && providerInfo && (
        <>
          <BookingVoucherConfirmation
            providerInfo={providerInfo}
            reservationId={reservation.unique_id}
          />
          <BookingConfirmationDetails property={propertyDetails} reservation={reservation} />
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box fontSize="h6.fontSize">{t('bookingConfirmationCancellationDetailsTitle')}</Box>
              <Box mt={2}>
                <Box textAlign="justify">{reservation.cancellation_string}</Box>
              </Box>
              <Box className={classes.buttonContent}>
                <Button
                  disabled={
                    reservation.status !== ReservationApiStatus.ACTIVE || isCancelBookingDisabled
                  }
                  color="primary"
                  variant="contained"
                  onClick={onCancelBookingClicked}
                  startIcon={<ButtonProgressCircleIcon isFetching={isFetching} />}
                >
                  {t('bookingCancelBtnTitle')}
                </Button>
              </Box>
            </CardContent>
          </Card>
          <BookingConfirmationPayment reservation={reservation} />
        </>
      )}
      { isConfirmationOpen && (
        <ConfirmationDialog
          isOpen={isConfirmationOpen}
          title={t('bookingConfirmationCancelCancelConfirmTitle')}
          text={t('bookingConfirmationCancelConfirmText')}
          onClose={onCancelButton}
          onConfirm={onConfirmCancelReservation}
        />
      ) }
      { isCancellationResponseOpen && cancellation && (
        <CancellationResponseDialog
          bookingCancellation={cancellation}
          isOpen={isCancellationResponseOpen}
          onClose={onResponseDialogClose}
        />
      ) }
    </>
  );
};

export default BookingConfirmation;
