export default interface Location {
  country_name: string;
  destination_name: string;
  destination_type: string;
  id: string;
  latitude: number;
  location_name: string;
  longitude: number;
}

export const dummyLocation: Location = {
  country_name: '',
  destination_name: '',
  destination_type: '',
  id: 'null',
  latitude: 0,
  location_name: '',
  longitude: 0,
};
