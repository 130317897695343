import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Card, CardContent,
} from '../../common';

import BookingBillingAddress from '../billingAddress/BookingBillingAddress';
import BookingCardDetails from '../cardDetails/BookingCardDetails';
import BookingConfirmationError from '../confirmation/error/BookingConfirmationError';
import BookingDetailsRooms from '../rooms/BookingDetailsRooms';
import BookingReservationDetails from '../reservationDetails/BookingReservationDetails';
import ButtonProgressCircleIcon from '../../common/buttonPogressCircleIcon/ButtonProgressCircleIcon';

import { useLogic } from './hooks/useLogic';

import useStyles from './bookingDetailsPageStyles';

const BookingDetailsPage: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    roomsRoot,
    bookingErrors,
    bookNowDisabled,
    cardData,
    cardErrors,
    isFetching,
    personData,
    personDataErrors,
    roomHolders,
    roomHoldersErrors,
    onBookNowClicked,
    onCardDataChange,
    onPersonDataChange,
    onRoomHoldersChange,
  } = useLogic();

  try {
    return (
      <>
        { bookingErrors && <BookingConfirmationError errors={bookingErrors} /> }
        <Box fontSize="h5.fontSize" p={'10px 0 5px 16px'}>{t('bookingDetailsHeader')}</Box>
        <BookingReservationDetails />
        { roomsRoot.length > 0 && roomHolders.length > 0 && (
          <BookingDetailsRooms
            roomHolders={roomHolders}
            roomHoldersErrors={roomHoldersErrors}
            onChange={onRoomHoldersChange}
          />
        ) }
        <Card className={classes.card}>
          <CardContent>
            <Box fontSize="h6.fontSize">{t('paymentDetails')}</Box>
            <Box className={classes.cardPaymentContent}>
              <Box width="45%">
                <BookingCardDetails
                  cardData={cardData}
                  cardErrors={cardErrors}
                  onChange={onCardDataChange}
                />
              </Box>
              <Box width="50%">
                <BookingBillingAddress
                  personData={personData}
                  personDataErrors={personDataErrors}
                  onChange={onPersonDataChange}
                />
              </Box>
            </Box>
            <Box className={classes.buttonContent}>
              <Button
                disabled={bookNowDisabled}
                color="primary"
                variant="contained"
                onClick={onBookNowClicked}
                startIcon={<ButtonProgressCircleIcon isFetching={isFetching} />}
              >
                {t('bookNowButtonTitle')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </>
    );
  } catch {
    return null;
  }
};

export default BookingDetailsPage;
