import { Dispatch } from 'redux';

import { STORE_SELECTED_PRODUCT } from './actionTypes';
import { RootState } from '../reducers';

import { AvailabilityProduct } from '../../models';

// eslint-disable-next-line
const selectedProduct = (data: AvailabilityProduct) => ((dispatch: Dispatch, getState: () => RootState): void => {
  const { property } = getState();

  dispatch({ type: STORE_SELECTED_PRODUCT, payload: { property, product: data } });
});

export {
  selectedProduct,
};
