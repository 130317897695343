import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { LOGIN_ROUTE_PATH } from './routesPath';

interface PrivateRouteProps {
  // eslint-disable-next-line
  component: any;
  path?: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
  const isAuthenticated: boolean = useSelector((state: RootState) => state.login !== null);
  const {
    component: Component,
    exact,
    path,
  } = props;
  return (
    <Route
      exact={exact}
      path={path}
      render={(renderProps) => {
        if (isAuthenticated) {
          return (
            <Component
              history={renderProps.history}
              location={renderProps.location}
              match={renderProps.match}
            />
          );
        }
        return (
          <Redirect to={{ pathname: LOGIN_ROUTE_PATH, state: { from: renderProps.location } }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
