import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ArrowLeft as ArrowLeftIcon } from '@material-ui/icons';
import { Box, Button } from '../../common';

import HotelBreadcrumbContainer from '../hotelBreadcrumbs/HotelBreadcrumbContainer';
import HotelDetailsSearchBar from '../../search/hotelDetailsSearchBar/HotelDetailsSearchBar';
import PropertyDetails from './propertyDetails/PropertyDetails';
import PropertyDetailsLoading from './propertyDetailsLoading/PropertyDetailsLoading';

import { useLogic } from './hooks/useLogic';

const useStyles = makeStyles(() => createStyles({
  propertySearchBarContainer: {
    marginTop: 8,
    marginBottom: 2,
  },
  buttonAndBreadContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  backButton: {
    fontSize: 16,
    padding: '10px 26px 10px 16px',
    '& .MuiSvgIcon-root': {
      fontSize: 35,
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
  },
}));

const HotelDetailsPage: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { breadcrumbs, propertyDetails, onBackButtonClicked } = useLogic();

  return (
    <Box>
      <Box className={classes.propertySearchBarContainer}>
        <HotelDetailsSearchBar />
      </Box>
      <Box className={classes.buttonAndBreadContainer}>
        <Box mr="8px">
          <Button
            color="primary"
            variant="contained"
            className={classes.backButton}
            onClick={onBackButtonClicked}
            startIcon={<ArrowLeftIcon />}
          >
            {t('backBtnTitle')}
          </Button>
        </Box>
        <Box width="100%">
          <HotelBreadcrumbContainer breadcrumbsItems={breadcrumbs} />
        </Box>
      </Box>
      <Box>
        { propertyDetails ? (
          <PropertyDetails propertyDetails={propertyDetails} />
        ) : (<PropertyDetailsLoading />)}
      </Box>
    </Box>
  );
};

export default HotelDetailsPage;
