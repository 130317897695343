import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FormControl, InputLabel, Select } from '../../common';

import { RootState } from '../../../store/reducers';

import { useLogic } from './hooks/useLogic';

import useStyles from './availabilitySortByStyles';

interface AvailabilitySortByProps {
  detailPage?: boolean;
  disabled?: boolean;
  listPage?: boolean;
}

const AvailabilitySortBy: FC<AvailabilitySortByProps> = (props: AvailabilitySortByProps) => {
  const { detailPage, disabled, listPage } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { selectedOption, sortOptions, onSortByChange } = useLogic(detailPage, listPage);

  const isHotelFetchingRoot: boolean = useSelector((state: RootState) => state.fetching.hotels);

  return (
    <FormControl fullWidth variant="filled" classes={{ root: classes.root }}>
      <InputLabel shrink htmlFor="sortBy">{t('sortBy')}</InputLabel>
      <Select
        native
        value={selectedOption}
        label={t('sortBy')}
        disabled={disabled || isHotelFetchingRoot}
        inputProps={{ id: 'sortBy' }}
        onChange={onSortByChange}
        disableUnderline
      >
        {sortOptions.map(
          (option) => <option key={option.value} value={option.value}>{t(option.label)}</option>,
        )}
      </Select>
    </FormControl>
  );
};

const MemoizedAvailabilitySortBy = React.memo(AvailabilitySortBy);
export default MemoizedAvailabilitySortBy;
