import React, { FC } from 'react';

import { Box, Card, CardContent } from '../../../common';

import HotelLoadingContent from '../../hotelsLoading/hotelLoadingContent/HotelLoadingContent';
import HotelLoadingImage from '../../hotelsLoading/hotelLoadingImage/HotelLoadingImage';

import useStyles from './propertyDetailsLoadingStyles';

const PropertyDetailsLoading: FC<unknown> = () => {
  const classes = useStyles();

  return (
    <Card className={classes.cardLoading}>
      <CardContent className={classes.cardLoadingContent}>
        <Box className={classes.imagesLoadingContainer}>
          <HotelLoadingImage />
        </Box>
        <Box className={classes.descAndMapLoadingContainer}>
          <Box className={classes.nameAndDescLoadingContainer}>
            <HotelLoadingContent />
          </Box>
          <Box className={classes.mapLoadingContainer}>
            <HotelLoadingImage />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const MemoizedPropertyDetailsLoading = React.memo(PropertyDetailsLoading);
export default MemoizedPropertyDetailsLoading;
