import { SET_POPULAR_LOCATIONS } from './actionTypes';

import { PointOfInterest } from '../../models';

// eslint-disable-next-line max-len
const setPopularLocations = (data: Array<PointOfInterest>): { type: string, payload: Array<PointOfInterest> } => (
  {
    type: SET_POPULAR_LOCATIONS,
    payload: data,
  }
);

export {
  setPopularLocations,
};
