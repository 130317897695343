import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Copyright as CopyrightIcon } from '@material-ui/icons';
import {
  Box, Button, Card, CardContent, CardMedia, Link, Typography,
} from '../../../common';

import { useSignOut } from '../../../../hooks/useSignOut';

import { BOOKING_RESERVATIONS, HOME_ROUTE_PATH } from '../../../../routes/routesPath';
import configs from '../../../../configs/environments';

import useStyles from './footerStyles';
import defaultBrandImage from '../../../../assets/inverted_brand.png';

interface FooterProps {
  year: number;
}

const Footer: FC<FooterProps> = (props: FooterProps) => {
  const { year } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { onSignOutClicked } = useSignOut();

  const onMyReservationsClicked = (): void => {
    history.push(BOOKING_RESERVATIONS);
  };

  return (
    <footer>
      <Card classes={{ root: classes.cardRoot }}>
        <CardContent>
          <Box className={classes.content}>

            <Box className={classes.leftContent}>
              <Link href={HOME_ROUTE_PATH}>
                <CardMedia
                  className={classes.brandImage}
                  component="img"
                  alt="Room Rocket"
                  image={defaultBrandImage}
                />
              </Link>
              <Box className={classes.copyrightContainer}>
                <CopyrightIcon />
                <Typography variant="subtitle2">
                  {`${t('footerCopyrightBrandText')} ${year}`}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.middleContent}>
              <Box height={23} />
              <Box>{t('footerCenterText')}</Box>
              <Link href={configs.termsOfUseUrl} target="_blank" rel="noreferrer" color="inherit" underline="always">
                {t('footerTermsOfUseLinkText')}
              </Link>
            </Box>

            <Box className={classes.rightContent}>
              <Button
                color="inherit"
                className={classes.footerButton}
                onClick={onMyReservationsClicked}
              >
                {t('footerMyReservationsLinkText')}
              </Button>
              <Link href={configs.contactUsUrl} target="_blank" rel="noreferrer" color="inherit" underline="always">
                {t('footerContactText')}
              </Link>
              <Button
                color="inherit"
                className={classes.footerButton}
                onClick={onSignOutClicked}
              >
                {t('signOut')}
              </Button>
            </Box>

          </Box>
        </CardContent>
      </Card>
    </footer>
  );
};

const MemoizedHeader = React.memo(Footer);
export default MemoizedHeader;
