import React, { FC, FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link,
  TextField,
} from '../common';

import { userRemote } from '../../api/services';

import { User } from '../../models';

import { loginSuccess, loginFail } from '../../store/actions/loginAction';

import configs from '../../configs/environments';
import useStyles from './loginStyles';

const LoginContainer: FC<unknown> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessageTerms, setErrorMessageTerms] = useState<string>('');
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);

  const onTermsAgreeHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>, checked: boolean,
  ): void => {
    setTermsAgreed(checked);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    switch (name) {
      case 'username': setUsername(value); break;
      case 'password': setPassword(value); break;
      default: break;
    }

    if (value && errorMessage) {
      setErrorMessage('');
    }
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (!termsAgreed) {
      setErrorMessageTerms(t('termsOfUseErrorText'));
      return;
    }

    setErrorMessageTerms(t(''));

    userRemote.login({ username, password }).then((resp: User) => {
      if (!resp || !resp.id) {
        // TODO: Change the message when there is a clarity for the errors
        setErrorMessage(t('incorrectUsernameOrPassword'));
        return;
      }

      dispatch(loginSuccess(resp));
      history.push('/');
    }, (err) => {
      if (err.response.data.errors && err.response.data.errors[0] === 'Incorrect username or password') {
        setErrorMessage(t('incorrectUsernameOrPassword'));
      }
    }).catch(() => dispatch(loginFail()));
  };

  return (
    <Box className={classes.loginContent}>
      <form onSubmit={onSubmit} className={classes.form}>
        <Box fontWeight="bold">{t('signIn')}</Box>
        <Box fontWeight="bold" fontSize="h5.fontSize" mb={2}>{t('signInTitle')}</Box>
        <TextField
          required
          fullWidth
          variant="outlined"
          label={t('username')}
          margin="normal"
          value={username}
          name="username"
          onChange={onInputChange}
          classes={{ root: classes.textField }}
        />
        <TextField
          required
          fullWidth
          variant="outlined"
          type="password"
          label={t('password')}
          margin="normal"
          value={password}
          name="password"
          onChange={onInputChange}
          classes={{ root: classes.textField }}
        />
        <Box minHeight="20px">
          { errorMessage && (
            <FormHelperText
              classes={{ root: classes.helperText }}
              error={true}
            >
              {errorMessage}
            </FormHelperText>
          ) }
        </Box>
        <TextField
          fullWidth
          id="outlined-multiline-static"
          label={t('termsOfUse')}
          margin="normal"
          multiline
          rows={8}
          value={t('termsOfUseText')}
          variant="outlined"
          classes={{ root: classes.textFieldTerms }}
          disabled
        />
        <FormControlLabel
          control={(
            <Checkbox checked={termsAgreed} onChange={onTermsAgreeHandleChange} color="default" />
          )}
          label={t('termsOfUseIAgree')}
        />
        <Box minHeight="20px">
          { errorMessageTerms && (
            <FormHelperText
              classes={{ root: classes.helperText }}
              error={true}
            >
              {errorMessageTerms}
            </FormHelperText>
          ) }
        </Box>
        <Box className={classes.submitBtnContent}>
          <Link href={`mailto:${configs.contactEmail}`} color="inherit">{t('forgotPassword')}</Link>
          <Button variant="contained" color="primary" type="submit">{t('signIn')}</Button>
        </Box>
      </form>
    </Box>
  );
};

export default LoginContainer;
