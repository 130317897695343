import americanExpressIcon from '../assets/payment_icons/american-express.png';
import dinnersClubIcon from '../assets/payment_icons/dinners-club.png';
import maestroIcon from '../assets/payment_icons/maestro.png';
import mastercardIcon from '../assets/payment_icons/mastercard.png';
import visaElectronIcon from '../assets/payment_icons/visa-electron.png';
import visaIcon from '../assets/payment_icons/visa.png';

const paymentIcons = [
  { name: 'american express', icon: americanExpressIcon },
  { name: 'visa', icon: visaIcon },
  { name: 'visa electron', icon: visaElectronIcon },
  { name: 'mastercard', icon: mastercardIcon },
  { name: 'maestro', icon: maestroIcon },
  { name: 'dinners club', icon: dinnersClubIcon },
];

export default paymentIcons;
